import React from 'react';
import Checkbox from './Checkbox';


const CheckboxList = ({checkboxes, onChange}) => (
    <React.Fragment>
        {checkboxes.map(item => (
            <div key={item.label} style={{paddingBottom: '2%'}}>
                <label>
                    <Checkbox checked={item.display} name={item.name} onChange={onChange} />
                    {item.label}
                </label>
                <br></br>
            </div>
        ))}
    </React.Fragment>
);


export default CheckboxList