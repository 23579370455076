import {createReducer} from "@reduxjs/toolkit";
import {modifyNuclideXYZ, modifyPeriodicXYZ} from "../actions/LocationActions";

export const locationReducer = createReducer(
    {
        nuclideXYZ: {
            x: 0, y: -190, z: 1
        },
        periodicXYZ: {
            x: 0, y: 254, z: 1
        }
    },
    {
        [modifyNuclideXYZ]: (state, action) => {
            state['nuclideXYZ'] = action.payload;
        },
        [modifyPeriodicXYZ]: (state, action) => {
            state['periodicXYZ'] = action.payload;
        },
    }
);
