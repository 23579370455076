import {fabric} from 'fabric';
import _ from 'lodash';

class ElementSquare {
    constructor(nuclide, items, squareSize) {
        this.nuclide = nuclide;
        this.items = items;
        this.elements = require('../../../elements.json').elements;
        this.squareSize = squareSize;

        this.getElementName = this.getElementName.bind(this);
    }

    getElementName() {
        return _.find(this.elements, e => this.nuclide.Symbol === e.symbol).element_name;
    }

    addElementName(multiplier = 9) {
        const elName = this.getElementName();

        if (elName.length > 10) {
            multiplier -= 2;
        }

        const name = new fabric.Text(elName, {
            originX: 'center',
            fontWeight: 'bold',
            fontFamily: 'Arial',
            fontSize: Math.log10(this.squareSize) * multiplier, // based on fontSize used in DefaultSquare
            top: this.squareSize / 2 + (this.squareSize * (1/16)),
            left: this.squareSize / 2,
            objectCaching: false
        });
        this.items.push(name);
    }

    addElementSquare() {
        let rect = new fabric.Rect({
            fill: 'transparent',
            stroke: 'black',
            strokeWidth: 8,
            width: this.squareSize - this.squareSize * (1/32),
            height: this.squareSize - this.squareSize * (1/32),
            objectCaching: false
        });

        this.items.push(rect);
    }

    addCrossSection(fontSize = 20) {
        let text = this.nuclide.xs2 ? this.nuclide.xs1 + '\n  ' + this.nuclide.xs2 : this.nuclide.xs1 || '';
        let xs = new fabric.Text(text, {
            originX: 'center',
            top: this.squareSize - (this.squareSize / 4),
            left: this.squareSize / 2,
            fontWeight: 'normal',
            fontSize: fontSize,
            objectCaching: false
        });

        let searchVals = ['α', 'p', 'f', 'γ', 't'];

        text.split('').forEach((letter, index) => {
            if (searchVals.indexOf(letter) !== -1) {
                xs.setSubscript(index, index + 1);
            }
        });

        this.items.push(xs);
    }

    addMass(fontSize = 24, heightDivisor = 6) {
        let text = this.nuclide.mass ? this.nuclide.mass : '';

        let mass = new fabric.Text(text, {
            originX: 'center',
            top: this.squareSize / heightDivisor,
            fontWeight: 'bold',
            left: this.squareSize / 2,
            fontSize: fontSize,
            objectCaching: false
        });

        this.items.push(mass)
    }

    addSymbol(fontMultiplier = 10, heightMultiplier = 56) {
        let nuclideName = this.nuclide.questionable ? this.nuclide.name + this.nuclide.questionable : this.nuclide.name;
        let color = this.nuclide.blackband ? 'white' : 'black';
        let name = new fabric.Text(nuclideName, {
            fill: color,
            fontSize: Math.log10(this.squareSize) * fontMultiplier, // ¯\_(ツ)_/¯
            originX: 'center',
            fontFamily: 'Arial',
            top: this.squareSize / heightMultiplier,
            left: this.squareSize / 2,
            objectCaching: false
        });
        this.items.push(name);
    }


}

export default ElementSquare;
