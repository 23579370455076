import React, { Component } from 'react';
import { fabric } from 'fabric';
import DefaultSquare from "../../../levels/nuclide/levelhelpers/DefaultSquare";
import FullSquare from "../../../levels/nuclide/levelhelpers/FullSquare";
import { generateLegendTextForNuclide } from "../../../helpers/nuclideLegendGenerator";
import './Legends.css';

class OneStateNuclideLegend extends Component {
    constructor(props) {
        super(props);
        this.oneStateRef = React.createRef();
        this.methods = ['addMass', 'addDisintegrationE', 'addSpinAndParity', 'addHalflifeAbundance',
            'addDecayData', 'addIndependentFissionYield', 'addCrossSectionResonance'];

        this.squareSize = 256;

    }

    componentDidMount() {
        this.renderLegend();
    }

    componentWillUnmount() {
        this.canvas.setHeight(0);
        this.canvas.setWidth(0);
        this.canvas.clear();
    }

    addSquare = () => {
        let items = [];
        const isOnChart = false;

        const defaultSquare = new DefaultSquare(this.props.nuclide, items, this.squareSize, isOnChart);
        defaultSquare.addBlackBand();
        defaultSquare.addName(13);

        if (this.props.nuclide.cluster === '1') {
            defaultSquare.addClusterSymbol();
        }

        const square = new FullSquare(this.props.nuclide, items, this.squareSize, isOnChart);
        const placeholderZoomLevel = 8;

        this.methods.forEach(method => {
            square[method](placeholderZoomLevel);
        });

        return new fabric.Group(items, {
            objectCaching: false,
            hasBorders: false,
            hasControls: false,
            hasRotatingPoint: false,
            lockMovementX: true,
            lockMovementY: true,
            hoverCursor: 'default',
            selectable: false,
        });
    };

    renderLegend = () => {

        this.canvas = new fabric.Canvas();
        this.canvas.initialize(this.oneStateRef.current);

        this.canvas.setHeight(270); // should probably be 256, squares with independent fission yield triangles get their top line cut off though
        this.canvas.setWidth(600);

        const group = this.addSquare();

        this.canvas.add(group);
        this.canvas.add(generateLegendTextForNuclide([this.props.nuclide]));
        group.center();
    };

    render() {
        const {N, Z} = this.props.nuclide;
        return (
            <div className='legend-container'>
                <div className='legend-inner-container'>
                    <canvas ref={this.oneStateRef}/>
                    <p style={{textAlign: 'center', marginTop: '0px'}}>Z: {Z}  N: {N}</p>
                </div>
            </div>
        )
    }

}



export default OneStateNuclideLegend;
