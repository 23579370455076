import store from '../../../store/ReduxStore';
import UnitConverter from '../../../helpers/UnitConverter';
import Parity from '../../../helpers/Parity';
import Decay from '../../../helpers/Decay';
import fromExponential from 'from-exponential';
import {graySquare} from "../../../colors";

function assignColorsWithFiltering(nuclide, colors) {
    let {topShade, bottomShade} = colors;

    if (!squareInFilterRanges(nuclide) && !_noColorCodingOptionsSelected()) {
        topShade = "";
        bottomShade = "";
    } else if (squareInFilterRanges(nuclide) && _noColorCodingOptionsSelected()) {
        topShade = graySquare;
        bottomShade =  graySquare;
    }

    if (_noColorFiltersSelected() && _noColorCodingOptionsSelected()) {
        topShade = "";
        bottomShade = "";
    }

    return [topShade, bottomShade];
}

function _noColorFiltersSelected() {
    return getColorFilters().length === 0;
}

function _noColorCodingOptionsSelected() {
    let {topshade, bottomshade} = store.getState().filters.filters;

    return topshade.attribute === 'no color' && bottomshade.attribute === 'no color';
}

function squareInFilterRanges(nuclide) {
    let filters = getColorFilters();

    if (filters.length === 0) {
        return true;
    }

    for (let filter of filters) {
        if(filter.type === 'continuous') {
            if(!_evaluateContinuousAttribute(nuclide, filter)) {
                return false;
            }
        } else if (filter.type === 'discrete') {
            if(!_evaluateDiscreteAttribute(nuclide, filter)) {
                return false;
            }
        }

    }
    return true;
}

function _evaluateContinuousAttribute(nuclide, filter) {
    let converter = new UnitConverter();
    let numericValue = converter[continuousMap[filter.attribute]](nuclide);


    if(numericValue === null) {
        return false;
    }

    return _evaluateFilterRanges(numericValue, filter);
}

function _evaluateDiscreteAttribute(nuclide, filter) {
    if (filter.options.length === 0) {
        return false;
    }

    if (filter.attribute === 'decay mode') {
        let decay = new Decay(nuclide);
        for (let option of filter.options) {
            if(decay.doesNuclideHaveDecayType(option.value)) {
                return true;
            }
        }
    }

    if (filter.attribute === 'parity') {
        let parity = new Parity(nuclide);
        for (let option of filter.options) {
            if (parity[parityMap[option.value]]()) {
                return true;
            }
        }
    }

    return false;
}

function _evaluateFilterRanges(value, filter) {
    let {minVal, maxVal} = filter;

    if (filter.attribute === 'half life') {
        [minVal, maxVal] = _handleHalflifeConversion(minVal, maxVal);
    }

    minVal = fromExponential(minVal);
    maxVal = fromExponential(maxVal);

    if (minVal && maxVal) {
        return minVal <= value && value <= maxVal;
    } else if (minVal && !maxVal) {
        return minVal <= value;
    } else if (!minVal && maxVal) {
        return value <= maxVal;
    }

    return true;
}

function _handleHalflifeConversion(minVal, maxVal) {
    let converter = new UnitConverter();

    let newMin = minVal ? converter.handleHalflifeConversion(minVal) : '';
    let newMax = maxVal ? converter.handleHalflifeConversion(maxVal) : '';

    return [newMin, newMax];
}

function getColorFilters() {
    return store.getState().colorFilter;
}

const continuousMap = {
    'alpha energy': 'convertDecayToAlpha',
    'beta energy': 'convertDecayToBeta',
    'gamma energy': 'convertDecayToGamma',
    'disintegration energy': 'convertDisintegrationE',
    'natural abundance': 'convertNaturalAbundanceToNumeric',
    'half life': 'convertHalfLife',
    'cross section': 'convertXsToCrossSection',
    'resonance integral': 'convertXsToResonance',
    'neutron absorption': 'convertXsToNeutronAbsorption',
    'spin': 'convertSpinToNumeric',
};

const parityMap = {
    'Strong': 'isStrong',
    'Weak': 'isWeak',
    'Positive': 'isPositive',
    'Negative': 'isNegative',
};


export {
    assignColorsWithFiltering,
    squareInFilterRanges,
    getColorFilters
}
