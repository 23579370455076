import {fabric} from 'fabric';
import DefaultSquare from '../nuclide/levelhelpers/DefaultSquare';
import RowColSquare from '../nuclide/levelhelpers/RowColSquare'
import ElementSquare from '../nuclide/levelhelpers/ElementSquare';
import SquareGrid from '../nuclide/levelhelpers/SquareGrid';
import store from "../../store/ReduxStore";


export default function createEight(nuclide, nuclideData) {
    let items = [];

    if(nuclide.isRow) {
        new RowColSquare(nuclide, items, 256, true, 'Z');
    } else if(nuclide.isColumn) {
        new RowColSquare (nuclide, items, 256, true, 'N');
    } else {
        let square = new DefaultSquare(nuclide, items, 256);
        const dataSelectors = store.getState().dataDisplay;

        if (nuclide.location === 'element') {
            let element = new ElementSquare(nuclide, items, 256);
            element.addElementName(13);
            element.addElementSquare();
            element.addSymbol(13);

            if (dataSelectors.addCrossSectionResonance.display) {
                element.addCrossSection();
            }

            if (dataSelectors.addMass.display) {
                element.addMass();
            }
        } else if (nuclide.location !== 'empty') {
            square.addBlackBand();
            square.addName(13);

            if (nuclide.cluster === '1' && dataSelectors.addDecayData.display) {
                square.addClusterSymbol();
            }

            let grid = new SquareGrid(items, nuclide, 256, nuclideData);
            grid.createGrid(8);
        }

    }

    let group = new fabric.Group(items, {
        objectCaching: false,
        opacity: nuclide.opacity
    });

    return group;
}

