import DecayMode from "./DecayMode";
import DecayTypes from "./DecayTypes";

const getDecayModes = (nuclide) => {
    const decayMode = new DecayMode(nuclide);
    const decays = decayMode.getDecayModes();

    return filterDecayString(decays);
};

const filterDecayString = (decays) => {
    let decayModes = [];

    // alpha and Not 2a or (a) or (2a)
    // switched this logic to just include succeeding parentheses OR alpha without preceding number
    if (decays.match(/\u03b1(?!\))/g) && !decays.includes(DecayTypes.DOUBLE_ALPHA)) {
        decayModes.push(DecayTypes.ALPHA);
    }

    // 2a and not (2a)
    if (decays.includes(DecayTypes.DOUBLE_ALPHA) && !decays.includes(DecayTypes.DELAYED_DOUBLE_ALPHA)) {
        decayModes.push(DecayTypes.DOUBLE_ALPHA);
    }

    // (a)
    if (decays.includes(DecayTypes.DELAYED_ALPHA)) {
        decayModes.push(DecayTypes.DELAYED_ALPHA);
    }

    // (2a)
    if (decays.includes(DecayTypes.DELAYED_DOUBLE_ALPHA)) {
        decayModes.push(DecayTypes.DELAYED_DOUBLE_ALPHA);
    }

    // B- and NOT B-B- OR B- B-
    if (decays.includes(DecayTypes.BETA_MINUS) && !decays.includes(DecayTypes.DOUBLE_BETA_MINUS) && !decays.includes(DecayTypes.DOUBLE_BETA_MINUS_WITH_SPACE)) {
        decayModes.push(DecayTypes.BETA_MINUS);
    }

    // B-B- OR B- B- (space in some not in others)
    if (decays.includes(DecayTypes.DOUBLE_BETA_MINUS) || decays.includes(DecayTypes.DOUBLE_BETA_MINUS_WITH_SPACE)) {
        decayModes.push(DecayTypes.DOUBLE_BETA_MINUS);
    }

    // B+
    if (decays.includes(DecayTypes.BETA_PLUS) && !decays.includes(`no ${DecayTypes.BETA_PLUS}`)) {
        decayModes.push(DecayTypes.BETA_PLUS);
    }

    // Epsilon and NOT ee
    if (decays.includes(DecayTypes.ELECTRON_CAPTURE) && !decays.includes(DecayTypes.DOUBLE_ELECTRON_CAPTURE)) {
        decayModes.push(DecayTypes.ELECTRON_CAPTURE);
    }

    // ee
    if (decays.includes(DecayTypes.DOUBLE_ELECTRON_CAPTURE)) {
        decayModes.push(DecayTypes.DOUBLE_ELECTRON_CAPTURE);
    }

    // n and NOT (n) OR #n AND NOT contains 'no \u03b3'
    if (decays.includes(DecayTypes.N) && !decays.match(/\dn/g) && !decays.includes(DecayTypes.N_DELAYED) && !decays.includes(`no ${DecayTypes.GAMMA}`)) {
        decayModes.push(DecayTypes.N);
    }

    // (n)
    if (decays.includes(DecayTypes.N_DELAYED)) {
        decayModes.push(DecayTypes.N_DELAYED);
    }

    // 2n and NOT (2n)
    if (decays.includes(DecayTypes.TWO_N) && !decays.includes(DecayTypes.TWO_N_DELAYED)) {
        decayModes.push(DecayTypes.TWO_N);
    }

    // (2n)
    if (decays.includes(DecayTypes.TWO_N_DELAYED)) {
        decayModes.push(DecayTypes.TWO_N_DELAYED);
    }

    // 3n and NOT (3n)
    if (decays.includes(DecayTypes.THREE_N) && !decays.includes(DecayTypes.THREE_N_DELAYED)) {
        decayModes.push(DecayTypes.THREE_N);
    }

    // (3n)
    if (decays.includes(DecayTypes.THREE_N_DELAYED)) {
        decayModes.push(DecayTypes.THREE_N_DELAYED);
    }

    // 4n and NOT (4n)
    if (decays.includes(DecayTypes.FOUR_N) && !decays.includes(DecayTypes.FOUR_N_DELAYED)) {
        decayModes.push(DecayTypes.FOUR_N);
    }

    // (4n)
    if (decays.includes(DecayTypes.FOUR_N_DELAYED)) {
        decayModes.push(DecayTypes.FOUR_N_DELAYED);
    }

    // p and NOT (p) or #p
    if (decays.match(/p(?!\))/g) && !decays.match(/\dp/g)) {
        decayModes.push(DecayTypes.P);
    }

    // (p)
    if (decays.includes(DecayTypes.P_DELAYED)) {
        decayModes.push(DecayTypes.P_DELAYED);
    }

    // 2p and NOT (2p)
    if (decays.match(/2p(?!\))/g)) {
        decayModes.push(DecayTypes.TWO_P);
    }

    // (2p)
    if (decays.includes(DecayTypes.TWO_P_DELAYED)) {
        decayModes.push(DecayTypes.TWO_P_DELAYED);
    }

    // (3p)
    if (decays.includes(DecayTypes.THREE_P_DELAYED)) {
        decayModes.push(DecayTypes.THREE_P_DELAYED);
    }

    // (t) He8 has a space in the t
    if (decays.includes(DecayTypes.T_DELAYED) || decays.includes('(t )')) {
        decayModes.push(DecayTypes.T_DELAYED);
    }

    // (d)
    if (decays.includes(DecayTypes.D_DELAYED)) {
        decayModes.push(DecayTypes.D_DELAYED);
    }

    return decayModes;
};

export default getDecayModes;

export {
    filterDecayString
}
