const lambdaURL = 'https://0fmmn1hd8c.execute-api.us-east-1.amazonaws.com';

const stage = {
    s3: {
        URL: 'https://fluor-nuclide-data-staging.s3.amazonaws.com/data.json'
    },
    lamdba: {
        URL: `${lambdaURL}/test`
    },
    GA: {
        ID: 'G-CHDHC2JD5M'
    }
};

const dev = {
    ...stage,
    GA: {
        ID: '',
    }
};

const prod = {
    s3: {
        URL: 'https://fluor-nuclide-data.s3.amazonaws.com/data.json'
    },
    lamdba: {
        URL: `${lambdaURL}/api`
    },
    GA: {
        ID: 'G-3D3FXF1MF6'
    }
};

const envMap = {
    'prod': prod,
    'staging': stage,
    'dev': dev
};

const config = envMap[process.env.REACT_APP_STAGE];

export default {
    ...config
}
