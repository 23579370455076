import React, { Component } from 'react';
import { fabric } from 'fabric';
import DefaultSquare from "../../../levels/nuclide/levelhelpers/DefaultSquare";
import HalfRightSquare from "../../../levels/nuclide/levelhelpers/HalfRightSquare";
import UpQuarterSquare from "../../../levels/nuclide/levelhelpers/UpQuarterSquare";
import DownQuarterSquare from "../../../levels/nuclide/levelhelpers/DownQuarterSquare";
import {generateLegendTextForNuclide} from "../../../helpers/nuclideLegendGenerator";
import './Legends.css';


class ThreeStateNuclideLegend extends Component {
    constructor(props) {
        super(props);

        this.threeStateRef = React.createRef();
        this.methods = ['addSpinAndParity', 'addHalflifeAbundance', 'addDecayData', 'addIndependentFissionYield'];

        this.squareSize = 256;
    }

    componentDidMount() {
        this.renderLegend();
    }

    componentWillUnmount() {
        this.canvas.setHeight(0);
        this.canvas.setWidth(0);
        this.canvas.clear();
    }

    addSquare = (states) => {
        let items = [];
        let isOnChart = false;
        let placeHolderZoomLevel = 8;

        const [s0Entry, s1Entry, s2Entry] = states;

        let defaultSquare = new DefaultSquare(s0Entry, items, this.squareSize, isOnChart);
        defaultSquare.addBlackBand();
        defaultSquare.addName(13);

        if (s0Entry.cluster === '1') {
            defaultSquare.addClusterSymbol();
        }

        let halfRight = new HalfRightSquare(s0Entry, items, this.squareSize, isOnChart);
        let downQuarterSquare = new DownQuarterSquare(s1Entry, items, this.squareSize);
        let upQuarterSquare = new UpQuarterSquare(s2Entry, items, this.squareSize);

        this.methods.forEach(method => {
            halfRight[method](placeHolderZoomLevel);
            downQuarterSquare[method](placeHolderZoomLevel);
            upQuarterSquare[method](placeHolderZoomLevel);
        });

        halfRight.addMass(placeHolderZoomLevel);
        halfRight.addDisintegrationE(placeHolderZoomLevel);
        halfRight.addCrossSectionResonance();
        halfRight.addSubdivisionLine();

        downQuarterSquare.addCrossSectionResonance();
        downQuarterSquare.addDoubleArrow(placeHolderZoomLevel);

        upQuarterSquare.addSubdivisionLine();
        upQuarterSquare.addDoubleArrow(placeHolderZoomLevel);

        return new fabric.Group(items, {
            objectCaching: false,
            hasBorders: false,
            hasControls: false,
            hasRotatingPoint: false,
            lockMovementX: true,
            lockMovementY: true,
            hoverCursor: 'default'
        });
    };

    renderLegend = () => {

        let s0Entry = this.props.isotopes.filter(isotope => isotope.s == '0')[0];
        let s1Entry = this.props.isotopes.filter(isotope => isotope.s == '1')[0];
        let s2Entry = this.props.isotopes.filter(isotope => isotope.s == '2')[0];

        this.canvas = new fabric.Canvas();
        this.canvas.initialize(this.threeStateRef.current);

        this.canvas.setHeight(270);
        this.canvas.setWidth(600);

        let group = this.addSquare([s0Entry, s1Entry, s2Entry]);

        this.canvas.add(group);
        this.canvas.add(generateLegendTextForNuclide([s0Entry, s1Entry, s2Entry]));
        group.center();
    };

    render() {
        const {N, Z} = this.props.isotopes[0];
        return (
            <div className='legend-container'>
                <div className='legend-inner-container'>
                    <canvas ref={this.threeStateRef} />
                    <p style={{textAlign: 'center', marginTop: '0px'}}>Z: {Z}  N: {N}</p>
                </div>
            </div>
        )
    }
}


export default ThreeStateNuclideLegend;
