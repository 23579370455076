import React, {useState, useCallback} from 'react';
import ColorFilterInputs from './ColorFilterInputs';
import Select from 'react-select';
import { connect } from 'react-redux';
import { updateColorFilterDecayValues, updateColorFilterParityValues} from "../../store/actions/ColorFilterAction";

const ColorFilterSelector = ({selectedParity, selectedDecay, updateColorFilterDecayValues, updateColorFilterParityValues, removeAttribute, valueChange, attribute}) => {

    const decay = [
        { index: 0, value: '2p', label: '2p', selected: true },
        { index: 1, value: 'p', label: 'p', selected: true },
        { index: 2, value: '\u03b2+', label: '\u03b2+', selected: true },
        { index: 3, value: 'EC', label: 'EC', selected: true },
        { index: 4, value: '\u03b1', label: '\u03b1', selected: true },
        { index: 5, value: 'IT', label: 'IT', selected: true },
        { index: 6, value: 'SF', label: 'SF', selected: true },
        { index: 7, value: '\u03b2-', label: '\u03b2-', selected: true },
        { index: 8, value: '\u03b2-\u03b2-', label: '\u03b2-\u03b2-', selected: true },
        { index: 9, value: 'n', label: 'n', selected: true },
        { index: 10, value: '2n', label: '2n', selected: true },
        { index: 11, value: '3n', label: '3n', selected: true },
        { index: 12, value: '4n', label: '4n', selected: true }
    ];

    const parity = [
        { index: 0, value: 'Strong', label: 'Strong', selected: true },
        { index: 1, value: 'Weak', label: 'Weak',  selected: true },
        { index: 2, value: 'Positive', label: 'Positive', selected: true },
        { index: 3, value: 'Negative', label: 'Negative', selected: true },
    ];

    const [decayOptions, updateDecayOptions] = useState(decay);
    const [parityOptions, updateParityOptions] = useState(parity);

    const handleParityChange = (values) => updateColorFilterParityValues(values);
    const handleDecayChange = (values) => updateColorFilterDecayValues(values);
    const isParity = attribute.attribute === 'parity';

    return (
        <React.Fragment>
            <div style={{paddingBottom: '2%', paddingTop: '2%'}}>
                <label style={{fontWeight: 'bold'}}>{attribute.label}: </label>
                <div style={{paddingTop: '3px'}}>
                    {attribute.type === 'continuous' ? (
                            <div style={{display: 'inline-block'}}>
                                <ColorFilterInputs attribute={attribute}
                                            onBlur={(e) => valueChange(e)}/>
                            </div>
                        )
                        :
                        <Select
                            onChange={isParity ? handleParityChange : handleDecayChange}
                            defaultValue={isParity ? [...parityOptions] : [...decayOptions]}
                            isMulti
                            name="options"
                            options={isParity ? parityOptions : decayOptions}
                            value={isParity ? selectedParity : selectedDecay}
                            className='filter_dropdown'
                        />
                    }
                    <div style={{display: 'inline-block'}}><button className="remove" onClick={() => removeAttribute(attribute.attribute)}>Remove</button></div>
                </div>
            </div>
            <br/>
        </React.Fragment>
    )
};

const mapStateToProps = (state) => {

    let parity = state.colorFilter.filter(attribute => attribute.attribute === 'parity');
    let decay = state.colorFilter.filter(attribute => attribute.attribute === 'decay mode');

    let selectedParity = parity.length > 0 ? parity[0].options : null;
    let selectedDecay = decay.length > 0 ? decay[0].options : null;

    return {
        selectedParity: selectedParity,
        selectedDecay: selectedDecay,
    }
}


export default connect(mapStateToProps, {updateColorFilterDecayValues, updateColorFilterParityValues})(ColorFilterSelector);