import {createReducer} from '@reduxjs/toolkit';
import {toggleMinimapDisplay} from "../actions/MiniMapToggleActions";

export const minimapToggleReducer = createReducer(
    {
        isMinimized: false
    },
    {
        [toggleMinimapDisplay]: (state, action) => {
            return {isMinimized: action.payload.isMinimized};
        }
    }
)