function getUnitOfTime(halflife) {
    return halflife.replace(/[0-9.Ee]+/g, '');
}

function getNumericValue(halflife) {
    return halflife.replace(/[a-df-zA-DF-Zμ\s]+/g, '');
}

export {
    getUnitOfTime,
    getNumericValue
}