import {
    isColumnInMagicNumbers,
    isRowInMagicNumbers,
    isNuclideInBothMagicRowAndColumn
} from "../../../helpers/IsInMagicNumbers";
import {
    createTriangleIndependentFissionYield,
    createSquareIndependentFissionYield,
    createMass,
    createDisintegrationEnergy,
    createSpinAndParity,
    createHalfLifeAbundance,
    createDecay,
    createCrossSectionAndResonance
} from '../creators';
import ColorSquare from '../levelhelpers/ColorSquare';
import {fabric} from 'fabric';
import { assignColorsWithFiltering } from '../levelhelpers/ColorFilter';

class FullSquare {
    constructor(nuclide, items, squareSize, isOnChart = true) {
        this.nuclide = nuclide;
        this.items = items;
        this.squareSize = squareSize;
        this.strokeWidth = 1;
        this.isOnChart = isOnChart;
    }

    addMass(zoomLevel) {
        let levelMap = {
            8: {
                left: this.squareSize/2,
                top: 225,
                fontSize: 18
            },
            7: {
                left: this.squareSize/2,
                top: 105,
                fontSize: 16
            },
            6: {
                left: this.squareSize/2,
                top: 50,
                fontSize: 9
            }
        };

        this.items.push(createMass(this.nuclide, {origin: 'center', top: levelMap[zoomLevel].top, left: levelMap[zoomLevel].left, fontSize: levelMap[zoomLevel].fontSize}));
    }

    addDisintegrationE(zoomLevel) {
        let levelMap = {
            8: {
                'mass': {
                    top: 200,
                    left: this.squareSize/2,
                    fontSize: 18,
                },
                'no_mass': {
                    top: 225,
                    left: this.squareSize/2,
                    fontSize: 18,
                },
            },
            7: {
                'mass': {
                    top: 85,
                    left: this.squareSize/2,
                    fontSize: this.nuclide.Eplus ? 14 : 16
                },
                'no_mass': {
                    top: 105,
                    left: this.squareSize/2,
                    fontSize: this.nuclide.Eplus ? 14 : 16
                },
            },
            6: {
                'mass': {
                    top: 35,
                    left: this.squareSize/2,
                    fontSize: this.nuclide.Eplus ? 6 : 9
                },
                'no_mass': {
                    top: 50,
                    left: this.squareSize/2,
                    fontSize: this.nuclide.Eplus ? 6 : 9,
                },
            }
        };


        if (this.nuclide.mass) {
            this.items.push(createDisintegrationEnergy(this.nuclide, {top: levelMap[zoomLevel]['mass'].top, fontSize: levelMap[zoomLevel]['mass'].fontSize, left: levelMap[zoomLevel]['mass'].left, origin: 'center'}));
        } else {
            this.items.push(createDisintegrationEnergy(this.nuclide, {top: levelMap[zoomLevel]['no_mass'].top, fontSize: levelMap[zoomLevel]['no_mass'].fontSize, left: levelMap[zoomLevel]['no_mass'].left, origin: 'center'}));
        }
    }

    addSpinAndParity(zoomLevel) {
        let spin = this.nuclide.jpi ? this.nuclide.jpi : '';
        let fontSize = zoomLevel === 8 ? 20 : (spin.length > 5 ? 10 : 12);

        this.items.push(createSpinAndParity(this.nuclide, {origin: 'right', left: this.squareSize -6, top: this.squareSize/24, fontSize: fontSize }));
    }

    addHalflifeAbundance(zoomLevel) {
        let baseTop = this.squareSize/6;
        this.items.push(createHalfLifeAbundance(this.nuclide, {top: zoomLevel === 8 ? baseTop : baseTop + 12, left: this.squareSize/2, fontSize: zoomLevel === 8 ? 20 : 14}));
    }
    
    addDecayData() {
        let stripLength = 32;
        let left = 13;
        if (this.nuclide.abundance && this.nuclide.halflife) {
            this.items.push(createDecay(this.nuclide, {stripLength: stripLength, top: 256/3, left: left}));
        } else {
            this.items.push(createDecay(this.nuclide, {stripLength: stripLength, top: 256/3-20, left: left}));
        }
    }    

    addIndependentFissionYield() {
        let symbol = this.nuclide.fpsymbol ? this.nuclide.fpsymbol : '';
        let top, left;

        top = 258;
        left = 265;

        // added the isOnChart check so the triangle doesn't move on the legend
        // since legend doesn't have the bold lines but chart does
        if (this.isOnChart) {
            if (isNuclideInBothMagicRowAndColumn(this.nuclide)) {
                top = top - 6;
                left = left - 5;
            } else if (isRowInMagicNumbers(this.nuclide)) {
                top = top - 6;
            } else if (isColumnInMagicNumbers(this.nuclide)) {
                left = left - 5;
            }
        }

        if (symbol && symbol.toUpperCase() === "X") {
            this.items.push(createSquareIndependentFissionYield(this.nuclide, {top: 256, left: 256}));
        } else if (symbol === "\/") {
            this.items.push(createTriangleIndependentFissionYield(this.nuclide, {top: top, left: left}));
        }
    }

    addCrossSectionResonance() {
        let stripLength = 30;
        let left = 13;

        if (this.nuclide.mass && (this.nuclide.Eplus || this.nuclide.Eminus || this.nuclide.E)) {
            this.items.push(createCrossSectionAndResonance(this.nuclide, {stripLength: stripLength, top: 256 - 80, left: left }));
        } else {
            this.items.push(createCrossSectionAndResonance(this.nuclide, {stripLength: stripLength, left: left, top: 256 - 30}));
        }
    }

    addColor() {
        let colorSpec = new ColorSquare(this.nuclide);
        let topShade = colorSpec.populateTopColor();
        let bottomShade = colorSpec.populateBottomColor();

        [topShade, bottomShade] = assignColorsWithFiltering(this.nuclide, {topShade, bottomShade});

        if (this.nuclide.location === 'element' || this.nuclide.location === 'empty') {
            topShade = '';
            bottomShade = '';
        }

        let colorUp = new fabric.Rect({
            width: this.squareSize-1, 
            strokeWidth: this.strokeWidth,
            height: this.squareSize/2,
            fill: topShade,
            objectCaching: false,
        });
        let colorDown = new fabric.Rect({
            width: this.squareSize-1, 
            strokeWidth: this.strokeWidth,
            height: this.squareSize/2,
            top: this.squareSize/2,
            fill: bottomShade,
            objectCaching: false,
        });
        this.items.unshift(colorUp, colorDown)
    }
}

export default FullSquare;