import React from 'react';
import errorPageImage from '../static/errorPageImage.png'

const Error = () => (
    <div style={{margin: '0', position: 'absolute', left: '50%', msTransform: 'translateY(-50%)', transform: 'translate(-50%, -50%)', textAlign: 'center', top: '50%', color: '#515151'}}>
        <img width='150px' src={errorPageImage} />
        <h1 className="welcome-title">Chart of the Nuclides</h1>
        <h2>Unable to access data. Please re-launch app with internet connection.</h2>
    </div>
);


export default Error;
