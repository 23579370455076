import FullSquare from '../levelhelpers/FullSquare';
import HalfLeftSquare from '../levelhelpers/HalfLeftSquare';
import HalfRightSquare from '../levelhelpers/HalfRightSquare';
import DownQuarterSquare from '../levelhelpers/DownQuarterSquare';
import UpQuarterSquare from '../levelhelpers/UpQuarterSquare';
import _ from 'lodash';
import NuclideData from '../levelhelpers/NuclideData';
import store from '../../../store/ReduxStore';

let squareGridProxy = {
    // For performance reasons, we don't render colors based on grids for low levels. 
    //Instead, we use s=0 isotope and color top/bottom of the whole square based on that.
    0: [{className: FullSquare, methods: ['addColor'], isotopeLength: 1, sValue: 0}],
    1: [{className: FullSquare, methods: ['addColor'], isotopeLength: 1, sValue: 0}],
    2: [{className: FullSquare, methods: ['addColor'], isotopeLength: 1, sValue: 0}],
    3: [{className: FullSquare, methods: ['addColor'], isotopeLength: 1, sValue: 0}],
    4: [{className: FullSquare, methods: ['addColor'], isotopeLength: 1, sValue: 0}],
    5: [
        {className: FullSquare, methods: ['addColor'], isotopeLength: 1, sValue: 0},
        {className: HalfRightSquare, methods: ['addColor'], isotopeLength: 2, sValue: 0},
        {className: HalfLeftSquare, methods: ['addColor'], isotopeLength: 2, sValue: 1},
        {className: HalfRightSquare, methods: ['addColor'], isotopeLength: 3, sValue: 0},
        {className: DownQuarterSquare, methods: ['addColor'], isotopeLength: 3, sValue: 1},
        {className: UpQuarterSquare, methods: ['addColor'], isotopeLength: 3, sValue: 2}
    ],
    6: [
        {className: FullSquare, methods: ['addColor', 'addMass', 'addDisintegrationE'], isotopeLength: 1, sValue: 0},
        {className: HalfRightSquare, methods: ['addColor', 'addMass', 'addDisintegrationE'], isotopeLength: 2, sValue: 0},
        {className: HalfLeftSquare, methods: ['addColor', 'addMass', 'addDisintegrationE'], isotopeLength: 2, sValue: 1},
        {className: HalfRightSquare, methods: ['addColor', 'addMass', 'addDisintegrationE'], isotopeLength: 3, sValue: 0},
        {className: DownQuarterSquare, methods: ['addColor'], isotopeLength: 3, sValue: 1},
        {className: UpQuarterSquare, methods: ['addColor'], isotopeLength: 3, sValue: 2}
    ],
    7: [
        {className: FullSquare, methods: ['addColor', 'addMass', 'addDisintegrationE', 'addHalflifeAbundance', 'addSpinAndParity'], isotopeLength: 1, sValue: 0},
        {className: HalfRightSquare, methods: ['addColor', 'addSubdivisionLine', 'addMass', 'addDisintegrationE', 'addHalflifeAbundance', 'addSpinAndParity'], isotopeLength: 2, sValue: 0},
        {className: HalfLeftSquare, methods: ['addColor', 'addMass', 'addDisintegrationE', 'addDoubleArrow', 'addHalflifeAbundance', 'addSpinAndParity'], isotopeLength: 2, sValue: 1},
        {className: HalfRightSquare, methods: ['addColor', 'addSubdivisionLine', 'addMass', 'addDisintegrationE', 'addHalflifeAbundance', 'addSpinAndParity'], isotopeLength: 3, sValue: 0},
        {className: DownQuarterSquare, methods: ['addColor', 'addDoubleArrow', 'addHalflifeAbundance', 'addSpinAndParity'], isotopeLength: 3, sValue: 1},
        {className: UpQuarterSquare, methods: ['addColor', 'addSubdivisionLine', 'addDoubleArrow', 'addHalflifeAbundance', 'addSpinAndParity'], isotopeLength: 3, sValue: 2}
    ],
    8: [
        {className: FullSquare, 
                    methods: ['addMass', 'addDisintegrationE', 'addSpinAndParity', 'addHalflifeAbundance',
                                'addDecayData', 'addIndependentFissionYield', 'addCrossSectionResonance', 'addColor'], 
                    isotopeLength: 1, sValue: 0},
        {className: HalfRightSquare, 
                    methods: ['addMass', 'addDisintegrationE', 'addSpinAndParity', 'addHalflifeAbundance',
                                'addDecayData', 'addIndependentFissionYield', 'addCrossSectionResonance', 'addSubdivisionLine', 'addColor'], 
                    isotopeLength: 2, 
                    sValue: 0},
        {className: HalfLeftSquare, 
                    methods: ['addMass', 'addSpinAndParity', 'addHalflifeAbundance', 'addDecayData', 'addIndependentFissionYield',
                                'addCrossSectionResonance', 'addDoubleArrow', 'addDisintegrationE', 'addColor'],
                    isotopeLength: 2, sValue: 1},
        {className: HalfRightSquare, 
                    methods: ['addMass', 'addDisintegrationE', 'addSpinAndParity', 'addHalflifeAbundance',
                                'addDecayData', 'addIndependentFissionYield', 'addCrossSectionResonance', 'addSubdivisionLine', 'addColor'],
                    isotopeLength: 3, sValue: 0},
        {className: DownQuarterSquare, 
                    methods: ['addDoubleArrow', 'addSpinAndParity', 'addHalflifeAbundance', 'addIndependentFissionYield', 
                                'addDecayData', 'addCrossSectionResonance', 'addColor'],
                    isotopeLength: 3, sValue: 1},
        {className: UpQuarterSquare, 
                    methods: ['addSpinAndParity', 'addDoubleArrow', 'addHalflifeAbundance', 'addIndependentFissionYield', 
                                'addDecayData', 'addSubdivisionLine', 'addColor'], 
                    isotopeLength: 3, sValue: 2}
    ]
}
class SquareGrid {
    constructor(items, nuclide, squareSize, nuclideData) {
        this.items = items;
        this.nuclide = nuclide;
        this.squareSize = squareSize;
        this.state = store.getState();
        this.nuclideData = nuclideData;

        let defaults = {
            addSubdivisionLine: {
                display: true,
            },
            addDoubleArrow: {
                display: true,
            },
            addColor: {
                display: true,
            }
        };

        this.methodsToRender = {...this.state.dataDisplay, ...defaults};
    }

    invokeClasses(subClasses, isotope, zoomLevel, isotopes = []) {
        let that = this;

        _.forEach(subClasses, function(cls) {
            // classProxy is the dynamically created class instance
            // if the list is empty like level 0 nothing will be created.
            let classProxy = new cls['className'](isotope, that.items, that.squareSize, isotopes);

            _.forEach(cls.methods ,function(method){
                if (that.methodsToRender[method].display) {
                    classProxy[method](zoomLevel);
                }
            });
        });

    }

    createGrid(zoomLevel) {
        let that = this;
        if (zoomLevel >= 5) {

            let isotopes = this.nuclideData.getIsotopesFromObject(this.nuclide.name);

            _.forEach(isotopes, function(isotope) {
                let subClasses = squareGridProxy[zoomLevel]
                    .filter(cls => cls.isotopeLength == isotopes.length)
                    .filter(cls => cls.sValue == isotope.s);
                that.invokeClasses(subClasses, isotope, zoomLevel, isotopes);
            })
        } else {
            // For performance reasons, we don't create grids before zoom level 5.
            this.invokeClasses(squareGridProxy[zoomLevel], this.nuclide, zoomLevel);
        }

    }
}
export default SquareGrid;