import _ from 'lodash';
import highlightSquares from "./highlightSquares";
import drawArrows from "./drawArrows";
import createChain from "./decayChainCreator";

function updateDecayNuclideOpacity(nuclides, locationItem, chart) {
    chart.eachLayer(function(layer) {
            if (!layer.isNeeded) {
                chart.removeLayer(layer);
            }
        }
    );

    nuclides.forEach(nuclide => nuclide.opacity = 0.2);
    const chain = createChain(locationItem, nuclides);
    const chainForRender = _.uniqBy(chain, (nuclide => nuclide.name));
    chainForRender.forEach(nuclide => {
        highlightSquares(nuclides, nuclide);
        nuclide.modes.forEach(mode => {
            drawArrows(nuclide, mode, nuclides);
        })
    });

    return nuclides;
}

export default updateDecayNuclideOpacity;
