import L from "leaflet";
import {chart} from "../../components/NuclideChartContainer";
import decayXYMap from "./decayXYMap";
import getModifiedDecays from "./getModifiedDecays";
import hasParentheses from "./hasParentheses";
import DecayTypes from "./DecayTypes";

// IDE says these are unused, but actually injects the custom marker and custom arrow to L so don't remove
import swoopyArrow from "./CustomArrow";
import CustomMarker from "./CustomMarker";

export default function drawArrows(parentNuclide, decayType, nuclides) {
    /**
     if the mode has parentheses, we need to fire a function to get the daughter
     nuclide of each primary decay and then use those N, Z coords as the starting point.
     Then hit xy map to determine the ending coords relative to the new "decayed" parent]

     otherwise we just draw the arrow basing start and end points off the parent coordinates and decay type
     **/

    if (hasParentheses(decayType)) {
        const {N, Z} = getDecayedDaughterCoordinates(parentNuclide, parentNuclide.primaryDecay);
        drawArrow({N, Z}, decayType, nuclides);
    } else {
        drawArrow(parentNuclide, decayType, nuclides)
    }

}

const drawArrow = (parentCoords, decayType, nuclides) => {
    const strippedDecayWithNums = getModifiedDecays(decayType);

    const modesWithReversedFactor = [DecayTypes.BETA_MINUS, DecayTypes.DOUBLE_BETA_MINUS, DecayTypes.D_DELAYED];
    const arrowFeatures = {
        weight: '2',
        color: '#515151',
        iconAnchor: [30, 10],
        minZoom: 5,
        factor: modesWithReversedFactor.includes(decayType) ? -1 : 1
    };

    const leafletOffsets = decayLatLngMap[decayType];

    const daughterCoords = {
        N: parentCoords.N + decayXYMap[strippedDecayWithNums].N,
        Z: parentCoords.Z + decayXYMap[strippedDecayWithNums].Z
    };

    const arrowStart = [parentCoords.Z + leafletOffsets.parentZ, parentCoords.N + leafletOffsets.parentN];
    const arrowEnd = [daughterCoords.Z + leafletOffsets.daughterZ, daughterCoords.N + leafletOffsets.daughterN];

    let coordNuclide = nuclides.filter(n => n.Z === daughterCoords.Z)
                                .filter(n => n.N === daughterCoords.N);
    const firstNuclide = coordNuclide[0];
    const isRowColumnElementOrN0Entry = (firstNuclide.N === 0 && firstNuclide.Z === 0) || firstNuclide.location === 'row' || firstNuclide.location === 'element' || firstNuclide.location === 'column';

    if(! isRowColumnElementOrN0Entry) {
        L.swoopyArrow(
            arrowStart,
            arrowEnd,
            arrowFeatures
        ).addTo(chart);

        L.customMarker(
            arrowEnd,
            {
                label: decayType,
                iconAnchor: [30, 10],
                minZoom: 6,
                className: hasParentheses(decayType) ? 'arrow-label delayed-decay-arrow-label' : 'arrow-label'
            }
        ).addTo(chart);
    }
};

const getDecayedDaughterCoordinates = ({N, Z}, primaryDecay) => {
    const strippedDecayWithNums = getModifiedDecays(primaryDecay);
    const offsetN = decayXYMap[strippedDecayWithNums].N;
    const offsetZ = decayXYMap[strippedDecayWithNums].Z;
    return {N: N + offsetN, Z: Z + offsetZ};
};

const decayLatLngMap = {
    [DecayTypes.ALPHA]: {'parentZ': -256, 'parentN': 0.5, 'daughterZ':-255.2, 'daughterN':1.1},
    [DecayTypes.DOUBLE_ALPHA]: {'parentZ': -256.1, 'parentN': 0.5, 'daughterZ':-255.2, 'daughterN':1.1},
    [DecayTypes.DELAYED_ALPHA]: {'parentZ': -256, 'parentN': 0.5, 'daughterZ':-255.6, 'daughterN':1.1},
    [DecayTypes.DELAYED_DOUBLE_ALPHA]: {'parentZ': -256.1, 'parentN': 0.5, 'daughterZ':-255.2, 'daughterN':1.1},
    [DecayTypes.BETA_MINUS]: {'parentZ': -254.9, 'parentN': 0.5, 'daughterZ':-255.6, 'daughterN':1.1},
    [DecayTypes.DOUBLE_BETA_MINUS]: {'parentZ': -254.9, 'parentN': 0.6, 'daughterZ':-255.3, 'daughterN':1.1},
    [DecayTypes.BETA_PLUS]: {'parentZ': -255.6, 'parentN': 1, 'daughterZ':-254.9, 'daughterN':0.25},
    [DecayTypes.ELECTRON_CAPTURE]: {'parentZ': -255.2, 'parentN': 1, 'daughterZ':-254.9, 'daughterN':0.75},
    [DecayTypes.DOUBLE_ELECTRON_CAPTURE]: {'parentZ': -255.6, 'parentN': 1.1, 'daughterZ':-254.9, 'daughterN':0.5},
    [DecayTypes.N]: {'parentZ': -256, 'parentN': 0.5, 'daughterZ':-256, 'daughterN':0.5},
    [DecayTypes.TWO_N]: {'parentZ': -256, 'parentN': 0.5, 'daughterZ':-256, 'daughterN':0.5},
    [DecayTypes.N_DELAYED]: {'parentZ': -256, 'parentN': 0.5, 'daughterZ':-256, 'daughterN':0.5},
    [DecayTypes.TWO_N_DELAYED]: {'parentZ': -256, 'parentN': 0.5, 'daughterZ':-256, 'daughterN':0.75},
    [DecayTypes.THREE_N]: {'parentZ': -256, 'parentN': 0.5, 'daughterZ':-256, 'daughterN':0.5},
    [DecayTypes.THREE_N_DELAYED]: {'parentZ': -256, 'parentN': 0.5, 'daughterZ':-256, 'daughterN':0.25},
    [DecayTypes.FOUR_N]: {'parentZ': -256, 'parentN': 0.5, 'daughterZ':-256, 'daughterN':0.5},
    [DecayTypes.FOUR_N_DELAYED]: {'parentZ': -256, 'parentN': 0.5, 'daughterZ':-256, 'daughterN':0.5},
    [DecayTypes.P]: {'parentZ': -255.3, 'parentN': 1, 'daughterZ':-255.5, 'daughterN':1},
    [DecayTypes.P_DELAYED]: {'parentZ': -255.3, 'parentN': 1, 'daughterZ':-255.75, 'daughterN':1},
    [DecayTypes.TWO_P]: {'parentZ': -255.3, 'parentN': 1, 'daughterZ':-255.5, 'daughterN':1},
    [DecayTypes.TWO_P_DELAYED]: {'parentZ': -255.3, 'parentN': 1, 'daughterZ':-255.5, 'daughterN':1},
    [DecayTypes.THREE_P_DELAYED]: {'parentZ': -255.3, 'parentN': 1, 'daughterZ':-255.5, 'daughterN':1},
    [DecayTypes.T_DELAYED]: {'parentZ': -256, 'parentN': 0.5, 'daughterZ':-255.8, 'daughterN':1.1},
    [DecayTypes.D_DELAYED]: {'parentZ': -255.25, 'parentN': 0, 'daughterZ':-255, 'daughterN':0.8},
};
