import _ from "lodash";
import {fabric} from 'fabric';


class ElementSquare {
    constructor(element, size, isSymbolBumpedUp) {
        this.element = element;
        this.items = [];
        this.size = size;
        this.isSymbolBumpedUp = isSymbolBumpedUp || false;
        this.notShown = _.inRange(this.element.Z, 104, 119);
        this.colorizeCommonOxi = this.colorizeCommonOxi.bind(this);
    }

    addLabel() {
        let fontSizeMap = {
            255: this.element.symbol.length > 10 ? 38 : 40,
            127: this.element.symbol.length > 10 ? 20 : 22,
            63: 18,
            31: this.element.symbol.length > 10 ? 9 : 11,
            15: this.element.symbol.length > 10 ? 5 : 6,
        };

        let originMap = {
            255: 'left',
            127: 'left',
            63: 'bottom',
            31: 'bottom',
            15: 'bottom'
        };

        let label = new fabric.Text(this.element.symbol, {
            fontSize: fontSizeMap[this.size],
            fill: 'black',
            fontWeight: 'bold',
            fontFamily: 'Arial',
            top: -this.size / 1.5,
            originX: originMap[this.size],
            left: -this.size,
            objectCaching: false
        });
        this.items.push(label);
    }

    // This draws the chart
    addBorder() {
        let rect = new fabric.Rect({
            fill: 'transparent',
            stroke: this.element.isLabel ? 'white' : 'black',
            strokeWidth: 1,
            width: this.element.isLabel ? this.size  : this.size,
            height: this.size,
            objectCaching: false,
            top: this.element.isLabel ? -this.size  : 0,
            left: this.element.isLabel ? -this.size : 0,
        });
        this.items.push(rect);
    }

    addSymbol() {
        let color =
            this.element.phase === 'gas' ? "green" :
                this.element.phase === "liquid" ? "blue" :
                    this.element.phase === "solid" ? "black" :
                        "red";

        let name = this.element.symbol === 'La' || this.element.symbol === 'Ac' ? `${this.element.symbol}*` : this.element.symbol;

        let fontSizeMap = {
            255: 45,
            127: 27,
            63: 18,
            31: 12,
            15: 7,
        };

        let symbol = new fabric.Text(name, {
            fontSize: fontSizeMap[this.size],
            fill: color,
            fontWeight: 'bold',
            fontFamily: 'Arial',
            top: this.isSymbolBumpedUp ? (this.size/10) : this.size/2 - this.size/6,
            originX: 'center',
            left: this.size/2,
            objectCaching: false
        });
        this.items.push(symbol);
    }

    addName() {
        let color =
            this.element.phase === 'gas' ? "green" :
                this.element.phase === "liquid" ? "blue" :
                    this.element.phase === "solid" ? "black" :
                        "red";

        let name = new fabric.Text(this.element.element_name, {
            left: this.size/2,
            originX: 'center',
            top: this.size/2,
            originY: 'center',
            fontFamily: 'Arial',
            fontSize: this.size === 127 ? (this.element.element_name.length > 11 ? 15 : 18) : 26,
            fontWeight: 'bold',
            fill: color,
            objectCaching: false
        });
        this.items.push(name);
    }

    addAtomicWeight() {
        let weight = this.element.atomic_weight ? this.element.atomic_weight : '';
        let atomicWeight = new fabric.Text(weight, {
            left: 256/2,
            originX: 'center',
            top: 256/2 - 24,
            originY: 'center',
            fontSize: 28,
            fontWeight: 'bold',
            objectCaching: false
        });
        this.items.push(atomicWeight);
    }
    addAtomicNumber() {
        let atomicNumber = new fabric.Text(this.element.Z.toString(), {
            textAlign: 'left',
            left: 15,
            top: 35,
            fontSize: 30,
            fontWeight: 'bold',
            objectCaching: false
        });
        this.items.push(atomicNumber);
    }

    addElectronegativity() {
        let electronegativity = this.element.electronegativity ? this.element.electronegativity : '-';
        if (!this.notShown) {
            let electro = new fabric.Text(electronegativity, {
                textAlign: 'center',
                originX: 'center',
                left: 256 - electronegativity.length-36,
                top: 45,
                fontSize: 20,
                objectCaching: false
            });
            this.items.push(electro);
        }
    }

    addOxidationState() {
        let oxidation = this.element.oxidation_state ? this.element.oxidation_state : '';
        const oxiState = new fabric.IText(oxidation, {
            textAlign: 'center',
            originX: 'center',
            left: 256 - oxidation.length - 36,
            top: 10,
            fontSize: 20,
            objectCaching: false,
        });

        if (this.element.most_common_oxidation_state) {
            oxiState.set({ styles: { "0": this.colorizeCommonOxi(oxidation) } });
        }
        this.items.push(oxiState);
    }

    colorizeCommonOxi(oxidation) {
        if (oxidation.includes('\u2212')) {
            oxidation = oxidation.replace('\u2212', '-');
        }

        const oxiArray = oxidation.split(",")
        const mostCommonOxiArray = this.element.most_common_oxidation_state.split(",");
        const indexes = [];
        oxiArray.forEach(o => {
            if (mostCommonOxiArray.includes(o)) {
                const startIndex = oxidation.indexOf(o);
                indexes.push(startIndex);
                if (o.length > 1) indexes.push(startIndex + 1);
            }
        });

        const stylesObj = {};
        indexes.forEach(i => stylesObj[i] = { "fill": "red" });
        return stylesObj;
    }

    addAtomicRadius() {
        let atomicRadius = this.element.atomic_radius ? this.element.atomic_radius : '';
        let radiusText = new fabric.Text(atomicRadius, {
            textAlign: 'left',
            originX: 'right',
            left: 248,
            top: 256/2+20,
            fontSize: 20,
            objectCaching: false
        });
        this.items.push(radiusText);
    }

    addCrustalAbundance() {
        let crustalA = this.element.crustal_abundance ? this.element.crustal_abundance : '';
        let crustalText = new fabric.Text(crustalA, {
            textAlign: 'left',
            originX: 'right',
            left: 248,
            top: 225,
            fontSize: 20,
            objectCaching: false
        });
        this.items.push(crustalText);
    }

    addXrayEnergies() {
        let kA = this.element.K_alpha ? this.element.K_alpha : '';
        let kB = this.element.K_beta ? this.element.K_beta : '';
        let lA = this.element.L_alpha ? this.element.L_alpha : '';
        let lB = this.element.L_beta ? this.element.L_beta : '';
        if (kA || kB) {
            let mergedK = kA+'\n'+kB;
            let xrayText = new fabric.Text(mergedK, {
                textAlign: 'right',
                fill: 'red',
                originX: 'right',
                left: 248,
                top: 256/2+45,
                fontSize: 20,
                objectCaching: false
            });
            this.items.push(xrayText);
        } else if (lA || lB) {
            let mergedKL = lA+'\n'+lB;
            let xrayText = new fabric.Text(mergedKL, {
                textAlign: 'right',
                fill: 'blue',
                originX: 'right',
                left: 248,
                top: 256/2+45,
                fontSize: 20,
                objectCaching: false
            });
            this.items.push(xrayText);
        }
    }

    addMeltingPoint() {
        let meltingPoint = this.element.melting_point ? this.element.melting_point : '';
        let meltingText = new fabric.Text(meltingPoint, {
            textAlign: 'left',
            fill: 'blue',
            left: 8,
            top: 256/2+20,
            fontSize: 20,
            objectCaching: false
        });
        this.items.push(meltingText);
    }

    addBoilingPoint() {
        let boilingPoint = this.element.boiling_point ? this.element.boiling_point  : '';
        let boilingText = new fabric.Text(boilingPoint, {
            textAlign: 'left',
            fill: 'red',
            left: 8,
            top: 256/2+45,
            fontSize: 20,
            objectCaching: false
        });
        this.items.push(boilingText);
    }

    addSpecificGravity() {
        let color = this.element.phase === 'gas' ? "green" : "black";
        let gravity = this.element.specific_gravity ? this.element.specific_gravity  : '';
        let gravityText = new fabric.Text(gravity, {
            textAlign: 'left',
            left: 8,
            fill: color,
            top: 256/2+70,
            fontSize: 20,
            objectCaching: false
        });
        this.items.push(gravityText);
    }

    addThermalConductivity() {
        let thermalConductivity = this.element.thermal_conductivity ? this.element.thermal_conductivity  : '';
        let thermalText = new fabric.Text(thermalConductivity, {
            textAlign: 'left',
            left: 8,
            top: 225,
            fontSize: 20,
            objectCaching: false
        });
        this.items.push(thermalText);
    }
}

export default ElementSquare;