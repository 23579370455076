import React from 'react';
import './DataLegend.css';
import DecayTypes from "../../../helpers/decaychain/DecayTypes";
import cluster from '../../../static/cluster-mini.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsAltV, faArrowsAltH } from '@fortawesome/free-solid-svg-icons'

export default function DataLegend() {

    const decayText = [
        {
            symbol: DecayTypes.ALPHA,
            text: 'alpha particle'
        },
        {
            symbol: DecayTypes.BETA_MINUS,
            text: 'beta minus'
        },
        {
            symbol: DecayTypes.BETA_PLUS,
            text: 'beta plus (positron)'
        },
        {
            symbol: DecayTypes.GAMMA,
            text: 'gamma ray'
        },
        {
            symbol: DecayTypes.N,
            text: 'neutron'
        },
        {
            symbol: DecayTypes.P,
            text: 'proton'
        },
        {
            symbol: DecayTypes.D,
            text: 'deuteron'
        },
        {
            symbol: DecayTypes.T,
            text: 'triton'
        },
        {
            symbol: DecayTypes.ELECTRON_CAPTURE,
            text: 'electron capture'
        },
        {
            symbol: 'IT',
            text: 'isomeric transition'
        },
        {
            symbol: 'e\u207B',
            text: 'conversion electron'
        },
        {
            symbol: DecayTypes.DOUBLE_BETA_MINUS,
            text: 'double beta'
        },
        {
            symbol: 'cluster',
            text: 'cluster'
        },
        {
            symbol: 'D',
            text: 'delayed radiation',
        },
        {
            symbol: 'SF',
            text: 'spontaneous fission'
        },
        {
            symbol: 'E, E\u2212, E\u002B',
            text: 'disintegration energy'
        }
    ];

    const symbolText = [
        {
            symbol: '\u03c3',
            text: 'neutron cross section'
        },
        {
            symbol: 'b',
            text: 'barn'
        },
        {
            symbol: '\u03c9',
            text: 'absolute abundance less than 1% (weak)'
        },
        {
            symbol: '\u03BD\u03c9',
            text: 'absolute abundance less than 1.0E-3% (very weak)'
        },
        {
            symbol: '\u03BD\u03BD\u03c9',
            text: 'absolute abundance less than 1.0E-6% (very, very weak)'
        },
        {
            symbol: 'E',
            text: 'indicates exponential format, e.g., 1.06E11 a is 1.06 x 10\u00B9\u00B9 years'
        },
        {
            symbol: 'horizontal',
            text: 'indicates assignment to metastable (m) and ground (g) states inconclusive'
        },
        {
            symbol: 'vertical',
            text: 'indicates assignment of m\u2082 and m\u2081 states inconclusive'
        },
        {
            symbol: '\u03c7',
            text: 'unspecified number of particles of a given type emitted, e.g., \u03c7p'
        }
    ];

    const timeText = [
        {
            symbol: 's',
            text: 'seconds'
        },
        {
            symbol: 'm',
            text: 'minutes'
        },
        {
            symbol: 'h',
            text: 'hours'
        },
        {
            symbol: 'd',
            text: 'days'
        },
        {
            symbol: 'a',
            text: 'years'
        }
    ];

    const prefixText = [
        {
            symbol: 'p',
            text: 'pico',
            unit: '(1.0E-12)'
        },
        {
            symbol: 'n',
            text: 'nano',
            unit: '(1.0E-9)'
        },
        {
            symbol: '\u03Bc',
            text: 'micro',
            unit: '(1.0E-6)'
        },
        {
            symbol: 'm',
            text: 'milli',
            unit: '(1.0E-3)'
        }
    ];

    return (
        <div className='outer-container'>
        <h2>LEGEND</h2>
            <div className='grid-container'>
                <div className='box' id='decay'>
                    <h3 className='headers'>Radiation and Decay</h3>
                    <div className='inner-container'>
                        {decayText.map(item => {
                            return (
                                <React.Fragment key={item.symbol}>
                                    {item.symbol === 'cluster' ? <span style={{paddingTop: '6px'}}><img style={{width: '20px', height: '18px'}} src={cluster} alt="cluster"/> </span> : <span className='text'>{item.symbol}</span>}
                                    <span className='text'>{item.text}</span>
                                </React.Fragment>
                            )
                        })}
                    </div>
                </div>
                <div className='box' id='symbols'>
                    <h3 className='headers'>Other Symbols</h3>
                    <div className='inner-container'>
                        {symbolText.map(item => {
                            return (
                                <React.Fragment key={item.symbol}>
                                    {item.symbol === 'horizontal' && <span style={{alignSelf: 'center'}}><FontAwesomeIcon icon={faArrowsAltH} size='lg'/></span>}
                                    {item.symbol === 'vertical' && <span style={{alignSelf: 'center'}}><FontAwesomeIcon icon={faArrowsAltV} size='lg'/></span>}
                                    {(item.symbol !== 'horizontal' && item.symbol !== 'vertical') && <span style={{alignSelf: 'center'}} className='text'>{item.symbol}</span>}
                                    <span className='text'>{item.text}</span>
                                </React.Fragment>
                            )
                        })}
                    </div>
                </div>
                <div className='box' id='time'>
                    <h3 className='headers'>Time</h3>
                    <div className='inner-container'>
                        {timeText.map(item => {
                            return (
                                <React.Fragment key={item.symbol}>
                                    <span className='text'>{item.symbol}</span>
                                    <span className='text'>{item.text}</span>
                                </React.Fragment>
                            )
                        })}
                    </div>
                </div>
                <div className='box' id='unit-prefixes'>
                    <h3 className='headers'>Unit Prefixes</h3>
                    <div className='unit-container'>
                        {prefixText.map(item => {
                            return (
                                <React.Fragment key={item.symbol}>
                                    <span className='text'>{item.symbol}</span>
                                    <span className='text'>{item.text}</span>
                                    <span className='text'>{item.unit}</span>
                                </React.Fragment>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}
