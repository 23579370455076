import {fabric} from 'fabric';
import magicNumbers from '../../../magicNumbers';
import _ from 'lodash';

class DefaultSquare {
    constructor(nuclide, items, squareSize, isOnChart = true) {
        this.nuclide = nuclide;
        this.items = items;
        this.squareSize = squareSize;
        this.isOnChart = isOnChart;
        this.addBorder();
    }

    makeBold() {
        if (this.nuclide.isRow || this.nuclide.isColumn || this.nuclide.location === 'element') {
            return;
        }


        // TODO clean this logic up
        magicNumbers.forEach(num => {

          if (this.nuclide.Z === num) {
              let topLine = new fabric.Line([0, 0, this.squareSize, 0], {
                  left: 0,
                  top: 0,
                  stroke: 'black',
                  strokeWidth: this.squareSize === 256 ? 6 : 4,
                  objectCaching: false
              });

              let bottomLine = new fabric.Line([0, 0, this.squareSize, 0], {
                  left: 0,
                  top: this.squareSize - (this.squareSize * 0.023),
                  stroke: 'black',
                  strokeWidth: this.squareSize === 256 ? 6 : 4,
                  objectCaching: false
              });
              this.items.push(topLine);
              this.items.push(bottomLine);
          }

          if (this.nuclide.N === num) {
              let leftLine = new fabric.Line([-2, 0, -2, this.squareSize], {
                  left: 0,
                  top: 0,
                  stroke: 'black',
                  strokeWidth: this.squareSize === 256 ? 6 : 4,
                  objectCaching: false
              });

              let rightLine = new fabric.Line([-2, 0, -2, this.squareSize], {
                  left: this.squareSize - (this.squareSize * 0.023),
                  top: 0,
                  stroke: 'black',
                  strokeWidth: this.squareSize === 256 ? 6 : 4,
                  objectCaching: false
              });
              this.items.push(leftLine);
              this.items.push(rightLine);
          }
      })
    }

    addBorder() {
        let rect = new fabric.Rect({
            fill: 'transparent',
            stroke: 'black',
            strokeWidth: this.squareSize < 3 ? 0.5 : 1,
            width: this.squareSize - 1,
            height: this.squareSize - 1,
            objectCaching: false
        });

        const levelSizes = [128, 256];  // square sizes at level 7 and 8
        if (levelSizes.includes(this.squareSize) && this.isOnChart) {
            this.makeBold(rect);
        }
        this.items.push(rect);
    }

    addBlackBand(multiplier = 16) {
        if (this.nuclide.blackband || _.startsWith(this.nuclide.name, 'Ta180')) {
            let rect = new fabric.Rect({
                fill: 'black',
                width: this.squareSize - 1,
                height: Math.log10(this.squareSize) * multiplier,
                objectCaching: false
            });
            this.items.push(rect);
        }
    }

    addName(fontMultiplier = 10, heightMultiplier = 56) {
        let nuclideName = this.nuclide.questionable ? this.nuclide.name + this.nuclide.questionable : this.nuclide.name;
        let color = this.nuclide.blackband || _.startsWith(this.nuclide.name, 'Ta180') ? 'white' : 'black';
        let name = new fabric.Text(nuclideName, {
            fill: color,
            fontSize: Math.log10(this.squareSize) * fontMultiplier, // ¯\_(ツ)_/¯
            originX: 'center',
            fontFamily: 'Arial',
            top: this.squareSize / heightMultiplier,
            left: this.squareSize / 2,
            objectCaching: false
        });
        this.items.push(name);
    }

    addMass() {
        let text = this.nuclide.mass ? this.nuclide.mass : '';
        let mass = new fabric.Text(text, {
            top: this.squareSize / 1.2,
            originX: 'center',
            left: (this.squareSize - 1) / 2,
            fontSize: this.squareSize / 8,
            objectCaching: false
        });
        this.items.push(mass);
    }

    addClusterSymbol() {
        function getCircle(x, y) {
            let circle = new fabric.Circle({
                radius: 6,
                left: x,
                top: y,
                stroke: 'black',
                strokeWidth: 2,
                fill: 'white',
                objectCaching: false
            });
            return circle
        }

        let x = 232;
        let y = 232;
        let offset = 6;
        this.items.push(getCircle(x, y - offset));
        this.items.push(getCircle(x - offset, y));
        this.items.push(getCircle(x + offset, y));
        this.items.push(getCircle(x - 1, y + offset));

    }
}

export default DefaultSquare;