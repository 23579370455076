import config from './aws-config';

export default () => {
    //
    if (process.env.REACT_APP_STAGE !== 'dev') {
        window.dataLayer = window.dataLayer || [];
        function gtag() {window.dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', config.GA.ID);

        const script = window.document.createElement('script');
        script.async = 1;
        script.src = `https://www.googletagmanager.com/gtag/js?id=${config.GA.ID}`;
        const elem = window.document.getElementsByTagName('script')[0];
        elem.parentNode.insertBefore(script, elem);
    }
}
