import DecayTypes from "./DecayTypes";

const decayXYMap = {
    [DecayTypes.ALPHA]: {'Z': -2, 'N': -2}, // alpha
    [DecayTypes.DOUBLE_ALPHA]: {'Z': -4, 'N': -4}, // double alpha
    [DecayTypes.BETA_MINUS]: {'Z': 1, 'N': -1}, // beta minus
    [DecayTypes.DOUBLE_BETA_MINUS]: {'Z': 2, 'N': -2}, // double beta minus
    [DecayTypes.BETA_PLUS]: {'Z': -1, 'N': 1}, // beta plus
    [DecayTypes.ELECTRON_CAPTURE]: {'Z': -1, 'N': 1}, // epsilon, electron capture (same as b+)
    [DecayTypes.DOUBLE_ELECTRON_CAPTURE]: {'Z': -2, 'N': 2}, // double electron capture
    [DecayTypes.N]: {'Z': 0, 'N': -1},
    [DecayTypes.TWO_N]: {'Z': 0, 'N': -2},
    [DecayTypes.THREE_N]: {'Z': 0, 'N': -3},
    [DecayTypes.FOUR_N]: {'Z': 0, 'N': -4},
    [DecayTypes.P]: {'Z': -1, 'N': 0},
    [DecayTypes.TWO_P]: {'Z': -2, 'N': 0},
    [DecayTypes.THREE_P]: {'Z': -3, 'N': 0}, // no 4p decay
    [DecayTypes.T]: {'Z': -1, 'N': -2},
    [DecayTypes.D]: {'Z': -1, 'N': -1},
};

export default decayXYMap;
