import {fabric} from "fabric";
import * as TextCreators from './legendTextCreators';
import * as LineCreators from "./legendLineCreators";

const attributeToLegendTextMap = {
    'Symbol': TextCreators.addSymbolInfo,
    'abundance': TextCreators.addAbundanceInfo,
    'halflife': TextCreators.addHalfLifeInfo,
    'decay1': TextCreators.addDecay,
    'xs1': TextCreators.addCrossSectionResonance,
    'jpi': TextCreators.addSpinParityInfo,
    'cluster': TextCreators.addCluster,
    'mass': TextCreators.addMassInfo,
    'E': TextCreators.addDisintegrationEnergyInfo,
    'Eminus': TextCreators.addDisintegrationEnergyInfo,
    'fpsymbol': TextCreators.applyYield,
    'doublearrow': TextCreators.applyArrow
};

const attributeLegendLineMap = {
    'Symbol': LineCreators.addSymbolLine,
    'abundance': LineCreators.addAbundanceLine,
    'halflife': LineCreators.addHalflifeLine,
    'decay1': LineCreators.addDecayLines,
    'xs1': LineCreators.addCrossSectionResonanceLine,
    'jpi': LineCreators.addSpinParityLine,
    'cluster': LineCreators.addClusterLine,
    'mass': LineCreators.addMassLine,
    'E': LineCreators.addDisintegrationEnergyLine,
    'Eminus': LineCreators.addDisintegrationEnergyLine,
    'fpsymbol': LineCreators.addYieldLine,
    'doublearrow': LineCreators.addArrowLine
};

const removeDuplicateObjectsByProperty = (arrayWithDuplicates, property) => {
    const arrayOfProperty = arrayWithDuplicates.map(entry => entry[property]);

    return arrayWithDuplicates.filter((element, index) => {
        return arrayOfProperty.indexOf(element[property]) == index;
    });
};

const generateLegendTextForNuclide = (nuclideStates) => {
    const fabricItems = [];
    const lineItems = [];

    Object.entries(attributeToLegendTextMap).forEach(([attribute, method]) => {
        nuclideStates.forEach(state => {
            if (attribute === 'cluster' && state[attribute] === '1') {
                fabricItems.push(method(state));
                lineItems.push(attributeLegendLineMap[attribute]());
            }

            if (attribute !== 'cluster' && state[attribute]) {
                fabricItems.push(method(state));
                lineItems.push(attributeLegendLineMap[attribute](state, nuclideStates));
            }
        });
    });

    const deDupedText = removeDuplicateObjectsByProperty(fabricItems.filter(Boolean), 'text');
    const textAndLinesForRender = deDupedText.concat(lineItems);

    return new fabric.Group(textAndLinesForRender, {
        objectCaching: false,
        hasBorders: false,
        hasControls: false,
        hasRotatingPoint: false,
        lockMovementX: true,
        lockMovementY: true,
        hoverCursor: 'default',
        selectable: false,
    });
};

export {
    generateLegendTextForNuclide
}
