import React, { Component } from "react";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import {addColorFilterAttribute, removeColorFilterAttribute, updateColorFilterValues} from '../../store/actions/ColorFilterAction';
import { connect } from 'react-redux';
import ColorFilterSelector from './ColorFilterSelector';
import DataExport from "./DataExport";

const options = [
    { value: 'natural abundance', label: 'Natural Abundance' },
    { value: 'disintegration energy', label: 'Disintegration Energy'},
    { value: 'half life', label: 'Half-life'},
    { value: 'decay mode', label: 'Decay Mode'},
    { value: 'alpha energy', label: 'Alpha Energy'},
    { value: 'beta energy', label: 'Beta Energy'},
    { value: 'gamma energy', label: 'Gamma Energy'},
    { value: 'neutron absorption', label: 'Neutron Absorption'},
    { value: 'cross section', label: 'Cross Section'},
    { value: 'resonance integral', label: 'Resonance Integral'},
    { value: 'spin', label: 'Spin'},
    { value: 'parity', label: 'Parity'}
];

class ColorFilterSpec extends Component{
    constructor(props) {
        super(props);
        this.state = {
            selectedValue: {
                label: '',
                value: '',
            },
            confirmationText: ''
        };

        this.changeDropdown = this.changeDropdown.bind(this);
        this.changeValue = this.changeValue.bind(this);
        this.addAttribute = this.addAttribute.bind(this);
        this.removeAttribute = this.removeAttribute.bind(this);
    }

    changeDropdown(e) {
        this.setState({selectedValue: {label: e.label, value: e.value}});
    }

    changeValue(e) {
        this.props.updateColorFilterValues({attribute: e.target.name, param: e.target.id, val: e.target.value});
    }

    addAttribute() {
        this.props.addColorFilterAttribute({attribute: this.state.selectedValue});
        this.setState({
            selectedValue: {
                label: '',
                value: '',
            },
            confirmationText: '**Adjustments succesfully applied'
        });
        this.timer = setTimeout(() => {
            this.setState({
                confirmationText: ''
            })
        }, 4000);

    }


    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    removeAttribute(attribute) {
        this.props.removeColorFilterAttribute({attribute: attribute});
    }

    render() {
        let selectedAttributes = this.props.attributes.map(attribute => attribute.attribute);
        let unselectedOptions = options.filter(option => selectedAttributes.indexOf(option.value) === -1);
        return (
            <div>
                {this.props.attributes.map((attribute) => (
                    <ColorFilterSelector key={attribute.attribute} attribute={attribute} removeAttribute={this.removeAttribute} valueChange={this.changeValue}/>
                ))}
                <div
                   style={{ height: '60px'}}
                >
                <Dropdown
                    options={unselectedOptions}
                    placeholder="Select an option"
                    onChange={this.changeDropdown}
                    value={this.state.selectedValue.label}
                />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-around'}}>
                    <button className="submit" disabled={!this.state.selectedValue.value} onClick={this.addAttribute} >Add Filter</button>
                    <DataExport />
                </div>
                <div style={{display: 'flex', justifyContent: 'space-around'}}>
                    <p style={{fontSize: '0.5em', marginLeft: '1em', color: '#ff8030'}}>
                        {this.state.confirmationText}
                    </p>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    attributes: state.colorFilter,
});

export default connect(mapStateToProps, {addColorFilterAttribute, removeColorFilterAttribute, updateColorFilterValues})(ColorFilterSpec);
