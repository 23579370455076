import {fabric} from "fabric";
import _ from 'lodash';


function createDisintegrationEnergy(nuclide, config) {
    let disintegrationE = nuclide.E
        ? (_.startsWith(nuclide.E, 'E') ? nuclide.E : `E ${nuclide.E}`)
        : (nuclide.Eminus && nuclide.Eplus
            ? (_.startsWith(nuclide.Eminus, 'E-') ? `${nuclide.Eminus} ` :  `E\u2212 ${nuclide.Eminus} `)
            + (config.splitLine || false ? '\n' : '') + (_.startsWith(nuclide.Eplus, 'E+') ? `${nuclide.Eplus}` : `E+ ${nuclide.Eplus}`)
            : '');

    return new fabric.Text(disintegrationE, {
        top: config.top,
        fontSize: config.fontSize,
        left: config.left,
        originX: config.origin,
        objectCaching: false
    });
}

export {
    createDisintegrationEnergy
}