import {fabric} from 'fabric';
import ElementSquare from './ElementSquare';


function createOne(element) {

    let square = new ElementSquare(element, 15);
    square.addBorder();

    if (element.isLabel) {
        square.addLabel();
    } else {
        square.addSymbol();
    }


    let group = new fabric.Group(square.items, {
        objectCaching: false,
    });

    return group;
}

export default createOne;