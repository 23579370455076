import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import ReactModal from 'react-modal';
import VersionScreenModal from '../modals/VersionScreenModal';
import SearchContainer from '../SearchContainer';
import SidebarContent from '../SidebarContent';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle, faQuestionCircle, faTimes} from '@fortawesome/free-solid-svg-icons'
import Info from "../InfoPanels/Info";
import './NavigationContainer.css';
import { slide as Menu } from 'react-burger-menu';
import HamburgerIcon from '../HamburgerIcon';

ReactModal.setAppElement('body');

let styles;
if(navigator.userAgent.match(/Android/i) ) {
    styles = {
      bmMenuWrap: {
        left: '0',
        top: '60px',
        width: '80%',
      },
    }
} else {
    styles = {
      bmMenuWrap: {
        left: '0',
        top: '60px',
        width: '30%',
      },
    }
}
class NavigationContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showVersionScreen: false,
            menuOpen: false,
            showInfo: false,
        };
        this.openVersionScreen = this.openVersionScreen.bind(this);
        this.closeVersionScreen = this.closeVersionScreen.bind(this);
        this.openInfo = this.openInfo.bind(this);
        this.closeInfo = this.closeInfo.bind(this);
        this.handleStateChange = this.handleStateChange.bind(this)
    }

    openVersionScreen() {
        this.setState({
            showVersionScreen: true,
            menuOpen: false
        });
    }

    closeVersionScreen() {
        this.setState({
            showVersionScreen: false
        });
    }

    openInfo() {
       this.setState({
           showInfo: true,
           menuOpen: false
        });
    }

    closeInfo() {
        this.setState({showInfo: false});
    }

    openMenu () {
        this.setState({
          menuOpen: !this.state.menuOpen
        })
    }

    handleStateChange (state) {
        this.setState({menuOpen: state.isOpen})
    }

    render() {
        return (
            <div>
                <nav className="navigation-bar">
                    <ul className="menu-bar">
                        <li className="menu-btn">
                            <HamburgerIcon open={this.state.menuOpen} onClick={()=>this.openMenu()} color='white' />
                            <Menu onStateChange={(state) => this.handleStateChange(state)} isOpen={this.state.menuOpen} customBurgerIcon={false} styles={styles}>
                                <SidebarContent />
                            </Menu>
                        </li>
                        <li>
                            <ul>
                                <li>
                                    <NavLink activeClassName="active" to="/periodic">
                                        <div className="icon periodic" role="img" alt="periodictable"/><span className="sr-only">Goes to Periodic Table</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink activeClassName="active" to="/nuclide">
                                        <div className="icon chart" role="img" alt="nuclidechart" /><span className="sr-only">Goes to Nuclide Chart</span>
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <ul className="menu-bar">
                        <li>
                            <SearchContainer/>
                        </li>
                    </ul>
                      <ul className="menu-bar">
                          <li className="help">
                              <FontAwesomeIcon onClick={this.openVersionScreen} icon ={faInfoCircle} size="xs" />
                              <ReactModal
                                  className= "modal"
                                  overlayClassName= "modal_overlay"
                                  isOpen={this.state.showVersionScreen}
                                  contentLabel="Version Screen Modal"
                                  onRequestClose={this.closeVersionScreen}
                                  closeTimeoutMS={500}
                              >
                                  <FontAwesomeIcon className="modal-button" onClick={this.closeVersionScreen} icon ={faTimes} size="1x" />
                                  <VersionScreenModal />
                              </ReactModal>
                          </li>
                          <li className="help">
                            <FontAwesomeIcon onClick={this.openInfo} icon={faQuestionCircle} size="xs"/>
                            <ReactModal
                                    className="modal info-modal"
                                    overlayClassName="modal_overlay"
                                    isOpen={this.state.showInfo}
                                    contentLabel="Welcome Panel and Legend"
                                    onRequestClose={this.closeInfo}
                                    closeTimeoutMS={500}
                                >
                                    <FontAwesomeIcon className="modal-button" onClick={this.closeInfo} icon ={faTimes} size="1x" />
                                    <Info onClick={this.closeInfo}/>
                                </ReactModal>
                        </li>
                      </ul>
                  </nav>

                  {this.props.children}
              </div>
          )
      }
  }

export default NavigationContainer;
