import {fabric} from 'fabric';
import ElementSquare from './ElementSquare';


export default function createThree(element) {
    let square = new ElementSquare(element, 63);
    square.addBorder();

    if (element.isLabel) {
        square.addLabel();
    } else {
        square.addSymbol();
    }

    let group = new fabric.Group(square.items, {
        objectCaching: false,
    });

    return group;
}