function convertStateToArray(dataDisplay) {
    return Object.keys(dataDisplay).map(key => {
        let data = dataDisplay[key];
        return {...data, name: key};
    });
}

function convertStateToCheckboxArray(dataDisplay) {
    return convertStateToArray(dataDisplay).filter(option => option.type === 'checkbox');
}

function convertStateToRadioButtonArray(dataDisplay) {
    return convertStateToArray(dataDisplay)
        .filter(option => option.type === 'radio')
        .sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
}

function createRadioButtonArrayWithCheckedParam(dataDisplay) {
    let massDisplayed = dataDisplay.addMass.display;
    let disintEDisplayed = dataDisplay.addDisintegrationE.display;

    let stateRadioButtons = convertStateToRadioButtonArray(dataDisplay);

    let radioButtonsForRender = [
        {label: 'Mass And Disintegration Energy', name: 'Both'},
        ...stateRadioButtons,
        {label: 'None', name: 'None'}
    ];

    let checkedMap = {
        addMass: massDisplayed && !disintEDisplayed,
        addDisintegrationE: disintEDisplayed && !massDisplayed,
        Both: massDisplayed && disintEDisplayed,
        None: !massDisplayed && !disintEDisplayed,
    };

    return radioButtonsForRender.map(radioButton => {
        radioButton.checked = checkedMap[radioButton.name];
        return radioButton;
    });
}

export {
    convertStateToCheckboxArray,
    createRadioButtonArrayWithCheckedParam
}