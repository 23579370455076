import {fabric} from 'fabric';
import ElementSquare from './ElementSquare';


export default function createFive(element) {

    let square = new ElementSquare(element, 255, true);
    square.addBorder();

    if (element.isLabel) {
        square.addLabel();
    } else {
        square.addSymbol();
        square.addName();
        square.addAtomicWeight();
        square.addAtomicNumber();
        square.addElectronegativity();
        square.addOxidationState();
        square.addAtomicRadius();
        square.addCrustalAbundance();
        square.addXrayEnergies();
        square.addMeltingPoint();
        square.addBoilingPoint();
        square.addSpecificGravity();
        square.addThermalConductivity();
    }


    let group = new fabric.Group(square.items, {
        objectCaching: false,
    });

    return group;
}