import {createReducer} from "@reduxjs/toolkit";
import {updateDataDisplayCheckbox, updateDataDisplayRadioButton} from "../actions/DataDisplayActions";

export const dataDisplayReducer = createReducer(
    {
        addDecayData: {
            display: true,
            label: 'Decay',
            type: 'checkbox',
        },
        addCrossSectionResonance: {
            display: true,
            label: 'Neutron Absorption Properties',
            type: 'checkbox'
        },
        addHalflifeAbundance: {
            display: true,
            label: 'Half-life/Abundance',
            type: 'checkbox'
        },
        addSpinAndParity: {
            display: true,
            label: 'Spin and Parity',
            type: 'checkbox'
        },
        addIndependentFissionYield: {
            display: true,
            label: 'Independent Fission Yield',
            type: 'checkbox'
        },
        addMass: {
            display: true,
            label: 'Mass Only',
            type: 'radio',
        },
        addDisintegrationE: {
            display: true,
            label: 'Disintegration Energy Only',
            type: 'radio'
        }
    },
    {
        [updateDataDisplayCheckbox]: (state, action) => {
            let filter = state[action.payload.name];
            filter.display = !filter.display;
        },
        [updateDataDisplayRadioButton]: (state, action) => {
            let massFilter = state['addMass'];
            let disintFilter = state['addDisintegrationE'];

            switch (action.payload.value) {
                case 'addMass':
                    massFilter.display = true;
                    disintFilter.display = false;
                    break;
                case 'addDisintegrationE':
                    massFilter.display = false;
                    disintFilter.display = true;
                    break;
                case 'Both':
                    massFilter.display = true;
                    disintFilter.display = true;
                    break;
                case 'None':
                    massFilter.display = false;
                    disintFilter.display = false;
                    break;
                default:
                    massFilter.display = true;
                    disintFilter.display = true;
            }
        }
    }
);
