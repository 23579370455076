import React, {Component} from "react";
import _ from "lodash";
import Dropdown from 'react-dropdown';
import ColorEntry from './ColorEntry';
import {blueSquare, graySquare, greenSquare, orangeSquare, yellowSquare} from "../../colors";

const options = [
    { value: 'natural abundance', label: 'Natural Abundance' },
    { value: 'disintegration energy', label: 'Disintegration Energy' },
    { value: 'half life', label: 'Half-life' },
    { value: 'alpha energy', label: 'Alpha Energy' },
    { value: 'beta energy', label: 'Beta Energy' },
    { value: 'gamma energy', label: 'Gamma Energy' },
    { value: 'neutron absorption', label: 'Neutron Absorption' },
    { value: 'cross section', label: 'Cross Section' },
    { value: 'resonance integral', label: 'Resonance Integral' },
    { value: 'spin', label: 'Spin' },
    { value: 'no color', label: 'No Color'}
];


class ColorEntrySection extends Component{

    render() {
        return (
            <div>
                <Dropdown
                    {...this.props}
                    onChange={this.props.updateDropdown}
                    options={options}
                    value={this.props.dropDownValue}
                    placeholder="Select an option"
                />
                <ColorEntry
                    {...this.props}
                    colorHex={orangeSquare}
                    color="orange"
                />
                <ColorEntry
                    {...this.props}
                    colorHex={yellowSquare}
                    color="yellow"
                />
                <ColorEntry
                    {...this.props}
                    colorHex={greenSquare}
                    color="green"
                />
                <ColorEntry
                    {...this.props}
                    colorHex={blueSquare}
                    color="blue"
                />
                <ColorEntry
                    {...this.props}
                    colorHex={graySquare}
                    color="gray"
                />
            </div>
        )
    }
}

export default ColorEntrySection;
