import React, {Component} from "react";
import ContinuousInputBox from './ContinuousInputBox';

class ColorEntry extends Component{
    render() {
        return (
            <div style={{display: 'flex', flexDirection: 'row'}}>
                <input className='colorSquare' disabled style={{backgroundColor: this.props.colorHex, flex: '1',  marginRight: '3px'}} aria-label='Color Square'/>
                <div style={{display: 'flex', flexDirection: 'column', paddingLeft: '1px'}}>
                    <ContinuousInputBox {...this.props} index={0}/>
                    <ContinuousInputBox {...this.props} index={1}/>
                </div>
            </div>
        )
    }
}

export default ColorEntry;