import {fabric} from "fabric";

function createDoubleArrow(nuclide, config) {
    let doubleArrow;
    let nuclideArrow = nuclide.doublearrow ? nuclide.doublearrow.toUpperCase() : '';

    if (nuclideArrow === 'HORIZONTAL' || nuclideArrow === 'YES' || nuclideArrow === 'HORIZONTAL - BOTTOM') {
        doubleArrow = "\u2194";
    } else if (nuclideArrow === 'VERTICAL') {
        doubleArrow = "\u2195";
    }

    return new fabric.Text(doubleArrow, {
        top: config.top,
        left: config.left,
        originX: 'center',
        fontSize: 24,
        objectCaching: false
    });


}

export {
    createDoubleArrow
}