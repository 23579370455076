
import {createReducer} from "@reduxjs/toolkit";
import {updateDecayDropdown} from "../actions/DiscreteDecayActions";
import _ from'lodash';

export const discreteDecayReducer = createReducer(
    {
        topshade: [],
        bottomshade: []
    },
    {
        [updateDecayDropdown]: (state, action) => {
            let { orientation, sortedValues, colors } = action.payload;
            state[orientation] = sortedValues.map((val, i) => {
              return Object.assign({}, val, { color: colors[i] });
            });
        }
    }
)
