import React from 'react';
import LoadAnimation from './LoadAnimation';

const styles = {
    height: '85vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center'
};
const heading = {
    color: '#515151',
    padding: '3%'
}

const Loading = () => (
    <div style={styles}>
        <h2 style={heading}>Fetching Data...</h2>
        <LoadAnimation />
    </div>
);


export default Loading;