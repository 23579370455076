import Decay from "../Decay";
import getValuesByPrefix from '../GetValuesByPrefix';

export default class DecayMode {
    constructor(nuclide) {
        this.nuclide = nuclide;
        let decay = new Decay(nuclide);
        this.decayList = getValuesByPrefix(decay.nuclide, 'decay')
            .filter(n => n !== null);
    }

    getDecayModes() {
        let list = this.decayList.filter(n => n !== 'no \u03b3')
            .filter(n => n!== 'no \u03b2+')
            .toString();
        return list;
    }
}
