import { configureStore } from '@reduxjs/toolkit';
import { continuousAttributeReducer, locationReducer, dataDisplayReducer, discreteParityReducer, discreteDecayReducer, colorFilterReducer, minimapToggleReducer, chartTypeReducer} from "./reducers";

const store = configureStore({
     reducer: {
        filters: continuousAttributeReducer,
        discreteDecay: discreteDecayReducer,
        discreteParity: discreteParityReducer,
        location: locationReducer,
        dataDisplay: dataDisplayReducer,
        colorFilter: colorFilterReducer,
        minimap: minimapToggleReducer,
        chartType: chartTypeReducer,
    }
});


export default store