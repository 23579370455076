import {fabric} from 'fabric';
import _ from 'lodash';

class RowColSquare {
    constructor(nuclide, items, squareSize, hasLabel=false, axis) {
        this.nuclide = nuclide;
        this.items = items;
        this.squareSize = squareSize;
        this.cumFisYield = require('../../../cum_fis_yield.json').cum_fis_yield;
        if(hasLabel) {
            this.axis = axis;
            const [top, left] = !!this.nuclide.isRow ? [this.squareSize / 3, this.squareSize / 1.3] : [2, this.squareSize / 2];
            this.addRowColLabel(top, left);
        }
        this.addRowColSquare();
        this.addCumulativeFissionYield()
    }
    addRowColSquare() {
        let noBorder = new fabric.Rect({
            stroke: 'white',
            fill: 'transparent',
            strokeWidth: 2,
            width: this.squareSize-2, 
            height: this.squareSize-2,
            objectCaching: false
        });
        this.items.push(noBorder);
    }
    addRowColLabel(top, left) {
        let label = new fabric.Text(this.nuclide[this.axis].toString(), {
            fontSize: this.squareSize/4.5,
            fontWeight: 'bold',
            top: top,
            originX: 'center',
            left: left,
            objectCaching: false
        });
        if (!this.nuclide.isCorner) {
            this.items.push(label);
        }
    }
    addCumulativeFissionYield() {
        let filteredList = this.cumFisYield
            .filter(n => n.U235 !== null || n.U233 !== null || n.PU239 !== null)
        let that = this;
        _.forEach(filteredList, function(fission){
            let point = that.squareSize/2
            if(fission.A === (that.nuclide.Z + that.nuclide.N) && that.axis === 'N') {
                let line = new fabric.Path(`M 0 0 L ${point} ${point} z`);
                line.set({
                    stroke: 'black',
                    strokeWidth: 1,
                    objectCaching: false
                });
                const leftParenthesis = '\uff08';
                const rightParenthesis = '\uff09';
                const leftSquareBracket = '\u005B';
                const rightSquareBracket = '\u005D';

                let U235Fission = fission.U235 ? fission.U235 : '';
                let U233Fission = fission.U233 ? leftSquareBracket+fission.U233+rightSquareBracket : '';
                let PU239Fission = fission.PU239 ? leftParenthesis+fission.PU239+rightParenthesis : '';
                let cumulativeFissionYield;
                if(!U235Fission && !U233Fission && PU239Fission) {
                    cumulativeFissionYield = PU239Fission;
                } else if(U235Fission && !U233Fission && PU239Fission) {
                    cumulativeFissionYield = U235Fission+'\n'+PU239Fission;
                } else {
                    cumulativeFissionYield = U235Fission+'\n'+PU239Fission+'\n'+U233Fission;
                }
                let text = new fabric.Text(cumulativeFissionYield, {
                    fontSize: that.squareSize/10.5,
                    top: that.squareSize/2,
                    originX: 'center',
                    left: that.squareSize/2,
                    textAlign: 'center',
                    objectCaching: false
                })
            that.items.push(line, text);
            }
        })
    }
}

export default RowColSquare