import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import ReactModal from "react-modal";

ReactModal.setAppElement("body");

class PrivacyPolicyContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/privacy-policy" />;
    }

    return (
      <React.Fragment>
        <div id="chart">
          <div style={{ padding: "30px 50px 0" }}>
            <p>
              <strong>Statement of Privacy Policy</strong>
            </p>
          </div>
          <div style={{ fontFamily: "monospace", padding: "0 50px" }}>
            <p>
              When you visit our website, basic information is received and
              stored. This information includes the geographic location from
              which you are accessing the site and usage information about how
              you interact with the site. This information is collected and
              stored to improve the quality of the resources provided and to
              enhance the user experience. The data is not collected or shared
              for any commercial purpose. If you have an unresolved privacy or
              data use concern that we have not addressed satisfactorily, please
              contact:
            </p>
            <p>
              Fluor Marine Propulsion, <br />
              LLC Knolls Atomic Power Laboratory Attention Jason Thompson Bin
              <br />
              122 PO Box 1072 Schenectady, NY 12309-1072 <br />
              Attention Jason Thompson <br />
              Bin 122 PO Box 1072 Schenectady, <br />
              NY 12309-1072
            </p>
          </div>
          <div style={{ width: "40%", margin: '10px 50px' }} className="version_info logo-container">
            <div className="FluorLogo logo-item" role="img" alt="Fluor Logo" />
            <div className="NNLLogo logo-item" role="img" alt="NNL Logo" />
            <div
              className="BechtelLogo logo-item"
              role="img"
              alt="Bechtel Logo"
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default PrivacyPolicyContainer;
