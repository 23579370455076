import {createReducer} from "@reduxjs/toolkit";
import {mddifyChartType} from "../actions/ChartTypeAction";

export const chartTypeReducer = createReducer(
    ''
    ,
    {
        [mddifyChartType]: (state, action) => {
            return action.payload;
        }
    }
);
