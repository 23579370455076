import _ from 'lodash';
import getValuesByPrefix from './GetValuesByPrefix';
import ms from 'ms';
import fromExponential from 'from-exponential';
import DecayTypes from "./decaychain/DecayTypes";

// Convert all values down to milliseconds
let intervalMapping = {
    'a': 31536000000, // milliseconds in a year
    'd': 86400000, // milliseconds in a day
    'h': 3600000, // milliseconds in an hour
    'm': 60000, // in a minute
    's': 1000, // in a second
    'ms': 1, // just 1 ms in a ms
    'μs': 0.001
};

class UnitConverter {
    // This function returns only numeric numbers and scientific notations, removes other characters.
    _cleanNumber(num) {
        var N = Number(num);
        if(parseFloat(num) === N) return N;
        return Number(num.replace(/\D+/g, '')) || 0;
    }

    _stripEverythingButNumbersCommasHyphensAndDecimals(string) {
        return string.replace(/[^0-9.,-]/g, '');
    }

    _symbolIsBetaOrAlphaWithGammaInEntry(symbol, entry) {
        // takes care of Nuclides: Li11, Ne17, Al22, P26, V50, Br78, Cs132, Np236, Be11
        // nuclides have decay entries with both Alpha OR Beta, AND Gamma
        // in all of these cases the gamma energy is what we want, so only return values for gamma's case
        return (symbol === DecayTypes.ALPHA || symbol === '\u03b2') && entry.includes(DecayTypes.GAMMA);
    }

    convertTime(unit) {
        return intervalMapping[unit];
    }

    convertHalfLife(nuclide) {
        return nuclide.halflife && this.handleHalflifeConversion(nuclide.halflife);
    }

    handleHalflifeConversion(halflife) {
        let removesCarrotsTildesAndQuestionMarks = /[<>~?]/g;
        let removesDecimalsOnlyIfNotSucceededByDigit = /\.(?!\d)/;

        let newHalflife = halflife.replace(removesCarrotsTildesAndQuestionMarks, '').replace(removesDecimalsOnlyIfNotSucceededByDigit, '').replace('a', 'y').trim();

        if (newHalflife.indexOf('y') !== -1 || newHalflife.match(/\ds/g)) {
            let char = newHalflife.slice(-1);
            let temp = newHalflife.replace(/[ys]/, '');
            newHalflife = fromExponential(temp) + ' ' + char;
        }

        if (newHalflife.match(/μ/)) {
            let numberWithRemovedUnit = newHalflife.slice(0, -2);
            newHalflife = parseFloat(numberWithRemovedUnit) * 0.001 + ' ms';
        }

        if (newHalflife.indexOf('ns') !== -1) {
            newHalflife = (parseFloat(newHalflife.slice(0, -2)) / 1000000000);
            newHalflife = fromExponential(newHalflife) + ' s';
        }

        if (newHalflife.indexOf('ps') !== -1) {
            newHalflife = (parseFloat(newHalflife.slice(0, -2)) / 1000000000000);
            newHalflife = fromExponential(newHalflife) + ' s';
        }

        let numVal = fromExponential(newHalflife.substr(0, newHalflife.indexOf(' ')));
        let unitOfMeasure = newHalflife.substr(newHalflife.indexOf(' ') + 1);
        let evaluate = numVal + unitOfMeasure;

        return ms(evaluate.replace(/\s/g, ''));
    }

    convertDecayToSymbol(nuclide, symbol) {
        let decays = getValuesByPrefix(nuclide, 'decay')
            .filter(entry => entry !== null && (entry.includes(symbol) || entry.includes( `(${symbol})`) || entry.includes(`2${symbol}`) || entry.includes( `(2${symbol})`)))
            .filter(entry => !this._symbolIsBetaOrAlphaWithGammaInEntry(symbol, entry));
        let allVals = [];

        for (let decay of decays) {
            let decayForProcessing = decay;
            if (_.startsWith(decay, `2\u03b1`) || _.startsWith(decay, `(2\u03b1)`)) {
                decayForProcessing = decay.replace('2', '');
            }

            let strippedDecay = this._stripEverythingButNumbersCommasHyphensAndDecimals(decayForProcessing);

            strippedDecay.split(',').forEach(value => {
                let parseableValue = value;

                if (value.match(/\d-\d/)) {
                    parseableValue = value.substr(value.search(/\d-\d/) + 2);
                }

                let parsedValue = parseFloat(parseableValue.replace('-', ''));
                if (!isNaN(parsedValue)) {
                    allVals.push(parsedValue);
                }
            });
        }


        if (allVals.length === 0) {
            return null;
        }

        return Math.max(...allVals);
    }
    convertDecayToAlpha(nuclide) {
        return this.convertDecayToSymbol(nuclide, '\u03b1');
    }
    convertDecayToBeta(nuclide) {
        return this.convertDecayToSymbol(nuclide, '\u03b2');
    }
    convertDecayToGamma(nuclide) {
        let gammaVal = this.convertDecayToSymbol(nuclide, '\u03b3');
        let ITVal = this.convertDecayToSymbol(nuclide, 'IT');

        if (gammaVal === null && ITVal === null) {
            return null;
        }

        return Math.max(gammaVal, ITVal);
    }

    convertDisintegrationE(nuclide) {
        let energyValues = getValuesByPrefix(nuclide, 'E').filter(n => n !== null);
        let that = this;
        let splittedValues = _.map(energyValues, function(n) {
           if (_.startsWith(n, 'E')) {
               return that._cleanNumber(n.split(' ')[1]);
           } else {
               return that._cleanNumber(n);
           }
        });

        if(splittedValues.length === 0) {
            return null;
        }

        return Math.max(...splittedValues)
    }

    _convertXSValueMicro(value) {
        return parseFloat(value) / 1000000;
    }

    _convertXSValueMilli(value) {
        return parseFloat(value) / 1000;
    }

    _smushParenthesesToOneValue(parenthesesWrappedValue) {
        let cleanedVals = parenthesesWrappedValue.replace(/\(|\)/g, '').split('+');

        return cleanedVals.reduce((total, nextValue) => {
            if (nextValue === '') {
                return total;
            }

            let value = parseFloat(nextValue);
            if (nextValue.indexOf('m') !== -1) {
                value = this._convertXSValueMilli(nextValue);
            }

            if (nextValue.indexOf('μ') !== -1) {
                value = this._convertXSValueMicro(nextValue);
            }

            return total + value;

        }, 0);
    }

    _hasParentheses(value) {
        return value.indexOf('(') !== -1 || value.indexOf(')') !== -1;
    }

    convertXsGeneric(nuclide, index) {
        let cleanedSplitValues = getValuesByPrefix(nuclide, 'xs').filter(n => n !== null)
            .map(xs => {
                return xs.replace(/[^0-9.,Embμ()+]/g, '').split(',')[index];
            })
            .filter(value => value !== undefined);


        if (cleanedSplitValues.length === 0) {
            return null;
        }

        return cleanedSplitValues.reduce((total, xsVal) => {
            let value = parseFloat(xsVal);

            // we'll skip this if it has parentheses as we will need to handle values with
            // either m or μ within the context of parentheses later in the function
            if (xsVal.indexOf('m') !== -1 && !this._hasParentheses(xsVal)) {
                value = this._convertXSValueMilli(xsVal);
            }

            if (xsVal.indexOf('μ') !== -1 && !this._hasParentheses(xsVal)) {
                value = this._convertXSValueMicro(xsVal);
            }

            if (this._hasParentheses(xsVal)) {
                value = this._smushParenthesesToOneValue(xsVal);
            }

            return total + value;
        }, 0);

    }

    convertXsToCrossSection(nuclide) {
        return this.convertXsGeneric(nuclide, 0);
    }

    convertXsToResonance(nuclide) {
        return this.convertXsGeneric(nuclide, 1);
    }

    convertXsToNeutronAbsorption(nuclide) {
        return _.max([this.convertXsToResonance(nuclide), this.convertXsToCrossSection(nuclide)]) || null;
    }

    convertNaturalAbundanceToNumeric(nuclide) {
        return nuclide.abundance ? parseFloat(nuclide.abundance) : null;
    }

    noColor(nuclide) {
        return '';
    }

    convertSpinToNumeric(nuclide) {
        let spinValue = getValuesByPrefix(nuclide, 'jpi').filter(n => n !== null)[0];
        if (nuclide.N % 2 === 0 && nuclide.Z % 2 === 0 && nuclide.jpi === null) {
            return 0;
        }
        if (_.includes(spinValue, '\/')) {
            if (_.includes(spinValue, ',')) {
                let splittedNumbOne = (spinValue.split(',')[0]);
                let splittedNumbTwo = (spinValue.split(',')[1]);
                let numb = this._cleanNumber(splittedNumbOne) + this._cleanNumber(splittedNumbTwo);
                return numb/2
            } else {
                return this._cleanNumber(spinValue) / 2;
            }
        } else {
            if (_.includes(spinValue, ',')) {
                let splittedNumbOne = spinValue.split(',')[0];
                return this._cleanNumber(splittedNumbOne);
            } else if (nuclide.jpi !== null) {
                return this._cleanNumber(spinValue);
            }
        }

        return null;
    }
}

export default UnitConverter;
