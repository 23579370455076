import React, { Component } from "react";
import 'react-dropdown/style.css';
import _ from 'lodash';
import {chart} from '../NuclideChartContainer';
import { connect } from 'react-redux';
import ColorEntrySection from './ColorEntrySection';
import { updateDecayDropdown } from "../../store/actions/DiscreteDecayActions";
import { updateParityDropdown } from "../../store/actions/DiscreteParityActions";
import { updateOneOrientation } from "../../store/actions/ContinuousAttributeActions";
import defaultRanges from '../../helpers/DefaultRanges';
import fromExponential from 'from-exponential';
import UnitConverter from '../../helpers/UnitConverter';

class ContinuousColorSpec extends Component{
    constructor(props) {
        super(props);
        this.state = {
            confirmationText: '',
            errorInputs: [],
            errorMessage: 'Entry overlaps an existing range.',
            colorCodingOptions: _.cloneDeep(this.props.filters).filters[this.props.orientation],
        };
        this.updateFilterState = this.updateFilterState.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.updateDropdown = this.updateDropdown.bind(this);
        this.validateInputs = this.validateInputs.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const newProps = this.props;

        if(!_.isEqual(prevProps.filters, newProps.filters)) {
            this.setState({colorCodingOptions: _.cloneDeep(newProps.filters).filters[newProps.orientation]});
        }
    }

    updateFilterState(color, index, val) {
        let newState = _.cloneDeep(this.state.colorCodingOptions);
        newState['colors'][color][index] = val;
        this.setState({colorCodingOptions: newState}, () => this.validateInputs());
    }

    updateDropdown(e) {
        let newState = _.cloneDeep(this.state.colorCodingOptions);
        newState.attribute = e.value;
        newState.colors = defaultRanges[e.value];
        this.setState({colorCodingOptions: newState}, () => this.validateInputs());
    }

    validateInputs() {
        let newErrors = [];
        let converter = new UnitConverter();

        Object.entries(this.state.colorCodingOptions.colors).forEach(([color, range]) => {
            let otherColorRanges = Object.entries(this.state.colorCodingOptions.colors).filter(entry => entry[0] !== color);
            otherColorRanges.forEach(([secondColor, secondRange]) => {
                let key0 = `${this.props.orientation}-${color}-0`;
                let key1 = `${this.props.orientation}-${color}-1`;
                let isHalflife = this.state.colorCodingOptions.attribute === 'half life';

                let areBothValuesEntered = secondRange[0] !== '' && secondRange[1] !== '';
                let parsedMin = isHalflife ? (secondRange[0] !== '' ? converter.handleHalflifeConversion(secondRange[0]) : NaN) : parseFloat(fromExponential(secondRange[0]));
                let parsedMax = isHalflife ? (secondRange[1] !== '' ? converter.handleHalflifeConversion(secondRange[1]) : NaN) : parseFloat(fromExponential(secondRange[1]));
                let parsedFirstVal = isHalflife ? (range[0] !== '' ? converter.handleHalflifeConversion(range[0]) : NaN) : parseFloat(fromExponential(range[0]));
                let parsedSecondVal = isHalflife ? (range[1] !== '' ? converter.handleHalflifeConversion(range[1]) : NaN) : parseFloat(fromExponential(range[1]));


                if (areBothValuesEntered && range[0] !== '' && (parsedMin < parsedFirstVal && parsedFirstVal < parsedMax)) {
                    newErrors.push(key0);
                }

                if (areBothValuesEntered && range[1] !== '' && (parsedMin < parsedSecondVal && parsedSecondVal < parsedMax)) {
                    newErrors.push(key1);
                }

            })
        });

        this.setState({errorInputs: newErrors});
    }

    handleSubmit() {
        // To see the dynamic changes, we need to re-render the leaflet layer 
        // instead of re-rendering the whole component

        this.props.updateOneOrientation({orientation: this.props.orientation, data: this.state.colorCodingOptions});

        let parityPayload = {};

        parityPayload[this.props.orientation] = { isPositiveVsNegative: null, isStrongVsWeak: null }

        this.props.updateParityDropdown(parityPayload);
        this.props.updateDecayDropdown({
            orientation: this.props.orientation,
            sortedValues: []
        });

        chart.eachLayer(function(layer){
            chart.removeLayer(layer);
            chart.addLayer(layer);
        });
        this.setState({
            confirmationText: 'Color Coding Updated!'
        });
        this.timer = setTimeout( () => {
            this.setState({
                confirmationText: ''
            })
        }, 4000);
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    render() {
        let orientation = this.props.orientation  === 'topshade' ? 'Top Color' : 'Bottom Color';
        let {errorMessage} = this.state;
        let isError = this.state.errorInputs.length !== 0;

        return (
            <div>
                <p className='menu-section-subtitle' style={{textDecoration: 'underline'}}>Select a Continuous Attribute for {orientation}:</p>
                <ColorEntrySection
                    {...this.props}
                    colorCodingOptions={this.state.colorCodingOptions}
                    updateDropdown={this.updateDropdown}
                    dropDownValue={this.state.colorCodingOptions.attribute}
                    updateFilterState={this.updateFilterState}
                    errorInputs={this.state.errorInputs}
                />
                <br />

                {isError ? <div><p style={{color: 'red'}}>{errorMessage}</p></div> : null}

                <div style={{ display: 'flex', flexDirection: 'row'}}>   
                    <button disabled={isError} style={{flex: '10%'}} className="submit" onClick={this.handleSubmit}>Apply</button>
                    <p style={{flex: '100%', fontSize: '0.7em', marginLeft: '1em', color: '#ff8030'}}>
                        {this.state.confirmationText}
                    </p>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    filters: state.filters,
});

export default connect(mapStateToProps, {updateDecayDropdown, updateParityDropdown, updateOneOrientation})(ContinuousColorSpec)