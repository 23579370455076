import {fabric} from "fabric";


const createLegendText = (text, xPos, yPos, justify = 'left') => {
    return new fabric.Text(text, {
        left: xPos,
        top: yPos,
        originX: 'center',
        fontSize: 10,
        textAlign: justify
    });
};

// array of points as [x1, y1, x2, y2]
const createLegendLine = (points) => {
    return new fabric.Line(points, {
        stroke: 'black',
        strokeWidth: 2,
        objectCaching: false
    });
};

export {
    createLegendText,
    createLegendLine
}
