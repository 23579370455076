import React from 'react';
import './FissionIconEntry.css';

export default function FissionIconEntry({yieldClass, label, type}) {

    const shapeClass = type === 'square' ? 'square' : 'triangle';

    return (
        <>
            <div className="fission-yield-container">
                <i className={`yield-icon ${yieldClass} ${shapeClass}`}/>
            </div>
            <div>
                {label.split('\n').map((item, i) => {
                    return (
                        <p
                            style={ label.includes('\n') ? {margin: '3px'} : {} }
                            key={i}>
                            {item}
                        </p>
                    )
                })}
            </div>
        </>
    )
}
