import {fabric} from "fabric";


function createHalfLifeAbundance(nuclide, config) {
    let abundance = nuclide.abundance ? nuclide.abundance : '';
    let halflife = nuclide.halflife ? nuclide.halflife : '';
    let displayText;

    if (abundance) {
        displayText = abundance + '\n' + halflife;
    } else {
        displayText = halflife;
    }

    return new fabric.Text(displayText, {
        top: config.top,
        left: config.left,
        fontSize: config.fontSize,
        fontWeight: 'bold',
        originX: 'center',
        textAlign: 'center',
        lineHeight: 1,
        objectCaching: false
    });
}

export {
    createHalfLifeAbundance
}