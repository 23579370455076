import React, { Component } from "react";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import store from '../../store/ReduxStore';
import _ from 'lodash';
import {updateParityDropdown} from "../../store/actions/DiscreteParityActions";
import { connect } from 'react-redux';
import { chart } from '../../components/NuclideChartContainer';
import defaultColors from '../../helpers/DefaultColors';
import {blueSquare, greenSquare, orangeSquare, yellowSquare} from "../../colors";

let valueMapping = {
    'Positive vs Negative': {isPositiveVsNegative: true, isStrongVsWeak: false},
    'Strong vs Weak': {isPositiveVsNegative: false, isStrongVsWeak: true}
 };

const options = [
    {value: 'PositiveVsNegative', label: 'Positive vs Negative'},
    {value: 'StrongVsWeak', label: 'Strong vs Weak'},
]

const ColorLegend = ({parity}) => {
    let legend = null;
    let header = <h4 style={{textDecoration: 'underline'}}>Color Legend:</h4>;


    if (parity.isPositiveVsNegative) {
        legend = (
            <React.Fragment>
                <div>
                    {header}
                </div>
                <div style={{backgroundColor: '#fff2', borderRadius: '10px', padding: '0 10px 10px 10px'}}>
                    <h4 style={{paddingTop: "5px", marginBottom: '15px'}}>Positive vs Negative</h4>
                    <input disabled style={{backgroundColor: orangeSquare, width: '10%'}}/> Positive <br/>
                    <input disabled style={{backgroundColor: blueSquare, width: '10%'}}/> Negative <br/>
                </div>
            </React.Fragment>
        );
    }

    if (parity.isStrongVsWeak) {
        legend = (
            <React.Fragment>
                <div>
                    {header}
                </div>
                <div style={{backgroundColor: '#fff2', borderRadius: '10px', padding: '0 10px 10px 10px'}}>
                    <h4 style={{paddingTop: "5px", marginBottom: '15px'}}>Strong vs Weak</h4>
                    <input disabled style={{backgroundColor: orangeSquare, width: '10%'}}/> Strong Positive <br/>
                    <input disabled style={{backgroundColor: yellowSquare, width: '10%'}}/> Weak Positive <br/>
                    <input disabled style={{backgroundColor: blueSquare, width: '10%'}}/> Strong Negative <br/>
                    <input disabled style={{backgroundColor: greenSquare, width: '10%'}}/> Weak Negative <br/>
                </div>
            </React.Fragment>
        )
    }

    return legend;
};


class ParityColorSpec extends Component {
    constructor(props) {
        super(props);
        this.changeDropdown = this.changeDropdown.bind(this);
        this.state = {
            confirmationText: ''
        }
    }

    changeDropdown(e) {
        let dropdownValue = {};
        dropdownValue[this.props.orientation] = valueMapping[e.label];
        this.props.updateParityDropdown(dropdownValue);
        store.dispatch({ type: 'discreteDecay/updateDiscreteDecay', payload: {
            orientation: this.props.orientation,
            sortedValues: []
        }});
        let otherOrientation = "topshade";
        if (this.props.orientation === "topshade") {
            otherOrientation = "bottomshade"
        }
        let continuousPayload = {};
        continuousPayload[this.props.orientation] = defaultColors[this.props.orientation];
        continuousPayload[otherOrientation] = store.getState().filters.filters[otherOrientation];
        store.dispatch({ type: 'continuous/updateRanges', payload: continuousPayload})
        this.setState({
            confirmationText: 'Parity filters are applied! See below for parity color legend.'
        });
        this.timer = setTimeout( () => {
            this.setState({
                confirmationText: ''
            })
        }, 5000);
    }

    render() {
        let value = this.props.parity.isPositiveVsNegative ? 'Positive vs Negative'
            : (this.props.parity.isStrongVsWeak ? 'Strong vs Weak' : null );
        return (
            <div>
                <p className='menu-section-subtitle' style={{textDecoration: 'underline'}}>Options:</p>
                <Dropdown
                    placeholder="Select Parity Options"
                    options={options}
                    onChange={this.changeDropdown}
                    value={value}
                />
                <p style={{flex: '100%', fontSize: '0.7em', marginLeft: '1em', color: '#ff8030'}}>
                    {this.state.confirmationText}
                </p>
                <ColorLegend parity={this.props.parity}/>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    parity: state.discreteParity.addDiscreteParity[ownProps.orientation]
});

export default connect(mapStateToProps, {updateParityDropdown})(ParityColorSpec);
