import React, {useState, useRef, useEffect} from 'react';
import {Slide} from 'react-slideshow-image';
import "react-slideshow-image/dist/styles.css"
import Hammer from 'hammerjs';
import './Welcome.css';

export default function Welcome({onClick, isFirstScreen}) {

    const [index, setIndex] = useState(0);
    const slideRef = useRef(null);
    const divRef = useRef(null);

    const swipeLeft = () => slideRef.current.goNext();

    const swipeRight = () => slideRef.current.goBack();

    useEffect(() => {
        const hammer = new Hammer(divRef.current);
        hammer.on('swipeleft', swipeLeft);
        hammer.on('swiperight', swipeRight);

        return () => {
            hammer.off('swipeleft', swipeLeft);
            hammer.off('swiperight', swipeRight);
            hammer.stop();
            hammer.destroy();
        }
    }, [divRef]);

    const properties = {
        transitionDuration: 500,
        infinite: false,
        indicators: true,
        arrows: true,
        autoplay: false,
        onChange: (oldIndex, newIndex) => {
            setIndex(newIndex);
        }
    };

    const skipToEnd = () => {
        slideRef.current.goTo(panelClasses.length - 1);
    };

    const panelClasses = [
        'Zoom',
        'Navigation',
        'Legend',
        'Export'
    ];

    return (
        <>
            <div>
                <h2 className="welcome-title">Chart of the Nuclides</h2>
            </div>
            <div ref={divRef}>
                <Slide ref={slideRef} {...properties}>
                    {panelClasses.map(panelClass => {
                        return (
                            <div key={panelClass} className="each-slide">
                                <div className={panelClass}/>
                            </div>
                        )
                    })}
                </Slide>
            </div>
            <div className='button-container'>
                {index === panelClasses.length - 1 ? <button className='continue-button' onClick={onClick}>{isFirstScreen ? 'GET STARTED' : 'CLOSE'}</button> : <button className='skip' onClick={skipToEnd}>SKIP</button>}
            </div>
        </>
    )
}
