import _ from 'lodash';
import getValuesByPrefix from '../helpers/GetValuesByPrefix';

class Parity {
    constructor(nuclide) {
        this.nuclide = nuclide;
    }

    _getSpin() {
        return getValuesByPrefix(this.nuclide, 'jpi').filter(n => n !== null).toString();
    }

    _areBothCoordinatesEven() {
        return this.nuclide.N % 2 === 0 && this.nuclide.Z % 2 === 0 && this.nuclide.jpi === null;
    }

    isPositive() {
        return _.includes(this._getSpin(), '+') || this._areBothCoordinatesEven();
    }


    isNegative() {
        return _.includes(this._getSpin(), '-');
    }

    isWeak() {
        return _.includes(this._getSpin(), '(') && _.includes(this._getSpin(), ')');
    }

    isStrong() {
        return !this.isWeak() || this._areBothCoordinatesEven();
    }

}

export default Parity;