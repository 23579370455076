import {fabric} from "fabric";
import _ from 'lodash';

function createSpinAndParity(nuclide, config) {
    let spin = nuclide.jpi ? nuclide.jpi : '';
    let origin = config.origin ? config.origin : 'left';
    let sZeroEntryForNuclide = config.sZeroIsotope || nuclide;

    return new fabric.Text(spin, {
        fill: sZeroEntryForNuclide.blackband || _.startsWith(nuclide.name, 'Ta180') ? 'white' : 'black',
        originX: origin,
        left: config.left,
        top: config.top,
        fontSize: config.fontSize || 20,
        textAlign: 'left',
        objectCaching: false
    });
}

export {
    createSpinAndParity
}