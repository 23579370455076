import React, { Component } from "react";

export default class VersionScreenModal extends Component {
    constructor() {
        super();
        this.state = {
            showModal : false,
            name: 'Chart of the Nuclides',
            version: process.env.REACT_APP_VERSION_NUMBER || '0.0.1',
            edition:'17th Edition\nRevised 2009',
            contact:'Contact info:\n' +
            '\n' +
            'Fluor Marine Propulsion, LLC\n' +
            'Knolls Atomic Power Laboratory\n' +
            'Attention Jason Thompson\n' +
            'Bin 122\n PO Box 1072\n Schenectady, NY 12309-1072',
        };
    }

    pretifyText(text) {
        return text.split('\n').map((item, key) => {
            return <span key={key}>{item}<br/></span>
        })
    }

    render() {
        return (
            <div>
                <div className='version_info'>
                    <h3>{this.pretifyText(this.state.name)}</h3>
                    <p>{this.pretifyText(this.state.version)}</p>
                    <p>{this.pretifyText(this.state.edition)}</p>
                    <p>{this.pretifyText(this.state.contact)}</p>
                </div>
                <div className="version_info">
                    <p>
                        This electronic media has been derived from Nuclides and Isotopes: Chart of the Nuclides,
                        Seventeenth Edition by the Naval Nuclear Laboratory operated for the U.S. Department of Energy
                        under a contract with Fluor Marine Propulsion LLC. The information contained herein is being
                        made available by Fluor Marine Propulsion LLC, and Bechtel Marine Propulsion Corporation in the
                        interest of promoting the dissemination of technical knowledge. Neither Fluor Marine Propulsion
                        LLC, Bechtel Marine Propulsion Corporation, nor the individual authors make any warranty or
                        representation, expressed or implied, with respect to the accuracy, completeness, or usefulness
                        of the information contained in this publication. Fluor Marine Propulsion LLC and Bechtel Marine
                        Propulsion Corporation assume no responsibility for liability or damage, which may result from
                        the use of any of this information.
                    </p>
                </div>
                <div className="version_info">
                    <p>
                        Copyright (C) 2010, Bechtel Marine Propulsion Corporation,
                        and Copyright (C) 2019 Fluor Marine Propulsion LLC
                    </p>
                </div>
                <div className="version_info logo-container">
                    <div className="FluorLogo logo-item" role="img" alt="Fluor Logo" />
                    <div className="NNLLogo logo-item" role="img" alt="NNL Logo" />
                    <div className="BechtelLogo logo-item" role="img" alt="Bechtel Logo" />
                </div>
            </div>
        )
    }
}
