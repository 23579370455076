import React, { Component } from 'react';
import L from 'leaflet';
import {fabric} from 'fabric';
import _ from 'lodash';
import createOne from '../levels/element/one.js';
import createTwo from '../levels/element/two.js';
import createThree from '../levels/element/three.js';
import createFour from '../levels/element/four.js';
import createFive from '../levels/element/five.js';
import elementData from '../helpers/element_locations.json';
import config from '../config';
import { addLanthanideAndActinideLabel } from '../helpers/UpdateElementsWithLabels';
import store from '../store/ReduxStore';
import { Redirect } from "react-router-dom";
import getXYZFromElement from '../helpers/getXYZ';
import {chart as nuclideChart} from './NuclideChartContainer'

let chart; 
class PeriodicTablecontainer extends Component {
    constructor(props) {
        super(props);
        this.state = store.getState().location;
        this.nuclides = Object.values(JSON.parse(localStorage.getItem('nuclides')));
    }

    getXYZFromElement(clickedElement) {
       return getXYZFromElement(clickedElement, this.nuclides);
    }
    
    componentDidMount() {
        store.dispatch(
            {
                type: 'modify/chartType',
                payload: 'periodic'
            }
        )
        let elements = require('../elements.json').elements;
        elements.filter(n => n.phase !== null);
        addLanthanideAndActinideLabel(elements);

        chart = L.map('chart', {
            crs: L.CRS.Simple,
            zoomControl: false,
            minZoom: config.tableMinZoom,
            maxZoom: 5,
            zoomSnap: 0.75,
            doubleClickZoom: false,
            maxBounds: [[0, -12], [-120, 180]]
        });
        let latlng = chart.unproject([(this.state.periodicXYZ.x+0.5) * 256, (this.state.periodicXYZ.y+1) * 256], this.state.periodicXYZ.z);
        chart.setView([latlng.lat, latlng.lng], this.state.periodicXYZ.z);

        let elementLayer = new L.GridLayer({
            noWrap: false,
            bounds: [[-256, 0], [0, 256]],
        });
        
        function getElementFromLocationItem(locationItem) {
            let element = elements
                .filter(n => n.symbol === locationItem.symbol)[0];
            return element
        }

        function getLocationItemFromElement(element) {
            let locationItem = elementData.locations
                .filter(n => n.symbol === element.symbol)[0];
            return locationItem 
        }

        function getElementsFromCoords(coords) {
            let x1 = (2**(5-coords.z)) * coords.x;
            let x2 = (2**(5-coords.z)) * coords.x + (2**(5-coords.z)-1);
            let y1 = (2**(5-coords.z)) * coords.y;
            let y2 = ((2**(5-coords.z)) * coords.y + (2**(5-coords.z)-1));
            
            let elementArray = elementData.locations
                .filter(n => n.x >= Math.min(x1, x2) && n.x <= Math.max(x1, x2))
                .filter(n => n.y >= Math.min(y1, y2) && n.y <= Math.max(y1, y2))

            let elements = elementArray.map(getElementFromLocationItem)
            return elements
        }

        function tileToBBOX(tile) {
            let x = tile[0];
            let y = tile[1];
            let zoom = tile[2];
            let pixelSize = 2 ** (5 - zoom); // Distance you move when pan at each level
            let w = x * pixelSize; // Left bound
            let s = (y + 1) * pixelSize; // Bottom bound
            let e =  (x + 1) * pixelSize; // Right bound
            let n = y * pixelSize; // Top bound
            return [w, s, e, n];

        }

        function getParent(tile) {
            return [tile[0] >> 1, tile[1] >> 1, tile[2] - 1]; 
        }

        function getOffsetFromElement(element, zoom) {
            let locationItem = getLocationItemFromElement(element);
            let x = locationItem.x;
            let y = locationItem.y;
            let parent = [x, y, 5];
            while (parent[2] !== zoom) {
                parent = getParent(parent);
            }
            let tileBounds = tileToBBOX([x, y, 5]);
            let parentBounds = tileToBBOX(parent);
            let xOffset = (Math.abs((parentBounds[0] - tileBounds[0])) / Math.abs((parentBounds[2] - parentBounds[0]))) * 256
            let yOffset = (Math.abs((parentBounds[3] - tileBounds[3])) / Math.abs((parentBounds[3] - parentBounds[1]))) * 256
            return [xOffset, yOffset];
        }

        let customCreateTile = function (coords) {
            // We need to add these 2 conditional to get rid of the tile error on Mobile Browsers. 
            // It happens when you try to zoom in more at level 5 or zoom out less at level 1
            if(coords.z > 5) {
                coords.z = 5;
            }
            if(coords.z < 1) {
                coords.z = 1;
            }

            let elementsForTile = _.compact(getElementsFromCoords(coords));

            var canvas = new fabric.StaticCanvas('c', {
                renderOnAddRemove: true,
                objectCaching: false
            });
            canvas.setHeight(256);
            canvas.setWidth(256);
            let layerMapping = {
                1: createOne,
                2: createTwo,
                3: createThree,
                4: createFour,
                5: createFive,
            }
            elementsForTile.forEach(function(e) {
                let group = layerMapping[coords.z].call(this, e)
                let offset = getOffsetFromElement(e, coords.z);
                group.left = offset[0];
                group.top = offset[1];
                canvas.add(group);
            })
            return canvas.lowerCanvasEl;

        };
        elementLayer.createTile = customCreateTile;
        elementLayer.addTo(chart);
        let that = this;

        // Get Element's location at zoom level 5 on Click
        function onMapClick(e) {
            let elementXY = chart.project(e.latlng, 5)
            let x = Math.floor(elementXY.x / 256);
            let y = Math.floor(elementXY.y / 256);
            let locationItem = elementData.locations
                .filter(n => n.x === x)
                .filter(n => n.y === y)[0];
            if(locationItem) {
                store.dispatch(
                    {
                        type: 'modify/nuclideXYZ',
                        payload: that.getXYZFromElement(locationItem.symbol)
                    }
                )
                that.setState({'redirect': true});
            }
        }
        chart.on('dblclick', onMapClick);
    }

    componentWillUnmount() {
        chart.eachLayer(function(layer){
            chart.removeLayer(layer);
        });
        chart.remove();
    }

    componentWillMount() {
        if (nuclideChart) {
            let y = nuclideChart.getCenter().lat;
            let x = nuclideChart.getCenter().lng;
            let z = nuclideChart.getZoom();
            store.dispatch(
                {
                    type: 'modify/nuclideXYZ',
                    payload: {x: x, y: y,z: z}
                }
            )
        }
    }

    render() {
        return this.state.redirect ? <Redirect to="/nuclide"/> : <div id='chart'/>;
    }
}

export default PeriodicTablecontainer;
export {chart};