import React from 'react';
import './FissionYieldLegend.css';
import FissionIconEntry from "../FissionIconEntry";

export default function FissionYieldLegend() {

    const yieldData = [
        {
            yieldClass: 'orange-tri',
            label: 'independent yield > 3.0%',
            type: 'triangle'
        },
        {
            yieldClass: 'yellow-tri',
            label: 'independent yield > 1.0% to 3.0%',
            type: 'triangle'
        },
        {
            yieldClass: 'green-tri',
            label: 'independent yield > 0.1% to 1.0%',
            type: 'triangle'
        },
        {
            yieldClass: 'blue-tri',
            label: 'independent yield > 0.01% to 0.1%',
            type: 'triangle'
        },
        {
            yieldClass: 'black-tri',
            label: 'independent yield > 2.5E-6% to 0.01%',
            type: 'triangle'
        },
        {
            yieldClass: 'white-tri',
            label: 'independent yield < 2.5E-6%\ncumulative yield > 2.5E-6%',
            type: 'triangle'
        },
        {
            yieldClass: 'black-square',
            label: 'shielded nuclide with independent yield designated by color, as above.',
            type: 'square'
        }
    ];

    return (
        <div className="fission-container">
            <h2>U235 Fission Yields</h2>
            <div className="fission-grid-container">
                {yieldData.map(yieldentry => {
                    return (
                        <FissionIconEntry
                            key={yieldentry.yieldClass}
                            yieldClass={yieldentry.yieldClass}
                            label={yieldentry.label}
                            type={yieldentry.type}
                        />
                    )
                })}
            </div>
        </div>
    )
}
