function getDecayObjectByNuclide(nuclide) {
    return {
        'decay1': nuclide.decay1,
        'decay2': nuclide.decay2,
        'decay3': nuclide.decay3,
        'decay4': nuclide.decay4,
        'decay5': nuclide.decay5
    }
}

export default getDecayObjectByNuclide;
