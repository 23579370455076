import React, { Component } from "react";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import store from '../../store/ReduxStore';
import {chart} from '../NuclideChartContainer';
import _ from 'lodash';
import {updateDecayDropdown} from "../../store/actions/DiscreteDecayActions";
import { connect } from 'react-redux';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import chroma from 'chroma-js';
import defaultColors from '../../helpers/DefaultColors';
import {blueSquare, greenSquare, orangeSquare, yellowSquare, graySquare} from "../../colors";

const symbols = [
    { value: '2p', label: '2p', selected: false, index: 1 },
    { value: 'p', label: 'p', selected: false, index: 2 },
    { value: '\u03b2+', label: '\u03b2+', selected: false, index: 3 },
    { value: 'EC', label: 'EC', selected: false, index: 4 },
    { value: '\u03b1', label: '\u03b1', selected: false, index: 5 },
    { value: 'IT', label: 'IT', selected: false, index: 6 },
    { value: 'SF', label: 'SF', selected: false, index: 7 },
    { value: '\u03b2-', label: '\u03b2-', selected: false, index: 8 },
    { value: '\u03b2-\u03b2-', label: '\u03b2-\u03b2-', selected: false, index: 9 },
    { value: 'n', label: 'n', selected: false, index: 10 },
    { value: '2n', label: '2n', selected: false, index: 11 },
    { value: '3n', label: '3n', selected: false, index: 12 },
    { value: '4n', label: '4n', selected: false, index: 13 }
];

let symbolColorMapping = {
    1: [orangeSquare],
    2: [orangeSquare, blueSquare],
    3: [orangeSquare, greenSquare , blueSquare],
    4: [orangeSquare, yellowSquare , greenSquare , blueSquare]
};

const colorStyles = {
    multiValue: (styles, { data }) => {
        const color = chroma(data.color);
        return {
        ...styles,
        backgroundColor: color.alpha(0.8).css(),
        };
    },
    multiValueLabel: (styles) => ({
        ...styles,
        color: 'white',
    }),
    multiValueRemove: (styles, { data }) => ({
        ...styles,
        color: 'white',
        ':hover': {
            backgroundColor: data.color,
            color: 'white',
        },
    }),
    menu: (styles) => ({
        ...styles,
        color: '#515151',
    })
  };

class DecayModeColor extends Component{
    constructor(props) {
        super(props);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.state= {
            confirmationText: ''
        }
    }

    handleSelectChange(values) {
        if(!values){
            this.props.updateDecayDropdown({
                orientation: this.props.orientation,
                sortedValues: []
            });
        }
        else if(values.length <= 4) {
            let colors = symbolColorMapping[values.length];
            let sortedValues = _.orderBy(values, ['index'], ['asc']);
            this.props.updateDecayDropdown({ orientation: this.props.orientation, colors, sortedValues });
            let parityPayload = {};
            let continuousPayload = {};
            let otherOrientation = "topshade";
            if (this.props.orientation === "topshade") {
                otherOrientation = "bottomshade"
            }
            parityPayload[this.props.orientation] = { isPositiveVsNegative: null, isStrongVsWeak: null }
            continuousPayload[this.props.orientation] = defaultColors[this.props.orientation];
            continuousPayload[otherOrientation] = store.getState().filters.filters[otherOrientation];
            store.dispatch({ type: 'discreteParity/updateDiscreteParity', payload: parityPayload });
            store.dispatch({ type: 'continuous/updateRanges', payload: continuousPayload})
            this.setState({
                confirmationText: 'Decay mode filters are updated!'
            });
            this.timer = setTimeout( () => {
                this.setState({
                    confirmationText: ''
                })
            }, 5000);
        }
    }

    render() {
        return (
            <div>
                <p className='menu-section-subtitle' style={{textDecoration: 'underline'}}>Select Decay Modes:</p>
                <Select
                    onChange={this.handleSelectChange}
                    options={symbols}
                    isMulti
                    components={makeAnimated()}
                    value={this.props.discreteDecay}
                    styles={colorStyles}
                />
                <p style={{flex: '100%', fontSize: '0.7em', marginLeft: '1em', color: '#ff8030'}}>
                    {this.state.confirmationText}
                </p>
                {this.props.discreteDecay.length >= 2 ? <div style={{backgroundColor: 'white', color: '#515151', borderRadius: '4px', padding: '8px', fontSize: '85%', display: 'inline-block'}}>
                        <input disabled style={{backgroundColor: graySquare, borderColor: graySquare, height: '18px', width: '35px', marginRight: '6px', marginLeft: '3px'}}/>Multiple
                    </div> : <></>
                } 
            </div>  
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    discreteDecay: state.discreteDecay[ownProps.orientation]
});

export default connect(mapStateToProps, {updateDecayDropdown})(DecayModeColor);
