import _ from 'lodash';

//This class is responsible for adding data
//that doesn't exist in nuclide.json for drawing
//element squares, N/Z numbers and cumulative
//fission yields on the chart.
class UpdatedNuclides {
    constructor(data) {
        this.nuclides = Object.values(data);
        this.filteredNuclides = this.nuclides
            .filter(n => n.location.toLowerCase() !== 'not shown')
    }

    getElementNValue(element) {
        let nuclidesForElement = this.filteredNuclides
            .filter(n => n.Symbol.startsWith(element.Symbol) && n.Z === element.Z && !n.Symbol.startsWith('Ts'));

        if (nuclidesForElement.length === 0) {
            nuclidesForElement = this.nuclides.filter(n => n.Symbol.startsWith('Og') && n.N);
        }

        return _.minBy(nuclidesForElement, 'N').N;
    }

    injectElements() {
        this.filteredNuclides
            .filter(n => n.N === null)
            .forEach(n => n.N = this.getElementNValue(n)-1)
    }

    injectOpacity() {
        this.filteredNuclides.map(n => n.opacity = 1.0);
    }

    injectRowNumber() {
        let max = _.maxBy(this.filteredNuclides, 'Z');
        let rowArray = Array.from(new Array(max.Z+1),(val,index) => index);
        _.forEach(rowArray, function(i) {
            let N = _.minBy(this.filteredNuclides.filter(n => n.Z === i), 'N').N-1;
            this.nuclides.push(
                {"N": N, "Z": i, "location": "row", "Symbol": " ", "name": " ", "isRow": true}
            )
        }.bind(this))
    }

    injectColumnNumber() {
        let max = _.maxBy(this.filteredNuclides, 'N');
        let columnArray = Array.from(new Array(max.N+1),(val,index) => index);
        _.forEach(columnArray, function(i) {
            let Z = _.minBy(this.filteredNuclides.filter(n => n.N === i), 'Z').Z-1;
            let rightAdjacentZ = _.minBy(this.filteredNuclides.filter(n => n.N+1 === i), 'Z').Z-1;
            // If the cell on the right has a smaller Z value that means it is a corner
            // If cell is a corner we need to add an extra cell to draw the cumulative fission lines.
            if (rightAdjacentZ < Z) {
                this.nuclides.push(
                    {"N": i, "Z": rightAdjacentZ, "location": "row", "Symbol": " ", 
                    "name": " ", "isColumn": true, "isCorner": true}
                )    
            } else if (!rightAdjacentZ) {
                this.nuclides.push(
                    {"N": 73, "Z": 41, "location": "row", "Symbol": " ", 
                        "name": " ", "isColumn": true, "isCorner": true}, // Injecting Mo114's Cumulative Fission Line
                    {"N": 94, "Z": 54, "location": "row", "Symbol": " ", 
                        "name": " ", "isColumn": true, "isCorner": true}, // Injecting Cs148's Cumulative Fission Line
                    {"N": 97, "Z": 59, "location": "row", "Symbol": " ", 
                        "name": " ", "isColumn": true, "isCorner": true}, // Injecting Nd156's Cumulative Fission Line
                    {"N": 97, "Z": 60, "location": "row", "Symbol": " ", 
                        "name": " ", "isColumn": true, "isCorner": true}, // Injecting Pm157's Cumulative Fission Line
                    {"N": 103, "Z": 63, "location": "row", "Symbol": " ", 
                        "name": " ", "isColumn": true, "isCorner": true} // Injecting Gd166's Cumulative Fission Line
                )  
            }
            this.nuclides.push(
                {"N": i, "Z": Z, "location": "row", "Symbol": " ", "name": " ", "isColumn": true}
            )
        }.bind(this))
    }
}

export default UpdatedNuclides;