import {fabric} from "fabric";


function createMass(nuclide, config) {
    let text = nuclide.mass ? nuclide.mass : '';
    return new fabric.Text(text, {
        top: config.top || 225,
        originX: config.origin,
        left: config.left,
        fontSize: config.fontSize || 18,
        objectCaching: false
    });
}

export {
    createMass
}