function stripText(text, numOfCharacters) {
    let values = text.split(',');

    values = handleEllipses(values);

    const i = findBreakPoint(values, numOfCharacters);
    if (i === null) {
        return values.join(',');
    }

    let firstValue = false;
    if (i === 0) {
        firstValue = true;  // indicates breakpoint is within first value and needs to be split
        const splitFirstValue = values[0].split(' '); // split first value into substrings
        const iForFirstValue = findBreakPoint(splitFirstValue, numOfCharacters, firstValue);
        insertNewline(splitFirstValue, iForFirstValue, numOfCharacters);
        updateValues(values, iForFirstValue, splitFirstValue);
        values = handleRemainder(values, i, numOfCharacters, firstValue);
    } else {
        insertNewline(values, i, numOfCharacters);
        values = handleRemainder(values, i, numOfCharacters, firstValue);
    }

    return dynamicJoin(values, firstValue);
}

function findBreakPoint(values, numOfCharacters, firstValue) {
    let totalLength = firstValue ? values.length - 1 : 0;  // include spaces removed when first value is split (fixes Xe134, Y89)
    for (let i = 0; i < values.length; i++) {
        totalLength += values[i].length;
        if (totalLength > numOfCharacters) {
            return i;
        }
    }
    return null;
}

function insertNewline(values, i, numOfCharacters) {
    const options = ["...", " ...", "..", "....", "\u2026"]; // fixes In128, In129, Sb124, Fm255, Na24, etc.
    if (options.find(o => o === values[i])) {
        values.splice(i - 1, 0, '\n  ');
    } else if (values[i - 1] !== '\n  ') { // fixes In115
        values.splice(i, 0, '\n  ');
    }
}

function updateValues(values, iForFirstValue, splitFirstValue) {
    values[0] = splitFirstValue.slice(0, iForFirstValue).join(' '); // set first item to first half of split value
    const remainder = splitFirstValue.slice(iForFirstValue,).join(' '); // grab second half of split value
    values.splice(1, 0, remainder); // and insert it as second item in values
}

function handleRemainder(values, i, numOfCharacters, firstValue) {
    i = firstValue ? 1 : i;
    const remainingValues = values.slice(i,);
    const iForRemainder = findBreakPoint(remainingValues, numOfCharacters, false);
    if (iForRemainder === null) {
        return values;
    }
    insertNewline(remainingValues, iForRemainder, numOfCharacters);
    return values.slice(0, i).concat(remainingValues);
}

function joinFirstValue(values) {
    return values.length > 2 ? values[0] + values[1] + ',' : values[0] + values[1]; // fixes Xe134
}

function dynamicJoin(values, firstValue) {
    let str = firstValue ? joinFirstValue(values) : "";
    const startingIndex = firstValue ? 2 : 0;
    for (let i = startingIndex; i < values.length; i++) {
        if (/\n/.exec(values[i]) || i === values.length - 1) {
            str += values[i];
        } else {
            str += values[i] + ',';
        }
    }
    return str;
}

function handleEllipses(values) {

    return values.map(value => {

        if (value == '\u2026' || value.indexOf('\u2026') !== -1) {
            return value.replace(' \u2026', '\u2026').replace('\u2026', '\u00B7\u00B7\u00B7');
        }

        if (value.indexOf('...' !== -1)) {
            return value.replace(' ...', '...').replace('...', '\u00B7\u00B7\u00B7');
        }


        return value;
    });
}

export default stripText;
