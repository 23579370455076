import getValuesByPrefix from "./GetValuesByPrefix";
import _ from "lodash";

class Decay {
    constructor(nuclide) {
        this.nuclide = nuclide;
    }

    _getDecays() {
        return getValuesByPrefix(this.nuclide, 'decay').splice(0, 5).filter(n => n !== null);
    }

    doesNuclideHaveDecayType(value) {

        let filteredDecays = _.filter(this._getDecays(), decay => {
            let strippedDecay = decay.replace(/\s/g, '');
            let valueNotEmpty = value !== '';

            if (value === '\u03b1') {
                if (decay.indexOf('2\u03b1') !== -1) {
                    return true;
                }
            }

            let characterPrecedingValue = strippedDecay[strippedDecay.indexOf(value)-1];
            let characterPrecedingValueIsNotADigitOrParentheses = !isNaN(characterPrecedingValue) || !characterPrecedingValue === '(';

            if (characterPrecedingValueIsNotADigitOrParentheses) {
                return false;
            }

            if (valueNotEmpty && value === '\u03b2-') {
                return this._doesDecayOnlyMatchBetaMinus(strippedDecay, value);
            }

            if (valueNotEmpty && value === 'n') {
                return this._doesDecayOnlyMatchN(strippedDecay, value);
            }

            if (valueNotEmpty && value === 'EC') {
                return this._doesDecayMatchElectronCapture(strippedDecay);
            }

            if (valueNotEmpty) {
                return strippedDecay.includes(value);
            }
        });

        if (filteredDecays.length > 0) {
            return true;
        }
    }

    // handles matching only beta minus and not double beta minus
    _doesDecayOnlyMatchBetaMinus(decay, value) {
        return decay.includes(value) && !decay.includes(`${value}${value}`);
    }

    // handles the phrase 'no y' selections popping positive when n selected
    _doesDecayOnlyMatchN(decay, value) {
        return decay.includes(value) && !decay.includes('no');
    }

    // handles matching all epsilons when EC selected
    _doesDecayMatchElectronCapture(decay) {
        return decay.includes('\u03B5');
    }
}

export default Decay;