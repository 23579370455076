import magicNumbers from '../magicNumbers';

function isColumnInMagicNumbers(nuclide) {
    return magicNumbers.indexOf(nuclide.N) !== -1
}

function isRowInMagicNumbers(nuclide) {
    return magicNumbers.indexOf(nuclide.Z) !== -1;
}

function isNuclideInBothMagicRowAndColumn(nuclide) {
    return isColumnInMagicNumbers(nuclide) && isRowInMagicNumbers(nuclide);
}

export {
    isColumnInMagicNumbers,
    isRowInMagicNumbers,
    isNuclideInBothMagicRowAndColumn
}