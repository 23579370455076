function addLanthanideAndActinideLabel(elements) {
    elements.push(
        {
            "phase": null,
            "Z": 58,
            "symbol": "Lanthanoids*",
            "isLabel": true,
            "element_name": "Lanthanoids*",
            "atomic_weight": null,
            "oxidation_state": null,
            "most_common_oxidation_state": null,
            "electronegativity": null,
            "melting_point": null,
            "boiling_point": null,
            "specific_gravity": null,
            "thermal_conductivity": null,
            "atomic_radius": null,
            "K_alpha": null,
            "K_beta": null,
            "L_alpha": null,
            "L_beta": null,
            "crustal_abundance": null
        });

    elements.push(
        {
            "phase": null,
            "Z": 90,
            "symbol": "Actinoids*",
            "isLabel": true,
            "element_name": "Actinoids*",
            "atomic_weight": null,
            "oxidation_state": null,
            "most_common_oxidation_state": null,
            "electronegativity": null,
            "melting_point": null,
            "boiling_point": null,
            "specific_gravity": null,
            "thermal_conductivity": null,
            "atomic_radius": null,
            "K_alpha": null,
            "K_beta": null,
            "L_alpha": null,
            "L_beta": null,
            "crustal_abundance": null
        }) ;
}

export {
    addLanthanideAndActinideLabel
}