import React, { Component } from "react";
import {connect} from 'react-redux';
import CheckboxList from './CheckboxList';
import {updateDataDisplayCheckbox, updateDataDisplayRadioButton} from "../../store/actions/DataDisplayActions";
import MassDisintegrationEnergyRadioButtons from "./MassDisintegrationEnergyRadioButtons";
import { convertStateToCheckboxArray, createRadioButtonArrayWithCheckedParam } from "../../helpers/mapStateToInputArrays"

class CheckboxContainer extends Component {
    constructor(props) {
        super(props);

        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.handleRadioButtonChange = this.handleRadioButtonChange.bind(this);
    }

    handleCheckboxChange(e) {
        this.props.updateDataDisplayCheckbox({ name: e.target.name});
    }

    handleRadioButtonChange(e) {
        this.props.updateDataDisplayRadioButton( {value: e.target.value});
    }

    render() {
        return (
            <div>
                <CheckboxList checkboxes={this.props.checkboxes} onChange={this.handleCheckboxChange}/>
                <hr style={{marginLeft: '0', width:'92%'}}/>
                <MassDisintegrationEnergyRadioButtons radiobuttons={this.props.radioButtons} onChange={this.handleRadioButtonChange}/>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    checkboxes:  convertStateToCheckboxArray(state.dataDisplay),
    radioButtons: createRadioButtonArrayWithCheckedParam(state.dataDisplay),
});


export default connect(mapStateToProps, {updateDataDisplayCheckbox, updateDataDisplayRadioButton})(CheckboxContainer)