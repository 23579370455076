
import {createReducer} from "@reduxjs/toolkit";
import {updateParityDropdown} from "../actions/DiscreteParityActions";
import _ from'lodash';

export const discreteParityReducer = createReducer(
    {
        addDiscreteParity: {
            topshade: {
                isPositiveVsNegative: null,
                isStrongVsWeak: null
            },
            bottomshade: {
                isPositiveVsNegative: null,
                isStrongVsWeak: null
            }
        },
    },
    {
        [updateParityDropdown]: (state, action) => {
            let newState = action.payload;
            _.forEach(newState, function(value, key) {
                state.addDiscreteParity[key] = value;  
            });            
        } 
    }
)