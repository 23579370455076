import React from 'react';

const Checkbox = ({checked, name, onChange}) => (
        <input type="checkbox"
               name={name}
               checked={checked}
               onChange={onChange}
               value={checked}/>
);

Checkbox.defaultProps = {
    checked: false,
};

export default Checkbox;