import {createLegendLine} from "../levels/nuclide/creators/LegendLabel";
import { isHorizontal, isHorizontalBottom, isVertical} from "./arrowDirection";

const addSymbolLine = () => {
    const startingPoint = {
        x: 160,
        y: 40
    };
    const endPoint = {
        x: 240,
        y: 35
    };

    return createLegendLine([startingPoint.x, startingPoint.y, endPoint.x, endPoint.y]);
};

const addAbundanceLine = (nuclideState) => {
    const startingPoint = {
        x: 440,
        y: 90,
    };

    const endPoints = {
      'whole box': {
          x: 315 + nuclideState.abundance.length * 4,
          y: 60
      },
      'right': {
          x: 395,
          y: 65,
      },
      'left': {
          x: 275,
          y: 65
      }
    };

    const finalEndPoint = endPoints[nuclideState.location];

    return createLegendLine([startingPoint.x, startingPoint.y, finalEndPoint.x, finalEndPoint.y]);
};

const addHalflifeLine = (nuclideState) => {
    const startingPoint = {
        x: 155,
        y: 70
    };

    const middle = {x: 250, y: 65};
    const upperLeft = {x: 200, y: 60};


    // if right or lower left we don't want to draw a line, so we set the end point to the starting point for simplicity
    const endPoints = {
        'whole box': middle,
        'right': startingPoint,
        'left': upperLeft,
        'upper left': upperLeft,
        'lower left': startingPoint
    };

    const finalEndPoint = endPoints[nuclideState.location];

    // half life will always be below abundance, so just drop the line slightly in those cases
    if (nuclideState.abundance) {
        finalEndPoint.y = finalEndPoint.y + 15;
    }

    return createLegendLine([startingPoint.x, startingPoint.y, finalEndPoint.x, finalEndPoint.y]);
};

const addDecayLines = (nuclideState) => {
    const startingPoint = {
        x: 165,
        y: 140
    };

    const location = nuclideState.location;
    const isLeftOrWholeBox = location === 'left' || location === 'whole box' || location === 'upper left';
    const finalEndPoint = isLeftOrWholeBox ? {x: 170, y: 90} : startingPoint;

    if (nuclideState.abundance && nuclideState.mass) {
        finalEndPoint.y = finalEndPoint.y + 15;
    }

    return createLegendLine([startingPoint.x, startingPoint.y, finalEndPoint.x, finalEndPoint.y]);
};

const addCrossSectionResonanceLine = (nuclideState, allStates) => {
    const startingPoint = {
        x: 435,
        y: 155
    };
    let finalEndPoint;

    const hasEAndMass = (nuclideState.E || nuclideState.Eminus) && nuclideState.mass;
    const s0Entry = allStates.filter(state => state.s === 0)[0];

    const left = hasEAndMass ? {x: 240 + (nuclideState.xs1.length * 4), y: 175} : {x: 240 + (nuclideState.xs1.length * 3), y: 200};
    const right = hasEAndMass ? {x: 405, y: 180} : {x: 365 + nuclideState.xs1.length, y: 215};

    // no s=2 squares have cross section/resonance integrals
    const endPoints = {
        'whole box': left,
        'right': right,
        'left': left,
    };

    if (nuclideState.location === 'whole box') {
        finalEndPoint = endPoints['whole box'];
    } else if (allStates.length > 1 && !s0Entry.xs1) {
        finalEndPoint = endPoints['left'];
    } else if (nuclideState.xs1 && nuclideState.location !== 'left') {
        finalEndPoint = endPoints['right'];
    } else {
        finalEndPoint = startingPoint;
    }

    return createLegendLine([startingPoint.x, startingPoint.y, finalEndPoint.x, finalEndPoint.y]);
};

const addSpinParityLine = (nuclideState, allStates) => {
    const startingPoint = {
        x: 435,
        y: 2
    };
    let finalEndPoint;

    const endPoints = {
        'left': {
            x: 215,
            y: 12,
        },
        'right': {
            x: 420,
            y: 25
        }
    };

    const s0Entry = allStates.filter(state => state.s === 0)[0];

    if (allStates.length > 1 && !s0Entry.jpi) {
        finalEndPoint = endPoints['left'];
    } else {
        finalEndPoint = endPoints['right'];
    }

    return createLegendLine([startingPoint.x, startingPoint.y, finalEndPoint.x, finalEndPoint.y]);
};

const addClusterLine = () => {
    const startingPoint = {
        x: 440,
        y: 210,
    };

    const endPoint = {
        x: 425,
        y: 235
    };

    return createLegendLine([startingPoint.x, startingPoint.y, endPoint.x, endPoint.y]);
};

const addMassLine = (nuclideState) => {
    const startingPoint = {
        x: 155,
        y: 230
    };

    const endPoints = {
        'whole box': {
            x: 240,
            y: 240
        },
        'left': {
            x: 190,
            y: 245
        },
        'right': {
            x: 300,
            y: 235
        }
    };

    const finalEndPoint = endPoints[nuclideState.location];

    return createLegendLine([startingPoint.x, startingPoint.y, finalEndPoint.x, finalEndPoint.y]);
};

const addDisintegrationEnergyLine = (nuclideState, allStates) => {
    const startingPoint = {
        x: 435,
        y: 200,
    };

    const endPoints = {
        'whole box': {
            x: 340,
            y: 235
        },
        'right': {
            x: 390,
            y: 230,
        },
        'left': {
            x: 280,
            y: 245
        }
    };

    const s0Entry = allStates.filter(state => state.s === 0)[0];

    const finalEndPoint = endPoints[nuclideState.location];

    if (nuclideState.mass) {
        // move it slightly up
        finalEndPoint.y = finalEndPoint.y - 15;
    }

    // bump it up for Whole box and left square
    if (nuclideState.location !== 'right' && nuclideState.mass) {
        finalEndPoint.y = finalEndPoint.y - 10;
    }

    if (nuclideState.Eminus) {
        // move slightly right
        finalEndPoint.x = finalEndPoint.x + 30;
    }

    if (nuclideState.location === 'left' && (s0Entry.E || s0Entry.Eminus)) {
        finalEndPoint.x = startingPoint.x;
        finalEndPoint.y = startingPoint.y;
    }

    return createLegendLine([startingPoint.x, startingPoint.y, finalEndPoint.x, finalEndPoint.y]);
};

const _addTriangleYieldLine = (nuclideState, allStates) => {
    const startingPoint = {
        x: 445,
        y: 240
    };

    const lowerRight = {x: 425, y: 245};
    const upperLeft = {x: 305, y: 150};

    let finalEndPoint;

    const s0Entry = allStates.filter(state => state.s === 0)[0];

    // Cu70 is the only nuclide that has either an s1 or s2 entry with triangle yield but s0 entry does NOT have it
    // so for that case, we draw the line to the upper left square corner
    // for all other cases, we draw the line to the lower right corner
    const endPoints = {
        'whole box': lowerRight,
        'right': lowerRight,
        'upper left': upperLeft,
        'left': lowerRight,
        'lower left': lowerRight
    };

    if (!s0Entry.fpsymbol) {
        finalEndPoint = endPoints['upper left'];
    } else if (nuclideState.s === 2 && s0Entry.fpsymbol) {
        finalEndPoint = lowerRight;
    } else {
        finalEndPoint = endPoints[nuclideState.location];
    }

    return createLegendLine([startingPoint.x, startingPoint.y, finalEndPoint.x, finalEndPoint.y]);
};

const _addSquareYieldLine = (nuclideState, allStates) => {
    const startingPoint = {
        x: 160,
        y: 185
    };
    let finalEndPoint;

    const endPoints = {
        'left': {
            x: 280,
            y: 250
        },
        'right': {
            x: 400,
            y: 235
        }
    };

    if (allStates.length > 1 && !!allStates.filter(state => state.s === 1)[0].fpsymbol) {
        finalEndPoint = endPoints['left'];
    } else {
        finalEndPoint = endPoints['right'];
    }

    if (nuclideState.name === 'La138' || nuclideState.name === 'Sb124') {
        startingPoint.x = 450;
        startingPoint.y = 240;
        finalEndPoint.x = 430;
        finalEndPoint.y = 245;
    }

    return createLegendLine([startingPoint.x, startingPoint.y, finalEndPoint.x, finalEndPoint.y]);
};

const addYieldLine = (nuclideState, allStates) => {
    if (nuclideState.fpsymbol.toUpperCase() === 'X') {
        return _addSquareYieldLine(nuclideState, allStates);
    } else if (nuclideState.fpsymbol.toUpperCase() === '/') {
        return _addTriangleYieldLine(nuclideState, allStates);
    }
};

const addArrowLine = (nuclideState) => {
    const startingPoint = {
        x: 160,
        y: 180
    };

    const endPoints = {
        'vertical': {
            x: 170,
            y: 160
        },
        'horizontal-bottom': {
            x: 275,
            y: 200
        },
        'horizontal-middle': {
            x: 275,
            y: 155
        }
    };

    let finalEndPoint;

    // default to starting point to not show the line if doublearrow reads 'none' or 'remove'

    if (isVertical(nuclideState)) {
        finalEndPoint = endPoints['vertical'];
    } else if (isHorizontalBottom(nuclideState)) {
        startingPoint.y = startingPoint.y - 10;
        finalEndPoint = endPoints['horizontal-bottom'];
    } else if (isHorizontal(nuclideState)) {
        finalEndPoint = endPoints['horizontal-middle'];
    } else {
        finalEndPoint = startingPoint;
    }

    return createLegendLine([startingPoint.x, startingPoint.y, finalEndPoint.x, finalEndPoint.y]);
};

export {
    addSymbolLine,
    addAbundanceLine,
    addHalflifeLine,
    addDecayLines,
    addCrossSectionResonanceLine,
    addSpinParityLine,
    addClusterLine,
    addMassLine,
    addDisintegrationEnergyLine,
    addArrowLine,
    addYieldLine
}
