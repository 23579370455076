import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import './Info.css';
import DataLegend from "../DataLegend";
import Welcome from "../../Welcome/Welcome";
import FissionYieldLegend from "../FissionYieldLegend";

export default function Info({onClick}) {
    return (
        <>
            <Tabs>
                <TabList style={{color: '#515151'}}>
                    <Tab>Legend</Tab>
                    <Tab>U235 Yields</Tab>
                    <Tab>Info Panels</Tab>
                </TabList>


                <TabPanel>
                    <DataLegend />
                </TabPanel>
                <TabPanel>
                    <FissionYieldLegend/>
                </TabPanel>
                <TabPanel>
                    <Welcome onClick={onClick}/>
                </TabPanel>
            </Tabs>
        </>
    )
}
