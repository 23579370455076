import React, {useState} from 'react';
import {squareInFilterRanges, getColorFilters} from "../../levels/nuclide/levelhelpers/ColorFilter";
import axios from "axios";
import fileDownload from "js-file-download";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from '@fortawesome/free-solid-svg-icons'
import SweetAlert from "react-bootstrap-sweetalert";
import aws from '../../aws-config';

export default function DataExport() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const handleError = () => {
        setError(true);
    };

    const onConfirm = () => {
        setError(false);
    };

    const timestamp = () => {
      let date = new Date();
      return date.getTime();
    }

    const exportData = async () => {
        setLoading(true);

        try {
            let data = JSON.parse(localStorage.getItem('nuclides'));
            let exportable = Object.values(data)
                .filter((nuclideVal) => nuclideVal.location.toLowerCase() !== 'not shown')
                .filter((nuclideVal) => squareInFilterRanges(nuclideVal));

            const response = await axios.post(`${aws.lamdba.URL}/export`, {includes: exportable});
            const fileName = `${timestamp()}_nuclide_export.csv`;

            // ensure unicode symbols are properly encoded
            const csvData = '\uFEFF' + response.data;

            if (window.cordova) {
                createFile(csvData, fileName);
            } else {
                fileDownload(csvData, fileName);
            }
        } catch (error) {
            handleError();
        }

        setLoading(false);
    };

    const createFile = (data, fileName) => {
        let exportDir;

        if (window.cordova.file.documentsDirectory !== null) {
            // iOS
            exportDir = window.cordova.file.documentsDirectory;
        } else if (window.cordova.file.externalDataDirectory !== null) {
            exportDir = window.cordova.file.externalDataDirectory;
        } else {
            // fail safe
            exportDir = window.cordova.file.dataDirectory;
        }

        window.resolveLocalFileSystemURL(exportDir, (dir) => {
            dir.getFile(fileName, {create: true, exclusive: false}, (entry) => {
                writeFile(entry, data);
            })
        })
    };

    const writeFile = (fileEntry, data) => {
        fileEntry.createWriter((fileWriter) => {
            console.log(fileEntry, 'entry');
            fileWriter.onwriteend = () => promptOpenFile(fileEntry);

            fileWriter.onerror = () => handleError();

            fileWriter.write(data);
        });
    };

    const promptOpenFile = (fileEntry) => {
        window.cordova.plugins.fileOpener2.showOpenWithDialog(
            fileEntry.nativeURL,
            'text/csv',
            {
                error: function (e) {
                    handleError();
                },
                success: function () {
                    console.log('file opened');
                }
            }
        );
    };

    const noFiltersApplied = getColorFilters().length === 0;

    return (
        <>
            <button className="submit" disabled={!navigator.onLine || loading || noFiltersApplied} onClick={exportData}>
                {loading
                    ? (<span> <FontAwesomeIcon icon={faSpinner} spin/> Exporting... </span>)
                    : <span>Export CSV</span>}
            </button>
            <SweetAlert
                error
                confirmBtnText="OK"
                title="Oh no!"
                onConfirm={onConfirm}
                show = {error}
                style = {{backgroundColor: '#919191' }}
            >
                Something went wrong! Please check internet connection and try again when connected.
            </SweetAlert>
        </>
    )

}
