class NuclideData {
    constructor(data) {
        this.nuclides = data;
    }

    getIsotopesFromObject(name) {
        let isotopes = [];

        let key1 = `${name}-0`;
        let key2 = `${name}m-1`;
        let key3 = `${name}m'-2`;
        let altKey3 = `${name}m2-2`;

        isotopes.push(this.nuclides[key1]);
        isotopes.push(this.nuclides[key2]);
        isotopes.push(this.nuclides[key3]);
        isotopes.push(this.nuclides[altKey3]);

        return isotopes.filter(Boolean).filter(isotope => isotope.location !== 'not shown');
    }
}

export default NuclideData;