import {
    createSquareIndependentFissionYield,
    createTriangleIndependentFissionYield,
    createDisintegrationEnergy,
    createSpinAndParity,
    createHalfLifeAbundance,
    createDoubleArrow,
    createDecay,
    createMass,
    createCrossSectionAndResonance
} from "../creators";
import {
    isColumnInMagicNumbers,
    isNuclideInBothMagicRowAndColumn,
    isRowInMagicNumbers
} from "../../../helpers/IsInMagicNumbers";
import ColorSquare from '../levelhelpers/ColorSquare';
import {fabric} from 'fabric';
import {assignColorsWithFiltering} from "./ColorFilter";

class HalfLeftSquare {
    constructor(nuclide, items, squareSize, isotopes, isOnChart = true) {
        this.nuclide = nuclide;
        this.items = items;
        this.squareSize = squareSize;
        this.isotopes = isotopes;
        this.strokeWidth = 1;
        this.isOnChart = isOnChart;
    }

    addMass(zoomLevel) {
        let levelMap = {
            8: {
                top: 225,
                left: this.squareSize/4,
                fontSize: 18,
            },
            7: {
                top: 105,
                left: this.squareSize/4,
                fontSize: 11
            },
            6: {
                top: 50,
                left: this.squareSize/2,
                fontSize: 9
            }
        };

        this.items.push(createMass(this.nuclide, {origin: 'center', top: levelMap[zoomLevel].top, fontSize: levelMap[zoomLevel].fontSize, left: levelMap[zoomLevel].left}));
    }

    addSpinAndParity(zoomLevel) {
        let nuclide = this.isotopes[0];
        let spin = this.nuclide.jpi ? this.nuclide.jpi : '';
        let fontSize = zoomLevel === 8 ? 20 : (spin.length > 5 ? 10 : 12);


        this.items.push(createSpinAndParity(this.nuclide, {left: this.squareSize * 0.05, top: this.squareSize/24, fontSize: fontSize, sZeroIsotope: nuclide}));
    }

    addHalflifeAbundance(zoomLevel) {
        let baseTop = this.squareSize/6;
        this.items.push(createHalfLifeAbundance(this.nuclide, {top: zoomLevel === 8 ? baseTop : baseTop + 12, left: this.squareSize/4, fontSize: zoomLevel === 8 ? 20 : (this.nuclide.halflife && this.nuclide.abundance ? 12 : 14)}));
    }

    addDecayData() {
        let stripLength = 15;
        let left = 8;

        if (this.nuclide.abundance && this.nuclide.halflife) {
            this.items.push(createDecay(this.nuclide, {stripLength: stripLength, top: 256/3, left: left}));
        } else {
            this.items.push(createDecay(this.nuclide, {stripLength: stripLength, top: 256/3 - 20, left: left}));
        }
    }

    addIndependentFissionYield() {
        let symbol = this.nuclide.fpsymbol ? this.nuclide.fpsymbol : '';
        let top = 258;


        if (this.isOnChart) {
            if (isNuclideInBothMagicRowAndColumn(this.nuclide)) {
                top = 252;
            } else if (isRowInMagicNumbers(this.nuclide)) {
                top = 252;
            } else if (isColumnInMagicNumbers(this.nuclide)) {
                top = 258;
            }
        }

        if (symbol && symbol.toUpperCase() === "X") {
            this.items.push(createSquareIndependentFissionYield(this.nuclide, {top: 256, left: 256/2+2}));
        } else if (symbol === "\/") {
            this.items.push(createTriangleIndependentFissionYield(this.nuclide, {top: top, left: 256/2+10}));
        }
    }

    addCrossSectionResonance() {

        if (this.nuclide.mass) {
            this.items.push(createCrossSectionAndResonance(this.nuclide, {stripLength: 17, left: 8, top: 256 - 80}));
        } else {
            this.items.push(createCrossSectionAndResonance(this.nuclide, {stripLength: 17, left: 8, top: 256 - 30}));
        }
    }

    addDoubleArrow() {
        let nuclideArrow = this.nuclide.doublearrow ? this.nuclide.doublearrow.toUpperCase() : '';
        if(nuclideArrow === 'HORIZONTAL' || nuclideArrow === 'YES') {
            this.items.push(createDoubleArrow(this.nuclide, {top: this.squareSize/2, left: this.squareSize/2 + 1}))
        }
    }

    addDisintegrationE(zoomLevel) {
        let levelMap = {
            8: {
                'mass': {
                    top: this.squareSize - 50,
                },
                'no_mass' : {
                    top: this.squareSize - 30,
                },
                splitLine: false,
                left: this.squareSize/4,
                fontSize: this.nuclide.E ? 18 : 14,
            },
            7: {
                'mass': {
                    top: 95,
                },
                'no_mass' : {
                    top: 105,
                },
                splitLine: false,
                left: this.squareSize/4,
                fontSize: this.nuclide.E ? 12 : 7,
            },
            6: {
                'mass': {
                    top: this.nuclide.E ? 40 : 35,
                },
                'no_mass' : {
                    top: this.nuclide.E ? 50 : 40,
                },
                splitLine: true,
                left: this.squareSize/4,
                fontSize: this.nuclide.E ? 7 : 6,
            }
        };


        if (this.nuclide.mass) {
            this.items.push(createDisintegrationEnergy(this.nuclide, {top: levelMap[zoomLevel]['mass'].top, left: levelMap[zoomLevel].left, fontSize: levelMap[zoomLevel].fontSize, origin: 'center', splitLine: levelMap[zoomLevel].splitLine}));
        } else {
            this.items.push(createDisintegrationEnergy(this.nuclide, {top:levelMap[zoomLevel]['no_mass'].top, left: levelMap[zoomLevel].left, fontSize: levelMap[zoomLevel].fontSize, origin: 'center', splitLine: levelMap[zoomLevel].splitLine}));
        }
    }

    addColor() {
        let colorSpec = new ColorSquare(this.nuclide);
        let topShade = colorSpec.populateTopColor();
        let bottomShade = colorSpec.populateBottomColor();

        [topShade, bottomShade] = assignColorsWithFiltering(this.nuclide, {topShade, bottomShade});

        let colorUp = new fabric.Rect({
            width: this.squareSize/2, 
            fill: topShade,
            strokeWidth: this.strokeWidth,
            height: this.squareSize/2,
            objectCaching: false,
        })
        let colorDown = new fabric.Rect({
            width: this.squareSize/2, 
            top: this.squareSize/2,
            fill: bottomShade,
            strokeWidth: this.strokeWidth,
            height: this.squareSize/2,
            objectCaching: false,
        })
        this.items.unshift(colorUp, colorDown)        
    }
}

export default HalfLeftSquare;