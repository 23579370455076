import React, { Component } from "react";
import axios from "axios";
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import NavigationContainer from "./NavigationContainer/NavigationContainer";
import PeriodicTableContainer from "./PeriodicTableContainer";
import Loading from "./Loading";
import Error from "./Error";
import NuclideChartContainer from "./NuclideChartContainer";
import PrivacyPolicyContainer from "./PrivacyPolicyContainer";
import aws from '../aws-config';

class Main extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isDataReady: false,
            isOnline: false,
            isLoading: true,
            isError: false,
        };
        this.setData = this.setData.bind(this);
        this.setDeviceOnlineStatus = this.setDeviceOnlineStatus.bind(this);
        this.storageHasNuclides = this.storageHasNuclides.bind(this);
        this.databaseVersionMatches = this.databaseVersionMatches.bind(this);
        this.getVersion = this.getVersion.bind(this);
        this.handleDatabaseUpdate = this.handleDatabaseUpdate.bind(this);

        this.url = aws.s3.URL;
    }

    async componentDidMount() {
        await this.setDeviceOnlineStatus();
        this.handleDatabaseUpdate();
    }

    async handleDatabaseUpdate() {
        let version = await this.getVersion();
        if(this.state.isOnline) {
            if (!this.storageHasNuclides() || !this.databaseVersionMatches(version)) {
                await this.setData();
            } else {
                // show chart
                this.setState({...this.state, isLoading: false, isError: false});
            }
        } else {
            if (this.storageHasNuclides()) {
                // show chart
                this.setState({...this.state, isError: false, isLoading: false});
            } else {
                // show error
                this.setState({...this.state, isError: true, isLoading: false});
            }
        }
    }

    setDeviceOnlineStatus() {
        let isDeviceOnline = navigator.onLine;
        this.setState({...this.state, isOnline: isDeviceOnline});
    }

    async getVersion() {
        try {
            let response = await axios.head(this.url);
            return response.headers['x-amz-version-id'];
        } catch (error) {
            // show error
            this.setState({...this.state, isLoading: false, isError: true, isOnline: false});
            console.log(error);
        }
    }

    storageHasNuclides() {
        return !!localStorage.getItem('nuclides');
    }

    databaseVersionMatches(version) {
        return localStorage.getItem('version') === version;
    }

    async setData(){
        try {
            let {data, headers} = await axios.get(this.url);
            localStorage.setItem('version', headers['x-amz-version-id']);
            localStorage.setItem('nuclides', JSON.stringify(data));
            // show chart
            this.setState({...this.state, isLoading: false, isError: false});
        } catch(error) {
            // show error
            this.setState({...this.state, isError: true, isLoading: false});
            console.log(error, 'error');
        }
    }

    render() {
        const {isLoading, isError} = this.state;
        return (
            <BrowserRouter>
                <div className="mainWrapper">
                    {window.location.pathname !== "/privacy-policy" ? (
                        <div className="navigationBar">
                        {!isLoading && !isError && <NavigationContainer />}
                        </div>
                    ) : null}
                    <Switch>
                        <Route
                            path="/periodic"
                            render={(props) => isLoading ? <Loading/> : (isError ? <Error/> : <PeriodicTableContainer/>)  }
                        />
                        <Route
                            path="/nuclide"
                            render={(props) => isLoading ? <Loading/> : (isError ? <Error/> : <NuclideChartContainer/>)  }
                        />
                        <Route
                            path="/privacy-policy"
                            render={(props) => isLoading ? <Loading/> : (isError ? <Error/> : <PrivacyPolicyContainer/>)  }
                        />
                        <Redirect from="/" extact to="/nuclide" />
                    </Switch>
                </div>
            </BrowserRouter> 
        );
    }
}

export default Main;
