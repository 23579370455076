import {
    createSquareIndependentFissionYield,
    createTriangleIndependentFissionYield,
    createSpinAndParity,
    createHalfLifeAbundance,
    createDoubleArrow,
    createDecay,
    createCrossSectionAndResonance
} from "../creators";
import {
    isColumnInMagicNumbers,
    isNuclideInBothMagicRowAndColumn,
    isRowInMagicNumbers
} from '../../../helpers/IsInMagicNumbers';
import ColorSquare from '../levelhelpers/ColorSquare';
import {fabric} from 'fabric';
import {assignColorsWithFiltering} from "./ColorFilter";

class DownQuarterSquare {
    constructor(nuclide, items, squareSize) {
        this.nuclide = nuclide;
        this.items = items;
        this.squareSize = squareSize;
        this.strokeWidth = 1
    }


    addSpinAndParity(zoomLevel) {
        let spin = this.nuclide.jpi ? this.nuclide.jpi : '';
        let fontSize = zoomLevel === 8 ? 20 : (spin.length > 5 ? 8 : 10);
        let doublearrow = this.nuclide.doublearrow ? this.nuclide.doublearrow : '';
        let offset = 0;

        if (zoomLevel === 7 && doublearrow.toUpperCase() === 'VERTICAL') {
            offset = 8;
            fontSize = 7;
        }

        this.items.push(createSpinAndParity(this.nuclide, {left: this.squareSize/50 + offset, top: this.squareSize/2 + (this.squareSize/11), fontSize: fontSize}));
    }

    addDoubleArrow() {
        let nuclideArrow = this.nuclide.doublearrow ? this.nuclide.doublearrow.toUpperCase() : '';
        if(nuclideArrow === 'HORIZONTAL - BOTTOM') {
            this.items.push(createDoubleArrow(this.nuclide, {top: this.squareSize - this.squareSize/4, left:this.squareSize/2}))
        }
    }

    addHalflifeAbundance(zoomLevel) {
        let spin = this.nuclide.jpi ? this.nuclide.jpi : '';
        this.items.push(createHalfLifeAbundance(this.nuclide, {top: this.squareSize/2 + this.squareSize/10, left: this.squareSize/4 + 6 + spin.length, fontSize: zoomLevel === 8 ? 20 : 14}));
    }

    addIndependentFissionYield() {
        let symbol = this.nuclide.fpsymbol ? this.nuclide.fpsymbol : '';
        let top;

        if (isNuclideInBothMagicRowAndColumn(this.nuclide)) {
            top = 252;
        } else if (isRowInMagicNumbers(this.nuclide)) {
            top = 252;
        } else if (isColumnInMagicNumbers(this.nuclide)) {
            top = 258;
        } else {
            top = 258;
        }

        if (symbol && symbol.toUpperCase() === "X") {
            this.items.push(createSquareIndependentFissionYield(this.nuclide, {top: this.squareSize, left: this.squareSize/2+2}));
        } else if (symbol === "\/") {
            this.items.push(createTriangleIndependentFissionYield(this.nuclide, {top: top, left: 256/2 + 10}));
        }
    }

    addDecayData() {
        this.items.push(createDecay(this.nuclide, {stripLength: 17, top: 256/2 + 48, left: 8}))
    }

    addCrossSectionResonance() {
        this.items.push(createCrossSectionAndResonance(this.nuclide, {stripLength: 17, left: 8, top: 256 - 10}));
    }

    addColor() {
        let colorSpec = new ColorSquare(this.nuclide);
        let topShade = colorSpec.populateTopColor();
        let bottomShade = colorSpec.populateBottomColor();

        [topShade, bottomShade] = assignColorsWithFiltering(this.nuclide, {topShade, bottomShade});

        let colorUp = new fabric.Rect({
            strokeWidth: this.strokeWidth,
            top: this.squareSize/1.7,
            width: this.squareSize/2, 
            height: this.squareSize/4,
            fill: topShade,
            objectCaching: false,
        })
        let colorDown = new fabric.Rect({
            strokeWidth: this.strokeWidth,
            width: this.squareSize/2, 
            top: this.squareSize/1.3,
            height: this.squareSize/4.4,
            fill: bottomShade,
            objectCaching: false,
        })
        this.items.unshift(colorUp, colorDown);
    }
}

export default DownQuarterSquare;