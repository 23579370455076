import React, { Component } from 'react';
import CheckboxContainer from './menu-fields/CheckboxContainer';
import ColorSection from './menu-fields/ColorSection';
import ColorFilterSpec from './menu-fields/ColorFilterSpec';
import store from '../store/ReduxStore';
import {chart} from './NuclideChartContainer';
import defaultColors from '../helpers/DefaultColors';
import SweetAlert from 'react-bootstrap-sweetalert';

class SidebarContent extends Component {
  constructor(props){
    super(props);
    this.state = {
      colorAlert: false,
      filterAlert: false
    }
    this.hideColorAlert = this.hideColorAlert.bind(this);
    this.hideFilterAlert = this.hideFilterAlert.bind(this);
    this.onCancelFilterDelete = this.onCancelFilterDelete.bind(this);
    this.onCancelColorDelete = this.onCancelColorDelete.bind(this);
    this.handleTurnToDefault = this.handleTurnToDefault.bind(this);
    this.clearAllFilters = this.clearAllFilters.bind(this)
  }
  showSettings (event) {
    event.preventDefault();
  }

  handleTurnToDefault() {
    this.setState ({
      colorAlert: true
    })
  }

  clearAllFilters() {
    this.setState ({
      filterAlert: true
    })
  }

  hideFilterAlert() {
    store.dispatch({type: 'colorFilter/clearAllFilters'});
    this.setState({
      filterAlert: false
    });
  }

  hideColorAlert() {
    store.dispatch({ type: 'continuous/updateRanges', payload: defaultColors});
    store.dispatch({ type: 'discreteDecay/updateDiscreteDecay', payload: {
        orientation: "topshade",
        sortedValues: []
    }});
    store.dispatch({ type: 'discreteDecay/updateDiscreteDecay', payload: {
        orientation: "bottomshade",
        sortedValues: []
    }});
    store.dispatch({ type: 'discreteParity/updateDiscreteParity', payload: {
        topshade: {
            isPositiveVsNegative: null,
            isStrongVsWeak: null
        },
        bottomshade: {
            isPositiveVsNegative: null,
            isStrongVsWeak: null
        }
    }});
    store.dispatch({ type: 'modify/colorFilterAttribute', payload: "" });
    store.dispatch({ type: 'modify/colorFilterMin', payload: "" })
    store.dispatch({ type: 'modify/colorFilterMax', payload: "" })

    // To see the dynamic changes, we need to re-render the leaflet layer
    // instead of re-rendering the whole component
    chart.eachLayer(function(layer){
        chart.removeLayer(layer);
        chart.addLayer(layer);
    });
    this.setState({
      colorAlert: false
    });
  }

  onCancelColorDelete() {
    this.setState({
      colorAlert: false
    })
  }

  onCancelFilterDelete() {
    this.setState({
      filterAlert: false
    })
  }

  render () {
    const alertModalBtn = {
      boxShadow: '0', 
      fontSize: '12px', 
      padding: '5px', 
      borderRadius: 10, 
      backgroundColor: 'white', 
      color: '#515151', 
      border:'0.5px solid #c4c4c4'
    }

    return (
      <div>
          <h1 className="menu-item">Data Display</h1>
          <CheckboxContainer /><br/>
          <h1 className="menu-item">Color Coding</h1>
          <p className='menu-section-subtitle'>Top Color:</p>
          <ColorSection orientation='topshade'/>
          <p className='menu-section-subtitle'>Bottom Color:</p>
          <ColorSection orientation='bottomshade'/><br/>
          <h1 className="menu-item">Color Filter</h1>
          <ColorFilterSpec />
          <br />
          <div className="buttons" style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', paddingBottom: '80px'}}>
              <button className="reset" onClick={this.clearAllFilters}>Clear Filters</button>
              <button className="reset" onClick={this.handleTurnToDefault}>Reset Colors</button>
              {this.state.alert}
          </div>
          <SweetAlert
            warning
            showCancel
            confirmBtnText="Yes, delete them!"
            confirmBtnStyle={alertModalBtn}
            cancelBtnStyle={alertModalBtn}
            title="Are you sure?"
            onConfirm={() => this.hideColorAlert()}
            onCancel={this.onCancelColorDelete}
            show = {this.state.colorAlert}
            style = {{backgroundColor: '#919191'}}
          >
            You are removing all the color coding selections!
          </SweetAlert>
          <SweetAlert
            warning
            showCancel
            confirmBtnText="Yes, delete them!"
            confirmBtnStyle={alertModalBtn}
            cancelBtnStyle={alertModalBtn}
            title="Are you sure?"
            onConfirm={() => this.hideFilterAlert()}
            onCancel={this.onCancelFilterDelete}
            show = {this.state.filterAlert}
            style = {{backgroundColor: '#919191' }}
          >
            You are removing all the color filtering selections!
          </SweetAlert>
      </div>
    );
  }
}

export default SidebarContent;
