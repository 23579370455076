import React from 'react';
import {createRoot} from 'react-dom/client';
import App from './App';
import './index.css';
import 'leaflet/dist/leaflet.css';
import { Provider } from 'react-redux';
import store from './store/ReduxStore';

const startApp = () => {
  createRoot(document.getElementById('root')).render(
    <Provider store={store}>
      <App />
    </Provider>
  );
}

if(!window.cordova) {
    startApp()
  } else {
    document.addEventListener('deviceready', startApp, false)
}
