import React, { Component } from 'react';
import { fabric } from 'fabric';
import DefaultSquare from "../../../levels/nuclide/levelhelpers/DefaultSquare";
import HalfRightSquare from "../../../levels/nuclide/levelhelpers/HalfRightSquare";
import HalfLeftSquare from "../../../levels/nuclide/levelhelpers/HalfLeftSquare";
import {generateLegendTextForNuclide} from "../../../helpers/nuclideLegendGenerator";
import './Legends.css';

class TwoStateNuclideLegend extends Component {
    constructor(props) {
        super(props);
        this.twoStateRef = React.createRef();
        this.methods = ['addMass', 'addDisintegrationE', 'addSpinAndParity', 'addHalflifeAbundance',
            'addDecayData', 'addIndependentFissionYield', 'addCrossSectionResonance'];

        this.squareSize = 256;
    }

    componentDidMount() {
        this.renderLegend();
    }

    componentWillUnmount() {
        this.canvas.setHeight(0);
        this.canvas.setWidth(0);
        this.canvas.clear();
    }

    addSquare = (states) => {
        const items = [];
        const isOnChart = false;
        const placeHolderZoomLevel = 8;

        const [s0Entry, s1Entry] = states;

        let defaultSquare = new DefaultSquare(s0Entry, items, this.squareSize, isOnChart);
        defaultSquare.addBlackBand();
        defaultSquare.addName(13);

        if (s0Entry.cluster === '1') {
            defaultSquare.addClusterSymbol();
        }

        let halfRight = new HalfRightSquare(s0Entry, items, this.squareSize, isOnChart);
        let halfLeft = new HalfLeftSquare(s1Entry, items, this.squareSize, this.props.isotopes, isOnChart);

        this.methods.forEach(method => {
            halfRight[method](placeHolderZoomLevel);
            halfLeft[method](placeHolderZoomLevel);
        });

        halfRight.addSubdivisionLine();
        halfLeft.addDoubleArrow();

        return new fabric.Group(items, {
            objectCaching: false,
            hasBorders: false,
            hasControls: false,
            hasRotatingPoint: false,
            lockMovementX: true,
            lockMovementY: true,
            hoverCursor: 'default'
        });
    };

    renderLegend = () => {

        const s0Entry = this.props.isotopes.filter(isotope => isotope.s == '0')[0];
        const s1Entry = this.props.isotopes.filter(isotope => isotope.s == '1')[0];

        this.canvas = new fabric.Canvas();
        this.canvas.initialize(this.twoStateRef.current);

        this.canvas.setHeight(270);
        this.canvas.setWidth(600);

        let group = this.addSquare([s0Entry, s1Entry]);

        this.canvas.add(group);
        this.canvas.add(generateLegendTextForNuclide([s0Entry, s1Entry]));
        group.center();
    };

    render() {
        const {N, Z} = this.props.isotopes[0];
        return (
            <div className='legend-container'>
                <div className='legend-inner-container'>
                    <canvas ref={this.twoStateRef}/>
                    <p style={{textAlign: 'center', marginTop: '0px'}}>Z: {Z}   N: {N}</p>
                </div>
            </div>
        )
    }

}

export default TwoStateNuclideLegend;
