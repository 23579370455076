import {fabric} from 'fabric';

class SubdivisionLine {
    constructor(items, nuclide, squareSize) {
        this.items = items;
        this.nuclide = nuclide;
        this.squareSize = squareSize;
    }
    addVerticalDivider() {
        let vDivider = new fabric.Line([
            this.squareSize/2, this.squareSize/6 +3,
            this.squareSize/2, this.squareSize
        ], {
            stroke: 'black',
            strokeWidth: 2,
            objectCaching: false
            }
        ); 
        this.items.push(vDivider);
    }
    addHorizontalDivider() {
        let hDivider = new fabric.Line([
            0, (this.squareSize+this.squareSize/6)/2, 
            this.squareSize/2, (this.squareSize+this.squareSize/6)/2
        ], {
            stroke: 'black',
            strokeWidth: 2,
            objectCaching: false
            }
        ); 
        this.items.push(hDivider);
    }
}

export default SubdivisionLine;