import React, { Component } from 'react';
import L from 'leaflet';
import {fabric} from 'fabric';
import _ from 'lodash';
import createOne from '../levels/nuclide/one.js';
import createTwo from '../levels/nuclide/two.js';
import createThree from '../levels/nuclide/three.js';
import createFour from '../levels/nuclide/four.js';
import createFive from '../levels/nuclide/five.js';
import createSix from '../levels/nuclide/six.js';
import createSeven from '../levels/nuclide/seven.js';
import createEight from '../levels/nuclide/eight.js';
import MiniMap from 'leaflet-minimap';
import UpdatedNuclides from '../helpers/UpdatedNuclide';
import config from '../config';
import store from '../store/ReduxStore';
import elementData from '../helpers/element_locations.json';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import NuclideData from "../levels/nuclide/levelhelpers/NuclideData";
import updateDecayNuclideOpacity from "../helpers/decaychain/UpdateDecayNuclideOpacity";
import {toggleMinimapDisplay} from "../store/actions/MiniMapToggleActions";
import axis from "../static/axis-small.png"
import ReactModal from 'react-modal';
import ElementLegend from './modals/Legends/ElementLegend';
import OneStateNuclideLegend from './modals/Legends/OneStateNuclideLegend';
import TwoStateNuclideLegend from "./modals/Legends/TwoStateNuclideLegend";
import ThreeStateNuclideLegend from "./modals/Legends/ThreeStateNuclideLegend";
import {chart as periodicChart} from './PeriodicTableContainer';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from '@fortawesome/free-solid-svg-icons'

ReactModal.setAppElement('body');
let chart;
class NuclideChartContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modalOpen: false,
            orientation: 'portrait',
            nuclideDataForLegend: [],
            redirect: false,
            isMinimized: store.getState().minimap.isMinimized,
            decayChainNuclide: ''
        };

        this.data = JSON.parse(localStorage.getItem('nuclides'));

        this.nuclideData = new NuclideData(this.data);
        let updatedNuclides = new UpdatedNuclides(this.data);
        updatedNuclides.injectElements();
        updatedNuclides.injectRowNumber();
        updatedNuclides.injectColumnNumber();
        updatedNuclides.injectOpacity();
        this.nuclides = updatedNuclides.nuclides;
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.convertLocationToNuclide = this.convertLocationToNuclide.bind(this);
        this.setLegend = this.setLegend.bind(this);
        this.convertLatlngToXYCoords = this.convertLatlngToXYCoords.bind(this);
        this.updateAndSetOrientation = this.updateAndSetOrientation.bind(this);
        this.clearDecayChain = this.clearDecayChain.bind(this);
        this.filterOutRowAndCols = this.filterOutRowAndCols.bind(this);
    }

    getXYZFromElement(elementFromChart) {
        let clickedElement = elementData.locations.filter(e => e.symbol === elementFromChart);
        return {
           x: clickedElement[0].x,
           y: clickedElement[0].y-0.5,
           z: 5
        }
    }

    async updateAndSetOrientation() {
        let orientation = window.screen.orientation.type;
        this.setState({
            orientation: orientation,
        });

        if (orientation === 'portrait-primary' || orientation === 'portrait-secondary') {
            await window.screen.orientation.lock('landscape-secondary');
        }
        if (orientation === 'landscape-primary') {
            await window.screen.orientation.lock('landscape-primary');
        } else if (orientation === 'landscape-secondary') {
            await window.screen.orientation.lock('landscape-secondary');
        }
    }

    // TODO: Find a better way to implement legends rather than locking screen
    async setLegend(e) {
        if (!!this.mousetimer) {
            clearTimeout(this.mousetimer);
        }

        let nucs = this.convertLocationToNuclide(e.latlng);
        const nucsWithoutNAndZSquares = this.filterOutRowAndCols(nucs);

        if (nucsWithoutNAndZSquares.length > 0) {
            if (window.cordova) {
                this.updateAndSetOrientation();
            }

            this.setState({
                modalOpen: true,
                nuclideDataForLegend: nucsWithoutNAndZSquares,
            });
        }
    }

    filterOutRowAndCols(nucs) {
        return nucs.filter(nuc => !nuc.isRow && !nuc.isColumn);
    }

    async handleCloseModal() {
        if (window.cordova) {
            await window.screen.orientation.unlock();
        }

        this.setState({
            modalOpen: false,
            nuclideDataForLegend: []
        });
    }

    convertLatlngToXYCoords(latlngCoords) {
        let xyCoords = chart.project(latlngCoords, 8);
        let x = Math.floor(xyCoords.x / 256);
        let y = 255 - (Math.floor(xyCoords.y / 256));

        // N values are x, Z values are y
        return [x, y];
    }

    convertLocationToNuclide(latlngCoords) {
        let [x, y] = this.convertLatlngToXYCoords(latlngCoords);

        return this.nuclides
            .filter(n => n.N === x && n.Z === y)
            .filter(n => n.location !== 'not shown' && n.location !== 'empty');
    }

    clearDecayChain(miniLayer) {
        this.nuclides.map(n => n.opacity = 1.0);
        chart.eachLayer(function(layer){
            chart.removeLayer(layer);
            // Swoopy arrow has an issue and Leaflet was not able to remove layers. Doing a little trick here:
            // We are removing ALL layers and only adding 2 needed layers, which are the actual chart and the N/Z static border.
            if (layer.isNeeded) {
                chart.addLayer(layer);
            }
        });
        // We need to clear layers from Minimap individually.
        miniLayer.redraw();
    }

    componentDidMount() {
        // TODO Should probably refactor the leaflet conversion logic here into a cleaner API

        store.dispatch(
            {
                type: 'modify/chartType',
                payload: 'nuclide'
            }
        );
        let that = this;

        chart = L.map('chart', {
            crs: L.CRS.Simple,
            zoomControl: false,
            doubleClickZoom: false,
            minZoom: config.chartMinZoom,
            maxZoom: 8,
            zoomSnap: 1,
            maxBounds: [ [-300, -5], [-120,200] ]
        }).setView([this.props.location.nuclideXYZ.y, this.props.location.nuclideXYZ.x], this.props.location.nuclideXYZ.z);

        function getNuclidesFromCoords(coords) {
            let x1 = (2**(8-coords.z)) * coords.x;
            let x2 = (2**(8-coords.z)) * coords.x + (2**(8-coords.z)-1);
            let y1 = 255 - (2**(8-coords.z)) * coords.y;
            let y2 = 255 - ((2**(8-coords.z)) * coords.y + (2**(8-coords.z)-1));
            let nuclideArray = that.nuclides
                .filter(n => n.location.toLowerCase() !== 'not shown')
                .filter(n => n.N >= Math.min(x1, x2) && n.N <= Math.max(x1, x2))
                .filter(n => n.Z >= Math.min(y1, y2) && n.Z <= Math.max(y1, y2))

            let deduped = _.uniqBy(_.sortBy(nuclideArray, ['name']), v => [v.N, v.Z].join());
            deduped = deduped.filter(n => n.Z !== null);
            return deduped;
        }

        function getParent(tile) {
            return [tile[0] >> 1, tile[1] >> 1, tile[2] - 1];
        }

        function tileToBBOX(tile) {
            let x = tile[0];
            let y = tile[1];
            let zoom = tile[2];
            let pixelSize = 2 ** (8 - zoom); // Distance you move when pan at each level
            let w = x * pixelSize; // Left bound
            let s = (y + 1) * pixelSize; // Bottom bound
            let e =  (x + 1) * pixelSize; // Right bound
            let n = y * pixelSize; // Top bound
            return [w, s, e, n];

        }

        function getOffsetFromNuclide(nuclide, zoom) {
            let x = nuclide.N;
            let y = 255 - nuclide.Z;
            let parent = [x, y, 8];
            while (parent[2] !== zoom) {
                parent = getParent(parent);
            }
            let tileBounds = tileToBBOX([x, y, 8]);
            let parentBounds = tileToBBOX(parent);
            let xOffset = (Math.abs((parentBounds[0] - tileBounds[0])) / Math.abs((parentBounds[2] - parentBounds[0]))) * 256
            let yOffset = (Math.abs((parentBounds[3] - tileBounds[3])) / Math.abs((parentBounds[3] - parentBounds[1]))) * 256
            return [xOffset, yOffset];
        }

        let withDefaultColorLayer = new L.GridLayer({
            noWrap: true,
            bounds: [[-266, -10], [0, 256]], // The "bounds" is in [(y1,x1), (y2, x1)] format
        });

        function customCreateTile(coords, done) {
            // We need to add these 2 conditional to get rid of the tile error on Mobile Browsers.
            // It happens when you try to zoom in more at level 8 or zoom out less at level 1
            if(coords.z > 8) {
                coords.z = 8;
            }
            if(coords.z < 1) {
                coords.z = 1;
            }

            let nuclidesForTile = getNuclidesFromCoords(coords);
            let canvas = new fabric.StaticCanvas('c', {
                renderOnAddRemove: true,
                objectCaching: false
            });
            canvas.setHeight(256);
            canvas.setWidth(256);
            let layerMapping = {
                1: createOne,
                2: createTwo,
                3: createThree,
                4: createFour,
                5: createFive,
                6: createSix,
                7: createSeven,
                8: createEight
            }
            nuclidesForTile.forEach(function(n) {
                let group = layerMapping[coords.z].call(this, n, that.nuclideData)
                let offset = getOffsetFromNuclide(n, coords.z);
                group.left = offset[0];
                group.top = offset[1];
                canvas.add(group);
            })
            setTimeout(function () {
                done(null, canvas);
            }, 100);

            return canvas.lowerCanvasEl;
        }

        let withDefaultColorCreateTile = function (coords, done) {
            return customCreateTile(coords, done);
        };

        withDefaultColorLayer.createTile = withDefaultColorCreateTile;
        withDefaultColorLayer.isNeeded = true;
        withDefaultColorLayer.addTo(chart);

        // Add Z and N static border as an image
        let imageUrl = axis,
        imageBounds = [[-257, 0], [-255, -2]];
        let overlay = L.imageOverlay(imageUrl, imageBounds);
        overlay.isNeeded = true;
        overlay.addTo(chart);

        // Needed to create a separate layer to show mini version of chart
        let miniNuclideLayer =  new L.GridLayer({
            noWrap: true,
            bounds: [[-512, 0], [0, 512]]
        });
        miniNuclideLayer.createTile = withDefaultColorCreateTile;

        let minimap = new L.Control.MiniMap(miniNuclideLayer, {
            toggleDisplay: true,
            collapsedWidth: 30,
            collapsedHeight: 30,
            position: 'bottomright',
            minimized: this.state.isMinimized,
            zoomLevelFixed: 1,
            width: 150,
            height: 150,
        });

        minimap.on('minimize', function() {
            minimap._miniMap.invalidateSize()
            minimap._miniMap.setView(minimap._miniMap.getCenter());
            that.props.toggleMinimapDisplay({isMinimized: true})
        });
        minimap.on('restore', function() {
            minimap._miniMap.invalidateSize()
            minimap._miniMap.setView(minimap._miniMap.getCenter());
            that.props.toggleMinimapDisplay({isMinimized: false})

        });

        minimap.addTo(chart);
        function onDoubleClick(e) {
            that.clearDecayChain(miniNuclideLayer);
            let locationItem = that.convertLocationToNuclide(e.latlng)[0];
            let decayChainItem = that.convertLocationToNuclide(e.latlng).filter(n => n.s === 0)[0];
            if(locationItem && locationItem.location === 'element') {
                // Navigation from Chart to Periodic Table
                store.dispatch(
                    {
                        type: 'modify/periodicXYZ',
                        payload: that.getXYZFromElement(locationItem.Symbol)
                    }
                )
                that.setState({'redirect': true});

            } else if(locationItem && locationItem.location !== 'row' && locationItem.location !== 'column') {
                if (that.state.decayChainNuclide === locationItem.name) {
                    // Clears decay chain when you click on already clicked parent nuclide
                    that.setState({decayChainNuclide: ''});
                } else {
                    // Draws decay chain display when you click on parent nuclide
                    that.setState({decayChainNuclide: locationItem.name});
                    that.nuclides.map(n => n.opacity = 1.0);
                    that.nuclides = updateDecayNuclideOpacity(that.nuclides, decayChainItem, chart);
                    chart.eachLayer(function(layer) {
                        chart.removeLayer(layer);
                        chart.addLayer(layer);
                    });
                    that.nuclides = updateDecayNuclideOpacity(that.nuclides, decayChainItem, chart);
                }
            } else {
                // Clears decay chain when you click off chart or on an empty square
                that.setState({decayChainNuclide: ''});
                that.clearDecayChain(miniNuclideLayer);
            }
        }

        if (!window.cordova) {
            chart.on('mousedown', (e) => {
                this.mousetimer = setTimeout(() => this.setLegend(e), 500);
            });

            chart.on('mouseup mousemove', () => {
                clearTimeout(this.mousetimer);
            })
        }

        chart.on('contextmenu', this.setLegend);

        chart.on('dblclick', onDoubleClick);
        chart.on('drag', function() {
            let zoom = chart.getZoom();
            if (zoom >= 6) {
                let bounds = chart.getCenter();
                let xValue = parseInt(bounds.lng);
                let yValue = parseInt(bounds.lat);
                // IT IS BLACK MAGIC ¯\_(ツ)_/¯
                // It swallow error messages for Lv and Og when you scroll up and pan right.
                // We have to fix this for real. This should not stay like this at all.
                // It will cause so many headaches down the road.
                try {
                    let topYCoord = _.maxBy(that.nuclides
                                            .filter(n => n.name !== 'Ts')
                                            .filter(n => n.N === xValue), 'Z').Z - 255;
                    let bottomYCoord = _.minBy(that.nuclides.filter(n => n.N === xValue), 'Z').Z - 255;
                    let rightXCoord = _.maxBy(that.nuclides.filter(n => n.Z === yValue + 255), 'N').N;
                    if (yValue >= topYCoord + 4) {
                        let topBoundsForUser = bounds;
                        topBoundsForUser.lat = topYCoord;
                        chart.setView(bounds);
                    } else if (yValue < bottomYCoord - 1) {
                        let bottomBoundsForUser = bounds;
                        bottomBoundsForUser.lat = bottomYCoord;
                        chart.setView(bounds);
                    } else if (xValue >= rightXCoord + 4) {
                        let rightBoundsForUser = bounds;
                        rightBoundsForUser.lng = rightXCoord;
                        chart.setView(bounds);
                    }
                }
                catch{
                    chart.setView(bounds);
                }
            }
        });
    }

    componentDidUpdate() {
        chart.eachLayer(function(layer){
            chart.removeLayer(layer);
            chart.addLayer(layer);
        });
    }

    componentWillUnmount() {
        chart.eachLayer(function(layer){
            chart.removeLayer(layer);
        });
        chart.remove();
    }
    componentWillMount() {
        if (periodicChart) {
            let z = periodicChart.getZoom();
            let coords = periodicChart.project(periodicChart.getCenter(), z);
            store.dispatch(
                {
                    type: 'modify/periodicXYZ',
                    payload: {x: coords.x / 256 - 0.5, y: coords.y / 256 - 1,z: z}
                }
            )
        }
    }
    render() {
        if (this.state.redirect) {
            return <Redirect
                to="/periodic"
                />;
        }

        let legendElementForRender;
        let nuclideDataForLegend = this.state.nuclideDataForLegend;

        if (nuclideDataForLegend.length === 1) {
            if (nuclideDataForLegend.filter(entry => entry.location === 'element').length > 0) {
                legendElementForRender = <ElementLegend element={nuclideDataForLegend[0]}/>
            } else {
                legendElementForRender = <OneStateNuclideLegend nuclide={nuclideDataForLegend[0]}/>
            }
        } else if (nuclideDataForLegend.length === 2) {
            legendElementForRender = <TwoStateNuclideLegend isotopes={nuclideDataForLegend}/>
        } else if (nuclideDataForLegend.length === 3) {
            legendElementForRender = <ThreeStateNuclideLegend isotopes={nuclideDataForLegend}/>
        }

        return (
            <React.Fragment>
                <div id='chart'/>
                <ReactModal
                    className="legendModal"
                    overlayClassName= "modal_overlay"
                    isOpen={this.state.modalOpen}
                    contentLabel="Legend"
                    onRequestClose={this.handleCloseModal}
                    closeTimeoutMS={500}
                >
                    <FontAwesomeIcon className="modal-button" onClick={this.handleCloseModal} icon ={faTimes} size="1x" style={{marginRight: '5px'}}/>
                    {nuclideDataForLegend.length > 0 && legendElementForRender}
                </ReactModal>
            </React.Fragment>
        );
    }
}


const mapStateToProps = state => ({
    location: state.location,
    dataDisplay: state.dataDisplay,
    colorFilter: state.colorFilter,
    discreteDecay: state.discreteDecay,
    discreteParity: state.discreteParity
});
export default connect(mapStateToProps, {toggleMinimapDisplay})(NuclideChartContainer);
export {chart};
