import {fabric} from 'fabric';
import DefaultSquare from '../nuclide/levelhelpers/DefaultSquare';
import RowColSquare from '../nuclide/levelhelpers/RowColSquare'
import SquareGrid from '../nuclide/levelhelpers/SquareGrid';

export default function createThree(nuclide) {
    let items = [];

    if(nuclide.isRow || nuclide.isColumn) {
        new RowColSquare(nuclide, items, 8, false);
    } else {
        new DefaultSquare(nuclide, items, 8);

        let grid = new SquareGrid(items, nuclide, 8);
        grid.createGrid(3);
    }

    let group = new fabric.Group(items, {
        objectCaching: false,
        opacity: nuclide.opacity
    });

    return group;
};