import React, { Component } from "react";
import Autosuggest from 'react-autosuggest';
import Fuse from 'fuse.js';
import _ from "lodash";
import {chart} from './NuclideChartContainer';
import {chart as periodic} from './PeriodicTableContainer';
import getXYZFromElement from '../helpers/getXYZ';
import store from '../store/ReduxStore'
import elementData from '../helpers/element_locations.json';

let elements = require('../elements.json').elements;

let options = {
    id: "name",
    shouldSort: true,
    threshold: 0.3,
    location: 0,
    distance: 100,
    maxPatternLength: 10,
    minMatchCharLength: 1,
    keys: [
      "name",
      "longName"
    ]
};

export default class SearchContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
      suggestions: [],
    };
    this.nuclides = Object.values(JSON.parse(localStorage.getItem('nuclides')));
    this.filteredNuclides = _.filter(this.nuclides, function(n) {
      return n.location !== 'not shown' && n.location !== 'empty' && (n.s === 0 || n.location === 'element');
    })
    this.filteredNuclides.forEach(n => n.longName = this.convertFullName(n.name));

  }

  convertFullName(searched) {
    const elName = _.find(elements, e => searched.replace(/\d/g, "") === e.symbol).element_name;
    let nuclideNumber = searched.match(/\d+/);
    if (nuclideNumber) {
      nuclideNumber = '-' + nuclideNumber[0];
    } else {
      nuclideNumber = '';
    }
  
    return elName + nuclideNumber;
  }

  componentDidMount() {
    this.getXYZForPeriodic = function(searchedElement) {
      let filteredElement = _.filter(this.nuclides, function(n) {
          return n.name === searchedElement
      })
      let element = elementData.locations
          .filter(
              e => e.symbol === filteredElement[0].Symbol
          )
      return {
        x: element[0].x,
        y: element[0].y-0.5,
        z: 5
      }
    }
  }

  getSuggestionValue = function(suggestion) {
    return suggestion;
  }

  renderSuggestion = function(suggestion) {
    return (
      <span>{suggestion}</span>
    );
  }

  getSuggestions (value) {
    let fuse = new Fuse(this.filteredNuclides, options);
    return fuse.search(value).slice(0, 10);
  }
  
  onChange = (event, { newValue, method }) => {
    this.setState({
      value: newValue
    });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
        suggestions: this.getSuggestions(value)
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  onSuggestionSelected = (event, {suggestionValue}) => {
      let nuclide = _.filter(this.nuclides, function(n) {
          return n.name === suggestionValue;
      })[0];
      let xVal = nuclide.N + 0.5;
      let yVal = nuclide.Z - 255.5;
      if (store.getState().chartType === 'periodic') {
          
          let coords = this.getXYZForPeriodic(suggestionValue);
          
          let z = coords.y;
          let n = coords.x;
          let zoom = coords.z;

          let latlng = periodic.unproject([(n+0.5) * 256, (z+1) * 256], zoom);
          periodic.setView([latlng.lat, latlng.lng], zoom);

      } else {
        if (nuclide.location === 'element') {
          let coords = new getXYZFromElement(nuclide.Symbol, this.nuclides);
          let z = coords.y;
          let n = coords.x;
          let zoom = coords.z;
          chart.setView([z, n], zoom)
        } else {
          chart.setView([yVal, xVal], 8);
        }
      }
      this.setState({
        value: ''
      });
  };

  render() {
    const { value, suggestions } = this.state;
    const inputProps = {
      placeholder: "Search",
      value,
      onChange: this.onChange,
    };

    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        onSuggestionSelected={this.onSuggestionSelected}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        inputProps={inputProps} 
      />
    );
  }
}