export default {
    ALPHA: '\u03b1',
    DOUBLE_ALPHA: '2\u03b1',
    DELAYED_ALPHA: '(\u03b1)',
    DELAYED_DOUBLE_ALPHA: '(2\u03b1)',
    BETA_MINUS: '\u03b2-',
    DOUBLE_BETA_MINUS: '\u03b2-\u03b2-',
    DOUBLE_BETA_MINUS_WITH_SPACE: '\u03b2- \u03b2-',
    BETA_PLUS: '\u03b2+',
    ELECTRON_CAPTURE: '\u03b5',
    DOUBLE_ELECTRON_CAPTURE: '\u03b5\u03b5',
    GAMMA: '\u03b3',
    N: 'n',
    N_DELAYED: '(n)',
    TWO_N: '2n',
    TWO_N_DELAYED: '(2n)',
    THREE_N: '3n',
    THREE_N_DELAYED: '(3n)',
    FOUR_N: '4n',
    FOUR_N_DELAYED: '(4n)',
    P: 'p',
    P_DELAYED: '(p)',
    TWO_P: '2p',
    TWO_P_DELAYED: '(2p)',
    THREE_P: '3p',
    THREE_P_DELAYED: '(3p)',
    T: 't',
    T_DELAYED: '(t)',
    D: 'd',
    D_DELAYED: '(d)'
};

// TODO Find all instances of hard coded decay types and replace with enum
