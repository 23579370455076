import {
    createSquareIndependentFissionYield,
    createTriangleIndependentFissionYield,
    createMass,
    createDisintegrationEnergy,
    createSpinAndParity,
    createHalfLifeAbundance,
    createDecay, createCrossSectionAndResonance
} from "../creators";
import {
    isColumnInMagicNumbers,
    isNuclideInBothMagicRowAndColumn,
    isRowInMagicNumbers
} from '../../../helpers/IsInMagicNumbers';
import ColorSquare from '../levelhelpers/ColorSquare';
import SubdivisionLine from '../levelhelpers/Subdivision';
import {fabric} from 'fabric';
import {assignColorsWithFiltering} from "./ColorFilter";

class HalfRightSquare {
    constructor(nuclide, items, squareSize, isOnChart = true) {
        this.nuclide = nuclide;
        this.items = items;
        this.squareSize = squareSize;
        this.strokeWidth = 1;
        this.isOnChart = isOnChart;
    }

    addMass(zoomLevel) {
        let levelMap = {
            8: {
                left: this.nuclide.cluster == 1 ? this.squareSize / 2 + 50 : this.squareSize - (this.squareSize/4),
                top: 225,
                fontSize: 18,
                origin: 'center'
            },
            7: {
                left: this.squareSize - (this.squareSize/4),
                top: 105,
                fontSize: 10,
                origin: 'center'
            },
            6: {
                left: this.squareSize / 2 ,
                top: 50,
                fontSize: 9,
                origin: 'center'
            }
        };

        this.items.push(createMass(this.nuclide, {origin: levelMap[zoomLevel].origin, left: levelMap[zoomLevel].left, top: levelMap[zoomLevel].top, fontSize: levelMap[zoomLevel].fontSize}));
    }

    addDisintegrationE(zoomLevel) {
        let levelMap = {
            8: {
                'mass': {
                    top: this.squareSize - 50,
                },
                'no_mass': {
                    top: this.squareSize - 30,
                },
                left: this.squareSize - (this.squareSize/4),
                fontSize: this.nuclide.E ? 18 : 14,
                splitLine: false,
            },
            7: {
                'mass': {
                    top: 95,
                },
                'no_mass': {
                    top: this.nuclide.Eminus ? 95 : 105,
                },
                left: this.squareSize - (this.squareSize/4),
                fontSize: this.nuclide.E ? 12 : 11,
                splitLine: true,
            },
            6: {
                'mass': {
                    top: 40,
                },
                'no_mass': {
                    top: this.nuclide.E ? 50 : 40,
                },
                left:  this.squareSize - (this.squareSize/4),
                fontSize: this.nuclide.E ? 7 : 6,
                splitLine: true,
            }
        };


        if (this.nuclide.mass) {
            this.items.push(createDisintegrationEnergy(this.nuclide, {top: levelMap[zoomLevel]['mass'].top, left: levelMap[zoomLevel].left, fontSize: levelMap[zoomLevel].fontSize, origin: 'center', splitLine: levelMap[zoomLevel].splitLine}));
        } else {
            this.items.push(createDisintegrationEnergy(this.nuclide, {top: levelMap[zoomLevel]['no_mass'].top, left: levelMap[zoomLevel].left, fontSize: levelMap[zoomLevel].fontSize, origin: 'center', splitLine: levelMap[zoomLevel].splitLine}));
        }
    }

    addSpinAndParity(zoomLevel) {
        let spin = this.nuclide.jpi ? this.nuclide.jpi : '';
        let fontSize = zoomLevel === 8 ? 20 : (spin.length > 8 ? 7 : (spin.length > 5 ? 10 : 12));


        this.items.push(createSpinAndParity(this.nuclide, {origin: 'right', left: this.squareSize - 6, top: this.squareSize/24, fontSize: fontSize}));
    }

    addHalflifeAbundance(zoomLevel) {
        let baseTop = this.squareSize / 6;
        this.items.push(createHalfLifeAbundance(this.nuclide,{top: zoomLevel === 8 ? baseTop : baseTop + 12, left: this.squareSize - this.squareSize/4, fontSize: zoomLevel === 8 ? 20 : (this.nuclide.halflife && this.nuclide.abundance ? 12 : 14)}))
    }

    addDecayData() {
        let stripLength = 16;
        let left = 256/2+4;

        if (this.nuclide.abundance && this.nuclide.halflife) {
            this.items.push(createDecay(this.nuclide, {stripLength: stripLength, top: 256/3, left: left}));
        } else {
            this.items.push(createDecay(this.nuclide, {stripLength: stripLength, top: 256/3 - 20, left: left}));
        }
    }

    addIndependentFissionYield() {
        let symbol = this.nuclide.fpsymbol ? this.nuclide.fpsymbol : '';

        let top = 258;
        let left = 265;

        if (this.isOnChart) {
            if (isNuclideInBothMagicRowAndColumn(this.nuclide)) {
                top = top - 6;
                left = left - 5;
            } else if (isRowInMagicNumbers(this.nuclide)) {
                top = top - 6;
            } else if (isColumnInMagicNumbers(this.nuclide)) {
                left = left - 5;
            }
        }

        if (symbol && symbol.toUpperCase() === "X") {
            this.items.push(createSquareIndependentFissionYield(this.nuclide, {top: this.squareSize, left: this.squareSize}));
        } else if (symbol === "\/") {
            this.items.push(createTriangleIndependentFissionYield(this.nuclide, {top: top, left: left}));
        }
    }

    addCrossSectionResonance() {
        let stripLength = 17;
        let left = 256/2+5;

        if (this.nuclide.mass && this.nuclide.E) {
            this.items.push(createCrossSectionAndResonance(this.nuclide, {stripLength: stripLength, top: 256 - 50, left: left }));
        } else {
            this.items.push(createCrossSectionAndResonance(this.nuclide, {stripLength: stripLength, left: left, top: 256 - 30}));

        }
    }

    addSubdivisionLine() {
        let line = new SubdivisionLine(this.items, this.nuclide, this.squareSize);
        if(this.nuclide.location !== 'element' && this.nuclide.location !== 'empty') {
            line.addVerticalDivider();
        }
    }

    addColor() {
        let colorSpec = new ColorSquare(this.nuclide);
        let topShade = colorSpec.populateTopColor();
        let bottomShade = colorSpec.populateBottomColor();

        [topShade, bottomShade] = assignColorsWithFiltering(this.nuclide, {topShade, bottomShade});


        let colorUp = new fabric.Rect({
            width: this.squareSize/2-1, 
            strokeWidth: this.strokeWidth,
            height: this.squareSize/2,
            left: this.squareSize/2,
            fill: topShade,
            objectCaching: false,
        })
        let colorDown = new fabric.Rect({
            width: this.squareSize/2-1, 
            strokeWidth: this.strokeWidth,
            height: this.squareSize/2,
            left: this.squareSize/2,
            top: this.squareSize/2,
            fill: bottomShade,
            objectCaching: false,
        })
        this.items.unshift(colorUp, colorDown)
    }

}

export default HalfRightSquare;