import decayXYMap from "./decayXYMap";
import hasParentheses from "./hasParentheses";
import getModifiedDecays from "./getModifiedDecays";
import getPrimaryDecay from "./getPrimaryDecay";
import getDecayModes from "./getDecayModes";
import getS0NuclideByXYCoords from "./getS0NuclideByXYCoords";

const createChain = (startingNuclide, nuclides, chainArray = []) => {
    let modes = getDecayModes(startingNuclide);
    let primaryDecay = getPrimaryDecay(startingNuclide);
    let record = createDecayRecord(startingNuclide, modes, primaryDecay);
    let startingN = startingNuclide.N;
    let startingZ = startingNuclide.Z;
    chainArray.push(record);

    if (modes.length === 0) {
        return chainArray;
    }

    modes.forEach(mode => {
        let daughters = getDaughterNuclides(mode, {startingN, startingZ}, nuclides, record.primaryDecay);
        daughters.filter(Boolean).forEach(daughter => {
            chainArray = createChain(daughter, nuclides, chainArray);
        });
    });

    return chainArray;
};

const getDaughterNuclides = (mode, coords, nuclides, primaryDecay) => {
    let strippedDecayWithNums = getModifiedDecays(mode);
    let daughters = [];
    const {startingN, startingZ} = coords;

    if (hasParentheses(mode)) {
        let ZcoordOfPrimaryDecay = startingZ + decayXYMap[primaryDecay].Z;
        let NcoordOfPrimaryDecay = startingN + decayXYMap[primaryDecay].N;

        let delayedDecayZCoord = ZcoordOfPrimaryDecay + decayXYMap[strippedDecayWithNums].Z;
        let delayedDecayNCoord = NcoordOfPrimaryDecay + decayXYMap[strippedDecayWithNums].N;

        daughters.push(getS0NuclideByXYCoords({N: delayedDecayNCoord, Z: delayedDecayZCoord}, nuclides));
    } else {
        let {N, Z} = decayXYMap[strippedDecayWithNums];
        let newNCoord = startingN + N;
        let newZCoord = startingZ + Z;
        daughters.push(getS0NuclideByXYCoords({N: newNCoord, Z: newZCoord}, nuclides));
    }

    return daughters;
};

const createDecayRecord = (nuclide, modes, primaryDecay) => {
    return {
        "name": nuclide.name,
        "primaryDecay": primaryDecay,
        "N": nuclide.N,
        "Z": nuclide.Z,
        "modes": modes
    }
};

export default createChain;
