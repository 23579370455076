const isVertical = (nuclide) => {
    return _upperCaseDirection(nuclide) === 'VERTICAL';
};

const isHorizontal = (nuclide) => {
    const upperCaseDirection = _upperCaseDirection(nuclide);
    return upperCaseDirection === 'YES' || upperCaseDirection === 'HORIZONTAL' || upperCaseDirection === 'HORIZONTAL - BOTTOM';
};

const isHorizontalBottom = (nuclide) => {
    return _upperCaseDirection(nuclide) === 'HORIZONTAL - BOTTOM';
};

const _upperCaseDirection = (nuclide) => {
    return nuclide.doublearrow.toUpperCase();
};

export {
    isVertical,
    isHorizontal,
    isHorizontalBottom
}