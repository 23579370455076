import React from 'react';


const MassDisintegrationEnergyRadioButtons = ({radiobuttons, onChange}) => (
    <React.Fragment>
        {radiobuttons.map(item => (
            <div key={item.label} style={{marginBottom: '2%'}}>
                <label>
                    <input type="radio" checked={item.checked} name="Mass and Disintegration Energy" value={item.name} onChange={onChange}/>
                    {item.label}
                </label>
                <br></br>
            </div>
        ))}
    </React.Fragment>
);

export default MassDisintegrationEnergyRadioButtons;