import React from 'react';
import nucleus from '../static/cluster-mini.png';

const LoadAnimation = () => (
  <div className="animation-container">
    <div className="animation-ring">
        <div className="animation-turn one"></div>
    </div>
    <div className="animation-ring">
        <div className="animation-turn two"></div>
    </div>
    <div className="animation-ring">
        <div className="animation-turn three"></div>
    </div>
    <div className="atom-nucleus"><img className="src" alt="logo-animation" src={nucleus}/></div>
  </div>
);


export default LoadAnimation;
