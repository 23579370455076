import React, {useState} from 'react';

const ColorFilterInputs = ({attribute, onBlur}) => {

    const [minState, setMinState] = useState(attribute.minVal);
    const [maxState, setMaxState] = useState(attribute.maxVal);

    return (
        <React.Fragment>
            <div><input type="text" style={{width: '75%', marginRight: '10px'}} id='minVal' name={attribute.attribute} placeholder='Min' value={minState}
                   onChange={e => setMinState(e.target.value)} onBlur={onBlur}/></div>
            <div><input type="text" style={{width: '75%'}} id='maxVal' name={attribute.attribute} placeholder='Max' value={maxState}
                   onChange={e => setMaxState(e.target.value)} onBlur={onBlur}/></div>
        </React.Fragment>
    )
};

export default ColorFilterInputs;