import {fabric} from "fabric";
import getValuesByPrefix from "../../../helpers/GetValuesByPrefix";
import stripText from "../levelhelpers/StripText";


function createCrossSectionAndResonance(nuclide, config) {
    let values = getValuesByPrefix(nuclide, 'xs').filter(n => n !== null);
    let strippedValues = values.map(n => stripText(n, config.stripLength));
    let mergedText = strippedValues.join('\n');

    let text = new fabric.Text(mergedText, {
        originY: 'bottom',
        top: config.top,
        left: config.left,
        fontSize: 14,
        textAlign: 'left',
        objectCaching: false
    });

    let searchVals = ['α', 'p', 'f', 'γ', 't'];

    mergedText.split('').forEach((letter, index) => {
        if (searchVals.indexOf(letter) !== -1) {
            text.setSubscript(index, index + 1);
        }
    });

    return text;
}


export {
    createCrossSectionAndResonance
}