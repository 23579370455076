import {fabric} from 'fabric';
import yield_colors from '../../../yield_colors';


function createTriangleIndependentFissionYield(nuclide, config) {

    let tri = new fabric.Triangle({
        originX: 'right',
        originY: 'bottom',
        left: config.left,
        top: config.top,
        fill: yield_colors[nuclide.fpcolor],
        width: 25,
        stroke: 'black',
        strokeWidth: 1.5,
        height: 12,
        objectCaching: false,
    });

    tri.rotate(135);

    return tri;
}

function createSquareIndependentFissionYield(nuclide, config) {

    return new fabric.Rect({
        originX: 'right',
        originY: 'bottom',
        left: config.left,
        top: config.top,
        fill: yield_colors[nuclide.fpcolor],
        width: 15,
        height: 15,
        objectCaching: false
    });

}

export {
    createSquareIndependentFissionYield,
    createTriangleIndependentFissionYield
}
