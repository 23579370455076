import L from 'leaflet';

L.CustomMarker = L.Layer.extend({
    latlng: [],
    options: {
        label: '',
        className: '',
        iconAnchor: [30, 10],
        minZoom: 0,
        maxZoom: 20,
    },

    initialize: function (latlng, options) {
        L.Util.setOptions(this, options);

        this._latlng = L.latLng(latlng);
        this._label = this.options.label;
        this._className = this.options.className;
        this._iconAnchor = this.options.iconAnchor;
        this._maxZoom = this.options.maxZoom;
        this._minZoom = this.options.minZoom;
    },

    onAdd: function (map) {
        this._map = map;

        this._drawMarker();
        this.update(this._map);
    },

    getEvents: function () {
        return {
            zoom: this.update,
            viewreset: this.update
        };
    },

    _drawMarker: function() {
        const swoopyLabel = this._createLabel();
        this._currentMarker = L.marker(this._latlng, {icon: swoopyLabel}).addTo(this._map);
    },

    _createLabel: function() {
        return L.divIcon({
                html: `<span style="font-size: ${16}px">${this._label}</span>`,
                className: this._className,
                iconAnchor: this._iconAnchor
            });
    },

    update: function(map) {
        this._checkZoomLevel();

        return this;
    },

    _checkZoomLevel: function() {
        const currentZoomLevel = this._map.getZoom();

        if(currentZoomLevel < this._minZoom || currentZoomLevel > this._maxZoom) {
            this._currentMarker.setOpacity(0);
        } else {
            this._currentMarker.setOpacity(1);
        }
    },

    onRemove: function(map) {
        this._map = map;
        this._map.removeLayer(this._currentMarker);
    }
});

L.customMarker = (latlng, options) => new L.CustomMarker(latlng, options);

export default L.customMarker;
