import {
    createTriangleIndependentFissionYield,
    createSpinAndParity,
    createHalfLifeAbundance,
    createDoubleArrow,
    createDecay
} from "../creators";
import ColorSquare from '../levelhelpers/ColorSquare';
import SubdivisionLine from '../levelhelpers/Subdivision';
import {fabric} from 'fabric';
import {assignColorsWithFiltering} from "./ColorFilter";

class UpQuarterSquare {
    constructor(nuclide, items, squareSize) {
        this.nuclide = nuclide;
        this.items = items;
        this.squareSize = squareSize;
        this.strokeWidth = 1;
    }

    addSpinAndParity(zoomLevel) {
        let spin = this.nuclide.jpi ? this.nuclide.jpi : '';
        let fontSize = zoomLevel === 8 ? 20 : (spin.length > 5 ? 10 : 12);

        this.items.push(createSpinAndParity(this.nuclide, {left: this.squareSize * 0.05, top: this.squareSize/24, fontSize: fontSize}));
    }

    addDoubleArrow(zoomLevel) {
        let levelMap = {
            8: {
                top: this.squareSize/2 + 5,
                left: 8,
            },
            7: {
                top: this.squareSize/2 - 3,
                left: 6,
            }
        };

        let nuclideArrow = this.nuclide.doublearrow ? this.nuclide.doublearrow.toUpperCase() : '';
        if(nuclideArrow === 'VERTICAL') {
            this.items.push(createDoubleArrow(this.nuclide, {top: levelMap[zoomLevel].top, left: levelMap[zoomLevel].left}))
        }
    }

    addHalflifeAbundance(zoomLevel) {
        let baseTop = this.squareSize/6;
        this.items.push(createHalfLifeAbundance(this.nuclide, {top:  zoomLevel === 8 ? baseTop : baseTop + 12, left: this.squareSize/4, fontSize: zoomLevel === 8 ? 20 : (this.nuclide.halflife && this.nuclide.abundance ? 12 : 14)}));
    }

    addIndependentFissionYield() {
        // no s2 with square fpsymbols
        if (this.nuclide.fpsymbol === "\/") {
            this.items.push(
                createTriangleIndependentFissionYield(this.nuclide, {top: (this.squareSize+this.squareSize/6)/2+2, left: this.squareSize/2+10})
            );
        }
    }

    addDecayData() {
        this.items.push(createDecay(this.nuclide, {stripLength: 16, top: 256/3 - 20, left: 8}));
    }

    addSubdivisionLine() {
        let line = new SubdivisionLine(this.items, this.nuclide, this.squareSize);
        if(this.nuclide.location !== 'element' && this.nuclide.location !== 'empty') {
            line.addHorizontalDivider();
        }
    }

    addColor() {
        let colorSpec = new ColorSquare(this.nuclide);
        let topShade = colorSpec.populateTopColor();
        let bottomShade = colorSpec.populateBottomColor();

        [topShade, bottomShade] = assignColorsWithFiltering(this.nuclide, {topShade, bottomShade});

        let colorUp = new fabric.Rect({
            width: this.squareSize/2, 
            height: this.squareSize/3,
            fill: topShade,
            strokeWidth: this.strokeWidth,
            objectCaching: false,
        })
        let colorDown = new fabric.Rect({
            width: this.squareSize/2,
            top: this.squareSize/3, 
            strokeWidth: this.strokeWidth,
            height: this.squareSize/4,
            fill: bottomShade,
            objectCaching: false,
        })
        this.items.unshift(colorUp, colorDown);
    }
}

export default UpQuarterSquare;