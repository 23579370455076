import {createLegendText} from "../levels/nuclide/creators/LegendLabel";
import { isVertical, isHorizontal } from "./arrowDirection";

const addSymbolInfo = () => {
    return createLegendText('Nuclide name\n(elemental symbol and\natomic mass number)', 95, 10,'right');
};

const addAbundanceInfo = () => {
    return createLegendText('Atom percent abundance;\n* indicates significant difference\nin the abundance have been\nobserved in some samples', 525, 70);
};

const addSpinParityInfo = () => {
    return createLegendText('Spin and parity;\nparentheses indicate weak\narguments are used to make\nassignment; denominator of\nhalf-integer spins are omitted', 520, 0);
};

const addHalfLifeInfo = () => {
    return createLegendText('Half-life', 125, 65,'right');
};

const addDecay = () => {
    return createLegendText('Modes of decay and decay\nenergies in order of prominence;\nenergy units are MeV for particles\nand keV for photons', 85, 110,'right');
};

const addCrossSectionResonance = () => {
    return createLegendText('Thermal neutron absorption\ncross section followed by\nresonance integral leading to\n(isomeric + ground)', 520, 125);
};

const addMassInfo = () => {
    return createLegendText('Isotopic mass', 110, 220,'right');
};

const addDisintegrationEnergyInfo = () => {
    return createLegendText('\u03B2 disintegration energy\nin MeV', 510, 185);
};

const addTriangleYieldInfo = () => {
    return createLegendText('Direct fission yield from\nthermal neutron induced\nfission of U235', 510, 220);
};

const addSquareYieldInfo = (nuclide) => {
    // TODO resolve this without hard coding
    const isLa138OrSb124 = nuclide.name === 'La138' || nuclide.name === 'Sb124';
    const pos = isLa138OrSb124 ? {x: 515, y: 220} : {x: 85, y: 170};
    const justify = isLa138OrSb124 ? 'left' : 'right';
    return createLegendText('Shielded fission yield from\nthermal neutron induced\nfission of U235', pos.x, pos.y, justify);
};

const addHorizontalArrowInfo = () => {
    return createLegendText('Uncertainty in assignment\nof metastable and ground\nstates', 85, 165,'right');
};

const addVerticalArrowInfo = () => {
    return createLegendText('Uncertainty in assignment\nof the two metastable states', 85, 165,'right');
};

const addCluster = () => {
    return createLegendText('Cluster decay of this\nnuclide has been observed', 520, 195);
};

const applyYield = (nuclide) => {
    const upperCaseSymbol = nuclide.fpsymbol.toUpperCase();
    if (upperCaseSymbol === 'X') {
        return addSquareYieldInfo(nuclide);
    } else if (upperCaseSymbol === '/') {
        return addTriangleYieldInfo();
    }
};

const applyArrow = (nuclide) => {
    if (isVertical(nuclide)) {
        return addVerticalArrowInfo();
    } else if (isHorizontal(nuclide)) {
        return addHorizontalArrowInfo();
    }
};


export {
    addSymbolInfo,
    addAbundanceInfo,
    addSpinParityInfo,
    addHalfLifeInfo,
    addDecay,
    addCrossSectionResonance,
    addMassInfo,
    addDisintegrationEnergyInfo,
    addCluster,
    applyArrow,
    applyYield
}
