const blueSquare = '#9edaf2';
const orangeSquare = '#ff8030';
const greenSquare = '#76e1c7';
const yellowSquare = '#ffe74c';
const graySquare = '#c5c5c5';


export {
    blueSquare,
    orangeSquare,
    greenSquare,
    yellowSquare,
    graySquare
}
