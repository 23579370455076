const config = {
    mobile: {
        periodicTransitionZoomClose: 6,
        periodicTransitionZoomMedium: 5.5,
        periodicTransitionZoomFar: 4.5,
        chartMinZoom: 1,
        tableMinZoom: 1,
    },
    browser: {
        periodicTransitionZoomClose: 8,
        periodicTransitionZoomMedium: 7.5,
        periodicTransitionZoomFar: 7,
        chartMinZoom: 2,
        tableMinZoom: 2,
    }
}
function getPlatformType() {
    if(navigator.userAgent.match(/Mobi/)) {
        return 'mobile';
    } else {
        return 'browser';
    }
}
export default config[getPlatformType()];
