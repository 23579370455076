import React, { Component } from 'react';
import {fabric} from "fabric";
import ElementSquare from "../../../levels/nuclide/levelhelpers/ElementSquare";
import { createLegendLine, createLegendText } from "../../../levels/nuclide/creators/LegendLabel";

const styles = {
    container: {
        width: '80vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }
};

class ElementLegend extends Component {
    constructor(props) {
        super(props);
        this.canvasRef = React.createRef();
        this.fontSize = 13;
    }

    componentDidMount() {
        this.renderLegend();
    }

    componentWillUnmount() {
        this.canvas.setHeight(0);
        this.canvas.setWidth(0);
        this.canvas.clear();
    }

    addSymbolInfo = () => {
        let symbolText = createLegendText('Symbol', 85, 20);
        symbolText.fontSize = this.fontSize;
        let symbolLine = createLegendLine([120, 30, 270, 20]);

        return [symbolText, symbolLine];
    };

    addMassInfo = () => {
        let massSquarePointXCoord = this.props.element.mass.length * 3 + 340;
        let massText = createLegendText('Atomic Weight', 515, 60);
        massText.fontSize = this.fontSize;
        let massLine = createLegendLine([massSquarePointXCoord, 55, 450, 70]);

        return [massText, massLine];
    };

    addNameInfo = (elementSquare) => {
        let nameSquarePointXCoord = 240 - elementSquare.getElementName().length * 4;
        let nameText = createLegendText('Element Name', 75, 150);
        nameText.fontSize = this.fontSize;
        let nameLine = createLegendLine([140, 160, nameSquarePointXCoord, 160]);

        return [nameText, nameLine];
    };

    addCrossSectionInfo = () => {
        let xsText = createLegendText('Thermal neutron absorption \ncross section followed by \nresonance integral in \nbarns per atom', 520, 160);
        xsText.fontSize = this.fontSize;
        let xsLine = createLegendLine([375, 200, 435, 190]);

        return [xsText, xsLine];
    };

    addLegendText = (elementSquare) => {
        let arrayOfFabricItems = [];
        arrayOfFabricItems.push(...this.addNameInfo(elementSquare), ...this.addSymbolInfo());

        if (this.props.element.mass) {
            arrayOfFabricItems.push(...this.addMassInfo());
        }

        if (this.props.element.xs1) {
            arrayOfFabricItems.push(...this.addCrossSectionInfo());
        }

        return new fabric.Group(arrayOfFabricItems, {
            objectCaching: false,
            hasBorders: false,
            hasControls: false,
            hasRotatingPoint: false,
            lockMovementX: true,
            lockMovementY: true,
            hoverCursor: 'default',
            selectable: false,
        });
    };

    renderLegend = () => {
        // let screenSize = window.matchMedia("(min-width: 1000px)").matches ? 'large' : 'small';
        let screenSize = 'small';
        let attributeMap = {
            'small': {
                squareSize: 256,
                textSizeMultiplier: 13,
                massFontSize: 24,
                crossSectionFont: 20,
            },
            'large': {
                squareSize: 512,
                textSizeMultiplier: 18,
                massFontSize: 48,
                crossSectionFont: 40
            }
        };

        let squareSize = attributeMap[screenSize].squareSize;
        let textSizeMultiplier = attributeMap[screenSize].textSizeMultiplier;
        let massFontSize = attributeMap[screenSize].massFontSize;
        let crossSectionFontSize = attributeMap[screenSize].crossSectionFont;

        let items = [];
        let element = new ElementSquare(this.props.element, items, squareSize);
        element.addElementName(textSizeMultiplier);
        element.addElementSquare();
        element.addSymbol(textSizeMultiplier);
        element.addCrossSection(crossSectionFontSize);
        element.addMass(massFontSize);

        this.canvas = new fabric.Canvas();
        this.canvas.initialize(this.canvasRef.current);

        this.canvas.setHeight(256);
        this.canvas.setWidth(600);

        let group = new fabric.Group(items, {
            objectCaching: false,
            hasBorders: false,
            hasControls: false,
            hasRotatingPoint: false,
            lockMovementX: true,
            lockMovementY: true,
            hoverCursor: 'default',
            selectable: false,
        });

        this.canvas.add(group);
        this.canvas.add(this.addLegendText(element));
        group.center();

    };

    render() {
        return (
            <div id='container' style={styles.container}>
                <div>
                    <canvas ref={this.canvasRef}/>
                </div>
            </div>
        )
    }
}


export default ElementLegend;
