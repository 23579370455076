import {createReducer} from "@reduxjs/toolkit";
import {updateRanges, updateOneOrientation} from "../actions/ContinuousAttributeActions";
import defaultColors from '../../helpers/DefaultColors';

export const continuousAttributeReducer = createReducer(
    {   
        filters: defaultColors
    }, 
    {
        [updateRanges]: (state, action) => {
            state['filters'] = action.payload;
        },
        [updateOneOrientation]: (state, {payload}) => {
            state['filters'][payload.orientation] = payload.data;
        }
    }
);
