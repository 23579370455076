let defaultColors = {
    topshade: {
        attribute: 'half life',
        colors: {
            orange: ['1 d', '10 d'],
            yellow: ['10 d', '100 d'],
            green: ['100 d', '10 a'],
            blue: ['10 a', '5E8 a'],
            gray: ['5E8 a', '']
        }
    }, 
    bottomshade: {
        attribute: 'neutron absorption',
        colors: {
            blue: ['10', '100'],
            green: ['100', '500'],
            yellow: ['500', '1000'],
            orange: ['1000', ''],
            gray: ['', '']
        }
    }
};

export default defaultColors;

