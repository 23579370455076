import React, { Component } from "react";
import ContinuousColorSpec from './ContinuousColorSpec';
import DecayModeColorSpec from './DecayModeColorSpec';
import ParityColorSpec from './ParityColorSpec';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';


 export default class ColorSection extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
                <Accordion allowMultipleExpanded={true} allowZeroExpanded={true} preExpanded={['cont']}>
                   <AccordionItem uuid='cont'>
                        <AccordionItemHeading>
                            <AccordionItemButton style={{ width: '90%', color: 'white',backgroundColor: '#707070', marginTop: '4px', boxShadow: '#959595 -1px -1px 5px'}}>
                                Continuous Attributes
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                                <ContinuousColorSpec orientation={this.props.orientation}/>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton style={{ width: '90%', color: 'white',backgroundColor: '#707070', marginTop: '4px', boxShadow: '#959595 -1px -1px 5px'}}>
                                Discrete: Decay
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <DecayModeColorSpec orientation={this.props.orientation}/>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton style={{ width: '90%', color: 'white',backgroundColor: '#707070', marginTop: '4px', boxShadow: '#959595 -1px -1px 5px'}}>
                                Discrete: Parity
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <ParityColorSpec orientation={this.props.orientation}/>
                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>
        )
    }
}

