import {fabric} from 'fabric';
import ElementSquare from './ElementSquare';

export default function createFour(element) {

    let square = new ElementSquare(element, 127, true);
    square.addBorder();

    if (element.isLabel) {
        square.addLabel();
    } else {
        square.addSymbol();
        square.addName();
    }


    let group = new fabric.Group(square.items, {
        objectCaching: false,
    });

    return group;
}