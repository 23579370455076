import {createReducer} from "@reduxjs/toolkit";
import {
    addColorFilterAttribute,
    removeColorFilterAttribute,
    updateColorFilterDecayValues,
    updateColorFilterParityValues,
    updateColorFilterValues,
    clearAllFilters
} from "../actions/ColorFilterAction";


function isDiscreteAttribute(attribute) {
    return attribute === 'decay mode' || attribute === 'parity';
}

export const colorFilterReducer = createReducer([],
    {
        [addColorFilterAttribute]: (state, action) => {
            let attribute = action.payload.attribute.value;
            let label = action.payload.attribute.label;
            let type = isDiscreteAttribute(attribute) ? 'discrete' : 'continuous';

            return [...state, {attribute: attribute, label: label, type: type, minVal: '', maxVal: '', options: []}];
        },
        [removeColorFilterAttribute]: (state, action) => {
            return state.filter(attribute => attribute.attribute !== action.payload.attribute);
        },
        [updateColorFilterParityValues]: (state, {payload}) => {
            let parityAttribute = state.find(attribute => attribute.attribute === 'parity');
            parityAttribute.options = payload;
        },
        [updateColorFilterDecayValues]: (state, {payload}) => {
            let decayAttribute = state.find(attribute => attribute.attribute === 'decay mode');
            decayAttribute.options = payload;
        },
        [updateColorFilterValues]: (state, {payload}) => {
            let attribute = state.find(attribute => attribute.attribute === payload.attribute);
            attribute[payload.param] = payload.val;
        },
        [clearAllFilters]: (state, action) => {
            return [];
        }
    }
);
