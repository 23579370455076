import UnitConverter from '../helpers/UnitConverter';
import config from '../config';
import _ from "lodash";

function getXYZFromElement(clickedElement, nuclides) {
    let nuclideOfElement = nuclides
        .filter(
            n => n.Symbol === clickedElement
        );
    let ZVal = nuclideOfElement[0].Z;
    let NVal;
    let zoomLevel = config.periodicTransitionZoomClose;

    let nuclidesWithAbundance = nuclideOfElement.filter(n => n.abundance != null);

    // if there are nuclides with abundance, all of those should be visible on the transition
    // otherwise, center on the nuclide with longest half life
    if (nuclidesWithAbundance.length !== 0) {
        let Ns = nuclidesWithAbundance.map(({N}) => N);
        NVal = Ns.reduce((a, b) => a + b, 0)  / Ns.length;

        let maxAVal = _.maxBy(nuclidesWithAbundance, function(n) { return n.A; }).A;
        let minAVal = _.minBy(nuclidesWithAbundance, function(n) { return n.A; }).A;

        if (maxAVal - minAVal <= 5) {
            zoomLevel = config.periodicTransitionZoomClose;
        } else if (maxAVal - minAVal <= 8) {
            zoomLevel = config.periodicTransitionZoomMedium;
        } else {
            zoomLevel = config.periodicTransitionZoomFar;
        }
    } else {
        let converter = new UnitConverter()
        let halfLifeValuesNormalizedToMilliSeconds = nuclideOfElement
            .filter(n => n.halflife != null && n.halflife.toLowerCase() != 'very short' && n.location != 'not shown')
            .map(n => {
                let intervalMultiplier;
                let lastTwoCharsOfHalfLife = n.halflife.substr(-2);
                let numericalHalfLifeValue = n.halflife.substr(0, n.halflife.indexOf(' '));

                if (lastTwoCharsOfHalfLife.startsWith(' ')) {
                    intervalMultiplier = converter.convertTime(n.halflife.substr(-1));
                } else {
                    intervalMultiplier = converter.convertTime(n.halflife.substr(-2));
                }
                
                if (numericalHalfLifeValue.startsWith('~')) {
                    numericalHalfLifeValue = numericalHalfLifeValue.substr(1);
                }

                return {
                    'N': n.N,
                    'halflife': intervalMultiplier * numericalHalfLifeValue
                }
            });


        let mostStable = _.maxBy(halfLifeValuesNormalizedToMilliSeconds, function(n) { return n.halflife });

        // Ts has no nuclides, would otherwise error out. Just use the N value for Og to get the same effect
        NVal = mostStable ? mostStable.N : 175;
    }

    return {
        x: NVal + 0.5,
        y: ZVal - 256,
        z: zoomLevel
    }
}

export default getXYZFromElement;