import {fabric} from 'fabric';
import DefaultSquare from '../nuclide/levelhelpers/DefaultSquare';
import RowColSquare from '../nuclide/levelhelpers/RowColSquare';
import SquareGrid from '../nuclide/levelhelpers/SquareGrid';
import ElementSquare from "./levelhelpers/ElementSquare";
import store from '../../store/ReduxStore';

export default function createSix(nuclide, nuclideData) {
    let items = [];

    if(nuclide.isRow) {
        new RowColSquare(nuclide, items, 64, true, 'Z');
    } else if(nuclide.isColumn) {
        new RowColSquare(nuclide, items, 64, true, 'N');
    } else {
        let square = new DefaultSquare(nuclide, items, 64);

        if (nuclide.location === 'element') {
            let element = new ElementSquare(nuclide, items, 64);

            if (store.getState().dataDisplay.addMass.display) {
                element.addMass(11, 2);
            }

            element.addSymbol();
        } else if (nuclide.location !== 'empty') {
            square.addBlackBand();
            square.addName();
            let grid = new SquareGrid(items, nuclide, 64, nuclideData);
            grid.createGrid(6);
        }

    }

    let group = new fabric.Group(items, {
        objectCaching: false,
        opacity: nuclide.opacity
    });

    return group;
}
