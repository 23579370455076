import {fabric} from "fabric";
import getValuesByPrefix from "../../../helpers/GetValuesByPrefix";
import stripText from "../levelhelpers/StripText";


function createDecay(nuclide, config) {

    let values = getValuesByPrefix(nuclide, 'decay').splice(0, 5).filter(n => n !== null);
    let strippedValues = values.map(n => stripText(n, config.stripLength));
    let superScriptedMinusValues = superScriptAnnotatedMinusSigns(strippedValues);

    let mergedText = superScriptedMinusValues.join('\n').replace('+', '\u207A');

    return new fabric.Text(mergedText, {
        top: config.top,
        left: config.left,
        fontSize: 14.7,
        textAlign: 'left',
        objectCaching: false
    });

}

function superScriptAnnotatedMinusSigns(values) {
    return values.map(value => {

        // I don't know why but the minus sign and hyphen signs look the same but register differently
        if (value.indexOf('-') !== -1) {
            if (isNaN(value[value.indexOf('-') - 1])) {
                return value.replace(/-/g, '\u207B');
            } else {
                return value;
            }

        } else if (value.indexOf('-') !== -1) {
            if (isNaN(value[value.indexOf('-') - 1])) {
                return value.replace(/-/g, '\u207B');
            } else {
                return value;
            }
        }
        else {
            return value;
        }
    })
}


export {
    createDecay
}