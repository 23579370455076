import React, { Component } from 'react';
import Welcome from "./components/Welcome/Welcome";
import Main from './components/Main';
import config from './aws-config';
import initAnalytics from "./initAnalytics";

class App extends Component {
    state = {
        isFirstLoad: true,
    };

    componentDidMount() {
        initAnalytics();
        if (!!JSON.parse(localStorage.getItem('hasBeenLoaded'))) {
            this.setState({isFirstLoad: false});
        }
    }

    onClick = () => {
        localStorage.setItem('hasBeenLoaded', true);
        this.setState({isFirstLoad: false});
    };

    render() {
        return (
            <>
                {this.state.isFirstLoad
                    ? <div style={{margin: '50px'}}><Welcome onClick={this.onClick} isFirstScreen={true}/></div>
                    : <Main/>
                }
            </>
        );
    }
}
export default App;
