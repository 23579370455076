import React, {Component} from "react";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { getNumericValue, getUnitOfTime} from "../../helpers/HalfLifeStringManipulator";

class ContinuousInputBox extends Component {
    constructor(props) {
        super(props);

        this.changeDropdown = this.changeDropdown.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.validateAndDispatch = this.validateAndDispatch.bind(this);

        this.state = {
            attribute: this.props.colorCodingOptions.attribute,
            selected: getUnitOfTime(this.props.colorCodingOptions.colors[this.props.color][this.props.index]),
            entered: getNumericValue(this.props.colorCodingOptions.colors[this.props.color][this.props.index]),
        };
    }

    handleChange(numericVal) {
        const re = /^[0-9.Ee+\-]*$/;

        if(numericVal === '' || re.test(numericVal)) {
            this.setState({entered: numericVal});
        }
    }

    changeDropdown(selected) {
        let numericValWithUnit = `${this.state.entered}${selected.value}`;
        this.setState({
            selected: selected.value,
        });

        this.props.updateFilterState(this.props.color, this.props.index, numericValWithUnit);
    }

    validateAndDispatch() {
        let entryToValidate = this.props.colorCodingOptions.attribute === 'half life' ? `${this.state.entered}${this.state.selected}` : this.state.entered;
        this.props.updateFilterState(this.props.color, this.props.index, entryToValidate);
    }

    static getDerivedStateFromProps(props, state) {
        if (props.colorCodingOptions.attribute !== state.attribute) {
            return {
                ...state,
                attribute: props.colorCodingOptions.attribute,
                selected: getUnitOfTime(props.colorCodingOptions.colors[props.color][props.index]),
                entered: getNumericValue(props.colorCodingOptions.colors[props.color][props.index]),
            }
        }
        return null;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const props = this.props.colorCodingOptions.colors[this.props.color][this.props.index];
        const state = this.state;

        const inputValFromProps = prevProps.colorCodingOptions.colors[prevProps.color][prevProps.index];

        const oldUnitDiffOnReset = getUnitOfTime(inputValFromProps) !== getUnitOfTime(props) && getUnitOfTime(props) !== state.selected;
        const oldNumberDiffOnReset = getNumericValue(inputValFromProps) !== getNumericValue(props) && getNumericValue(props) !== state.entered;

        if (oldUnitDiffOnReset ||oldNumberDiffOnReset) {
            this.setState({
                ...state,
                selected: getUnitOfTime(props),
                entered: getNumericValue(props)
            });
        }

    }

    render() {
        const unitMap = {
            'alpha energy': 'MeV',
            'beta energy': 'MeV',
            'gamma energy': 'keV',
            'disintegration energy': 'MeV',
            'natural abundance': 'atom %',
            'cross section': 'barns',
            'resonance integral': 'barns',
            'neutron absorption': 'barns',
            'spin': '\u0127',
            'no color': '-',
        };

        const options = [
            'a',
            'd',
            'h',
            'm',
            's',
            'ms',
            'μs',
            'ns',
            'ps'
        ];

        const attribute = this.props.colorCodingOptions.attribute;
        const {selected, entered} = this.state;
        const key = `${this.props.orientation}-${this.props.color}-${this.props.index}`;
        const error = this.props.errorInputs.indexOf(key) !== -1;

        return (
            <div className="color_entry" style={{display: 'flex', flexDirection: 'row', padding: '0 2px 0 0'}}>
                <input type="text"
                       aria-label='continuous input'
                       className={error ? 'error' : 'continuous-input'}
                       style={{ borderRadius: '3px'}}
                       placeholder={this.props.index === 0 ? 'min' : 'max'}
                       value={entered}
                       onChange={(event) => this.handleChange(event.target.value)}
                       onBlur={this.validateAndDispatch}
                />
                {attribute === 'half life'
                    ? <Dropdown
                        options={options}
                        className={error ? 'error' : 'selectUnit'}
                        placeholder="Unit"
                        onChange={this.changeDropdown}
                        value={selected} />
                    : <label style={{padding: '10px'}}>{unitMap[attribute]}</label>}
            </div>
        )
    }
}

export default ContinuousInputBox;
