let defaultRanges = {
    'half life': {
        orange: ['1 d', '10 d'],
        yellow: ['10 d', '100 d'],
        green: ['100 d', '10 a'],
        blue: ['10 a', '5E8 a'],
        gray: ['5E8 a', '']
    },
    'neutron absorption': {
        blue: ['10', '100'],
        green: ['100', '500'],
        yellow: ['500', '1000'],
        orange: ['1000', ''],
        gray: ['', '']
    },
    'alpha energy': {
        orange: ['1', '3'],
        yellow: ['3', '5'],
        green: ['5', '7'],
        blue: ['7', '9'],
        gray: ['9', '']
    },
    'beta energy': {
        orange: ['0.5', '1'],
        yellow: ['1', '1.5'],
        green: ['1.5', '2'],
        blue: ['2', '2.5'],
        gray: ['2.5', '']
    },
    'gamma energy': {
        orange: ['500', '1000'],
        yellow: ['1000', '1500'],
        green: ['1500', '2000'],
        blue: ['2000', '2500'],
        gray: ['2500', '']
    },
    'disintegration energy': {
        orange: ['0.5', '1'],
        yellow: ['1', '1.5'],
        green: ['1.5', '2'],
        blue: ['2', '2.5'],
        gray: ['2.5', '']
    },
    'natural abundance': {
        orange: ['1E-10', '20'],
        yellow: ['20', '40'],
        green: ['40', '60'],
        blue: ['60', '80'],
        gray: ['80', '']
    },
    'cross section': {
        blue: ['10', '100'],
        green: ['101', '500'],
        yellow: ['501', '1000'],
        orange: ['1001', '5000'],
        gray: ['', '']
    },
    'resonance integral': {
        blue: ['10', '100'],
        green: ['101', '500'],
        yellow: ['501', '1000'],
        orange: ['1001', '5000'],
        gray: ['', '']
    },
    'spin': {
        orange: ['0', '1'],
        yellow: ['1', '2'],
        green: ['2', '3'],
        blue: ['3', '4'],
        gray: ['4', '5']
    },
    'no color': {
        orange: ['', ''],
        yellow: ['', ''],
        green: ['', ''],
        blue: ['', ''],
        gray: ['', '']
    }
};

export default defaultRanges;
