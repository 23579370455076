import _ from 'lodash';
import store from '../../../store/ReduxStore';
import UnitConverter from '../../../helpers/UnitConverter';
import defaultColors from '../../../helpers/DefaultColors';
import ms from 'ms';
import Parity from '../../../helpers/Parity';
import Decay from '../../../helpers/Decay';
import fromExponential from 'from-exponential';
import {blueSquare, graySquare, yellowSquare, greenSquare, orangeSquare} from "../../../colors";

class ColorSquare {
    constructor (nuclide) {
        this.nuclide = nuclide;
        this.colorMapping = {
            blue: blueSquare,
            orange: orangeSquare,
            green: greenSquare,
            yellow: yellowSquare,
            gray: graySquare,
            'gray\/blue': graySquare,
            'blue\/grey': graySquare
        }
        // keys in this mapping should match with what gets stored in the redux store
        // as the attribute value. Values are the functions which does the conversion
        // of fields to something numeric so that we can detect if they are in the
        // given range.
        this.converterMapping = {
            'alpha energy': 'convertDecayToAlpha',
            'beta energy': 'convertDecayToBeta',
            'gamma energy': 'convertDecayToGamma',
            'disintegration energy': 'convertDisintegrationE',
            'natural abundance': 'convertNaturalAbundanceToNumeric',
            'half life': 'convertHalfLife',
            'cross section': 'convertXsToCrossSection',
            'resonance integral': 'convertXsToResonance',
            'neutron absorption': 'convertXsToNeutronAbsorption',
            'spin': 'convertSpinToNumeric',
            'no color': 'noColor'
        }
        this.state = store.getState();
    }

    isAttributeInRange(attributeName, range) {
        let unitConverter = new UnitConverter;
        let numericValue = unitConverter[this.converterMapping[attributeName]](this.nuclide);
        if (attributeName === 'half life') {
            range = _.map(range, function(val) {
                if (val !== "") {
                    return fromExponential(unitConverter.handleHalflifeConversion(val));
                } else {
                    return "";
                }
            });
        }

        if (range[0] === '' && range[1] !== '') {
            return numericValue !== null && numericValue <= range[1];
        }

        if (range[1] === '' && range[0] !== '') {
            return numericValue !== null && range[0] <= numericValue;
        }

        return numericValue !== null && _.inRange(numericValue, range[0], range[1]);
    }

    isDefaultCondition() {
        return  _.isEqual(this.state.filters.filters, defaultColors)
    }

    _populateColor(orientation) {
        let that = this;
        if (this.isDefaultCondition()) {
            return this.colorMapping[this.nuclide[orientation]];
        } else {
            let finalColor;
            let attribute = this.state.filters.filters[orientation].attribute;
            _.forEach(this.state.filters.filters[orientation].colors, function(range, color) {
                if (that.isAttributeInRange(attribute, range)) {
                    finalColor = color;
                }
            })
            return this.colorMapping[finalColor];
        }
    }

    populateTopColor() {
        let color = this._populateColor('topshade');
        if (this.isTopDecayGiven()) {
            color = this.populateDecayColor('topshade');
        } else if (this.isTopSpinGiven()) {
            color = this.populateDiscreteSpinColor('topshade');
        }

        if (this.state.filters.filters['topshade'].attribute === 'no color'
            && this.state.filters.filters['bottomshade'].attribute !== 'no color') {
            return this.populateBottomColor()
        }
        return color;
    }
    populateBottomColor() {
        let color = this._populateColor('bottomshade');
        if (this.isBottomDecayGiven()) {
            color = this.populateDecayColor('bottomshade');
        } else if (this.isBottomSpinGiven()) {
            color = this.populateDiscreteSpinColor('bottomshade');
        }

        if (this.state.filters.filters['bottomshade'].attribute === 'no color'
            && this.state.filters.filters['topshade'].attribute !== 'no color') {
            return this.populateTopColor()
        }
        return color;
    }

    isTopDecayGiven() {
        return !_.isEmpty(this.state.discreteDecay.topshade);
    }

    isBottomDecayGiven() {
        return !_.isEmpty(this.state.discreteDecay.bottomshade);
    }

    populateDecayColor(orientation) {
        let counter = 0;
        let decay = new Decay(this.nuclide);
        let color;
        _.forEach(this.state.discreteDecay[orientation], function(n) {
            if (decay.doesNuclideHaveDecayType(n.value)) {
                counter += 1;
                color = n.color;
            }
        });
        if (counter >=2) {
            color = graySquare;
        }

        return color;
    }

    isTopSpinGiven() {
        const allEqual = arr => arr.every(
            v => v === arr[0]
        )
        return !allEqual(_.values(this.state.discreteParity.addDiscreteParity.topshade))
    }

    isBottomSpinGiven() {
        const allEqual = arr => arr.every(
            v => v === arr[0]
        )
        return !allEqual(_.values(this.state.discreteParity.addDiscreteParity.bottomshade))
    }

    populateDiscreteSpinColor(orientation) {
        let parity = new Parity(this.nuclide);
        let color;
        let spin = this.state.discreteParity.addDiscreteParity[orientation];
                if (spin.isPositiveVsNegative) {
                    if (parity.isPositive()) {
                        color = orangeSquare
                    }
                    if (parity.isNegative()) {
                        color = blueSquare
                    }
                } else if (spin.isStrongVsWeak) {
                    if (parity.isPositive() && parity.isStrong()) {
                        color = orangeSquare
                    }
                    if (parity.isNegative() && parity.isStrong()) {
                        color = blueSquare
                    }
                    if (parity.isPositive() && parity.isWeak()) {
                        color = yellowSquare
                    }
                    if (parity.isNegative() && parity.isWeak()) {
                        color = greenSquare
                    }
                }
        return color;
    }
}

export default ColorSquare;
