import hasParentheses from "./hasParentheses";
import getDecayObjectByNuclide from "./getDecayObjectByNuclide";
import {filterDecayString} from "./getDecayModes";

const getPrimaryDecay = (nuclide) => {
    const decays = getDecayObjectByNuclide(nuclide);

    if (Object.values(decays).every(decay => !hasParentheses(decay))) {
        return '';
    } else {
        const cleanedDecaysToEvaluate = filterOutUnneededDataAndNumbers(decays);
        return calculatePrimaryDecayBasedOnDelayedEmission(cleanedDecaysToEvaluate);

    }
};

const calculatePrimaryDecayBasedOnDelayedEmission = (cleanedDecaysToEvaluate) => {
    // TODO refactor this mess we got test coverage
    for (let [column, decayEntry] of Object.entries(cleanedDecaysToEvaluate)) {
        if (hasParentheses(decayEntry)) {
            let numberForDecayEntry = column.substr(-1);

            while(numberForDecayEntry >= 1) {
                numberForDecayEntry -= 1;
                const previousDecay = getPreviousDecay(cleanedDecaysToEvaluate, numberForDecayEntry);

                if (!parenthesesOrEmpty(previousDecay)) {
                    return previousDecay.split(',')[0];
                }
            }
        }
    }
};

const getPreviousDecay = (decays, decayIndex) => {
    const previousDecayColumn = `decay${decayIndex}`;
    return decays[previousDecayColumn];
};


const parenthesesOrEmpty = (decay) => {
    return decay.includes('(') || decay.includes(')') || decay === '';
};

const filterOutUnneededDataAndNumbers = (decays) => {
    let copy = Object.assign({}, decays);

    Object.entries(decays)
        .map(([column, decay]) => {
            if (decay === null) {
                return [column, ''];
            } else if (decay === 'no \u03b3') {
                return [column, ''];
            } else {
                return [column, decay];
            }
        })
        .forEach(([column, decay]) => {
            copy[column] = filterDecayString(decay).join(', ');
    });

    return copy;
};

export default getPrimaryDecay;
